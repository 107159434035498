.contact-form {
  margin-bottom: 25px;

  input,
  textarea {
    margin-bottom: 22px; }

  button[type="submit"] {
    > i {
      display: none; }

    &.clicked {
      > span {
        display: none; }

      > i {
        display: inline-block; } } }

  &.for-page {
    .form-result {
      margin-top: 25px; } } }
