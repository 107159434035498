input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="time"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime-local"],
input[type="search"],
input[type="tel"],
input[type="url"],
textarea,
select,
.form-control {
  background-color: $light_gray;
  border-radius: 0;
  box-shadow: none;
  display: block;
  width: 100%;
  padding: 10px 20px;
  line-height: 1;
  border: 1px solid transparent;
  transition: all 0.15s ease-in-out;
  outline: none;
  line-height: 1;
  height: 46px;

  &:focus {
    box-shadow: none;
    border-color: $color_secondary;
    background-color: #FFF;
    outline-color: transparent; }

  .transparent-form & {
    background-color: rgba(#FFF, 0.20);
    border-color: #FFF;
    color: #FFF;

    &:focus {
      border-color: $color_secondary; } } }

.transparent-form select option {
  color: #000; }

select {
  -webkit-appearance: none; }

textarea,
textarea.form-control {
  height: auto;
  padding: 20px; }
