.gallery-item {
  padding-bottom: 30px;

  > .content {
    display: block;
    position: relative;
    overflow: hidden;
    transition: all 0.25s ease-in-out;

    > img {
      width: 100%;
      height: auto; }

    > .info {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(5,7,9,0.7);
      padding: 60px 40px 50px 40px;
      color: #FFF;
      opacity: 0;
      transition: 0.25s ease-in-out;
      backface-visibility: hidden;

      > .title {
        color: #FFF;
        margin-top: 0;
        margin-bottom: 12px;
        line-height: 1;
        font-family: $font_secondary;
        font-weight: 600;
        @include font-size(24);
        text-transform: uppercase;
        transform: translateY(100px);
        opacity: 0;
        transition: all 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28); }

      > .meta {
        opacity: 0;
        transform: translateY(100px);
        transition: all 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.15s; }

      > .plus-sign {
        position: absolute;
        bottom: 50px;
        left: 40px;
        width: 40px;
        height: 40px;

        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 0%;
          height: 2px;
          background-color: #FFF;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          opacity: 0;
          transition: all 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.25s; }

        &:after {
          transform: translate(-50%,-50%) rotate(90deg); } } }

    &:hover {
      box-shadow: 0px 4px 10.8px 1.2px rgba(0, 0, 0, 0.25);

      > .info {
        opacity: 1;

        > .title,
        > .meta {
          transform: translateY(0);
          opacity: 1; }

        > .meta {
          opacity: 0.7; }

        > .plus-sign {
          &:before,
          &:after {
            opacity: 1;
            width: 100%; } } } } } }

@media (max-width: 600px) {
  .gallery-item {
    max-width: 300px;
    margin: 0 auto; } }
