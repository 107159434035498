.navigation {

  // Pagination
  &.pagination {
    display: block;
    margin-top: 30px;
    margin-bottom: 50px;

    > .nav-links {
      text-align: center;

      > a,
      > span {
        display: inline-block;
        border: 1px solid #eeeeee;
        min-width: 30px;
        min-height: 30px;
        text-align: center;
        color: $deep_black;
        line-height: 2;
        padding: 0 7px;
        margin-right: 5px;
        margin-bottom: 5px;

        &.current,
        &:hover {
          color: #FFF;
          background-color: $color_secondary;
          border-color: $color_secondary; }

        &.dots {
          background-color: transparent;
          border-color: #eeeeee;
          color: $deep_black; }

        &.prev,
        &.next {
          width: 30px; }

        &.prev:before {
          content: '\f104';
          font-family: FontAwesome; }

        &.next:before {
          content: '\f105';
          font-family: FontAwesome; } } } }

  // Navigation
  &.post-navigation {
    > .nav-links {
      padding: 0 30px;
      margin-top: 50px;
      margin-bottom: 50px;
      @include clearfix();

      > div {
        width: 50%;
        float: left;

        > a {
          display: block;
          color: $deep_black;
          font-weight: 700;

          > .nav-subtitle {
            text-transform: uppercase;
            letter-spacing: 0.1em;
            margin-bottom: 5px;
            color: $deep_gray; }

          > .nav-title {
            font-size: 1.2em;
            position: relative;

            > i {
              position: absolute;
              left: -25px;
              top: 4px;
              transition: all 0.15s ease-in-out; } }

          > span {
            display: block; }

          &:hover {
            color: $color_primary;

            > .nav-title > i {
              left: -30px; } } }

        &.nav-next {
          float: right;
          text-align: right;

          > a {

            > .nav-title > i {
              left: auto;
              right: -25px; }

            &:hover > .nav-title > i {
              left: auto;
              right: -30px; } } } }

      @media (max-width: 600px) {
        > div,
        > div.nav-next {
          width: auto;
          float: none; } } } } }

// Pages Links
.page-links {
  margin-top: 10px;
  margin-bottom: 10px;

  > a,
  > .page-number {
    display: inline-block;
    border: 1px solid #eeeeee;
    min-width: 30px;
    min-height: 30px;
    text-align: center;
    color: $deep_black;
    line-height: 2;
    padding: 0 7px;
    margin-bottom: 5px; }

  a:hover {}
  > .page-number {
    color: #FFF;
    background-color: $color_secondary;
    border-color: $color_secondary; }

  > .title {
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    border: 1px solid #eeeeee;
    line-height: 2;
    padding: 0 7px;
    margin-bottom: 5px; } }
