.site-page-header {
  text-align: center;

  .page-title {
    margin-top: 0;
    margin-bottom: 15px;
    font-family: $font_secondary;
    @include font-size(50);
    line-height: 1;
    font-weight: 600;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 360px;
      height: 1px;
      background-color: #FFF;
      bottom: -5px;
      left: 50%;
      margin-left: -180px; } }

  .breadcrumbs {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      display: inline-block;
      word-break: break-word;

      &+li:before {
        content: '\/';
        margin: 0 5px;
        color: #d0d0d0; } } } }

// Responsiveness
@media (max-width: 768px) {
  .site-page-header {
    &.section-padding {
      padding-top: 50px;
      padding-bottom: 50px; }

    .page-title {
      @include font-size(30); } } }
