.event-schedule-info {

  > .section-title > .title {
    margin-bottom: 30px; }

  > .info-content {

    p {
      margin-bottom: 30px; }

    ul {
      padding-top: 25px;

      > li {
        color: $deep_black;
        font-weight: 500; } } } }
