/*!
  Theme Name: Devent
  Theme URI: http://www.decentthemes.com/product/devent-events-conference-template/
  Author: Decent Themes
  Author URI: http://www.decentthemes.com
  Description: Events & Conference Site Template.
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: devent
  Tags: one-column, two-columns, right-sidebar, accessibility-ready, custom-background, custom-colors, custom-header, custom-menu, editor-style, featured-images, flexible-header, microformats, post-formats, rtl-language-support, sticky-post, threaded-comments, translation-ready, blog
  */
/*-----------------------------------------------
  >>> TABLE OF CONTENTS:
  -----------------------------------------------
  # Base rules
    ## Accessibility
    ## Links
    ## Buttons
    ## Lists
    ## Form
    ## Contact Form 7 Support
    ## Tables
    ## Typography
    ## WordPress Core
    ## WooCommerce Hacks
    ## VC Hacks
  # Elements
    ## Event Countdown
    ## Navbar (Standard)
    ## Navbar (Transparent)
    ## Event Statics
    ## Carousel Controls
    ## Event Schedule Info
    ## Event Schedule Tabs
    ## Event Image Carousel
    ## About Content Block
    ## Newsletter form
    ## Speaker Profile Thumb
    ## Speaker Profile Thumb (v2)
    ## Speaker Profile Details
    ## Testimonial Item
    ## Testimonial Item (v2)
    ## Pricing Table
    ## Register form
    ## Event Sponsor Gallery
    ## Recent News List
    ## Contact Form
    ## Contact Method Box
    ## Gallery Item
    ## Navigation
    ## Error 404 Content
    ## Search Form
    ## Article
    ## Post Author Bio
    ## Comments
    ## Back To Top
  # Sections
    ## Site Header
    ## Page Header
    ## Site Banner
    ## Event Schedule
    ## About The Event
    ## Newsletter
    ## Event Speakers
    ## Testimonials
    ## Event Pricing
    ## Register Now
    ## Photos From Our Event
    ## Event Sponsor
    ## Recent News
    ## Contact Us
    ## Site Footer
    ## About Us Content Block
    ## Speakers Profile Carousel
    ## Galleries Archive
    ## Error 404
    ## Blog Entries
    ## Sidebars
  # Widgets
    ## About Us
    ## Recent Entries
    ## Get Your Tickets
    ## Photos From Our Events
    ## Tag Cloud
    ## Calendar
    ## Recent Comments
    ## RSS Feed
  ---------------------------------------------*/
/*-----------------------------------------------
  # Base rules
  ---------------------------------------------*/
body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #757575;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

a {
  color: #050709;
}

a:hover {
  color: #fd367e;
}

#site {
  position: relative;
}

hr.mt-50 {
  margin-top: 50px;
}

/* Swiper slider tweak */
[data-carousel="swiper"] {
  position: relative;
}

.swiper-slide[data-bg-image] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.swiper-slide .banner-content {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  transition-delay: 0.4s;
}

.swiper-slide.swiper-slide-active .banner-content {
  opacity: 1;
}

/* Map Display Area */
.map-display-area {
  min-height: 250px;
}

.contact-page-map .map-display-area {
  min-height: 500px;
}

@media (max-width: 768px) {
  .contact-page-map .map-display-area {
    min-height: 200px;
    max-height: 250px;
  }
}

/* SVG Sprite */
.dt-sprite {
  float: left;
  display: inline-block;
}

.dt-sprite > svg {
  display: block;
  width: 100%;
  height: auto;
}

/* WordPress Alignment Classes */
.alignleft {
  display: inline;
  float: left;
  margin-right: 2em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 2em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Preloader */
#site-preloader {
  position: fixed;
  background-color: #050709;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

#site-preloader > .loader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*-----------------------------------------------
  ## Accessibility
  ---------------------------------------------*/
.screen-reader-text,
.says {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*-----------------------------------------------
  ## Links
  ---------------------------------------------*/
a {
  transition: all 0.15s ease-in-out;
}

a:hover {
  text-decoration: none;
}

a:focus {
  outline: none;
}

/*-----------------------------------------------
  ## Buttons
  ---------------------------------------------*/
input[type="submit"] {
  border: none;
}

input[type="button"],
input[type="submit"],
button,
.btn {
  border-radius: 0;
  outline: none;
  transition: all 0.15s ease-in-out;
}

input[type="button"]:focus, input[type="button"]:active:focus,
input[type="submit"]:focus,
input[type="submit"]:active:focus,
button:focus,
button:active:focus,
.btn:focus,
.btn:active:focus {
  outline: none;
}

input[type="button"],
input[type="submit"],
button,
.btn {
  line-height: 1;
  padding: 15px 30px;
}

input[type="button"],
input[type="submit"],
button,
.btn-devent {
  color: #FFF;
  background-color: #fd367e;
  border: 1px solid #fd367e;
}

input[type="button"]:hover, input[type="button"]:focus,
input[type="submit"]:hover,
input[type="submit"]:focus,
button:hover,
button:focus,
.btn-devent:hover,
.btn-devent:focus {
  color: #FFF;
  background-color: #e40254;
}

input[type="button"].btn-hs:hover, input[type="button"].btn-hs:focus,
input[type="submit"].btn-hs:hover,
input[type="submit"].btn-hs:focus,
button.btn-hs:hover,
button.btn-hs:focus,
.btn-devent.btn-hs:hover,
.btn-devent.btn-hs:focus {
  background-color: transparent;
  color: #fd367e;
}

input[type="button"].dt-alt,
input[type="submit"].dt-alt,
button.dt-alt,
.btn-devent.dt-alt {
  background-color: #4e1184;
  border-color: #4e1184;
}

input[type="button"].dt-alt:hover, input[type="button"].dt-alt:focus,
input[type="submit"].dt-alt:hover,
input[type="submit"].dt-alt:focus,
button.dt-alt:hover,
button.dt-alt:focus,
.btn-devent.dt-alt:hover,
.btn-devent.dt-alt:focus {
  background-color: #260840;
}

input[type="button"].dt-alt.btn-hs:hover, input[type="button"].dt-alt.btn-hs:focus,
input[type="submit"].dt-alt.btn-hs:hover,
input[type="submit"].dt-alt.btn-hs:focus,
button.dt-alt.btn-hs:hover,
button.dt-alt.btn-hs:focus,
.btn-devent.dt-alt.btn-hs:hover,
.btn-devent.dt-alt.btn-hs:focus {
  background-color: transparent;
  color: #4e1184;
}

input[type="button"].btn-alt-2,
input[type="submit"].btn-alt-2,
button.btn-alt-2,
.btn-devent.btn-alt-2 {
  background-color: #831dde;
  border-color: #831dde;
}

input[type="button"].btn-alt-2:hover, input[type="button"].btn-alt-2:focus,
input[type="submit"].btn-alt-2:hover,
input[type="submit"].btn-alt-2:focus,
button.btn-alt-2:hover,
button.btn-alt-2:focus,
.btn-devent.btn-alt-2:hover,
.btn-devent.btn-alt-2:focus {
  background-color: #6917b1;
}

input[type="button"].btn-alt-2.btn-hs:hover, input[type="button"].btn-alt-2.btn-hs:focus,
input[type="submit"].btn-alt-2.btn-hs:hover,
input[type="submit"].btn-alt-2.btn-hs:focus,
button.btn-alt-2.btn-hs:hover,
button.btn-alt-2.btn-hs:focus,
.btn-devent.btn-alt-2.btn-hs:hover,
.btn-devent.btn-alt-2.btn-hs:focus {
  background-color: transparent;
  color: #831dde;
}

.dt-play-btn {
  display: inline-block;
  width: 70px;
  height: 70px;
  background-color: rgba(253, 54, 126, 0.8);
  position: relative;
  border-radius: 50%;
  z-index: 10;
}

.dt-play-btn > i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFF;
  font-size: 24px;
  margin-left: 2px;
}

.dt-play-btn:before {
  content: '';
  position: absolute;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background: rgba(253, 54, 126, 0.6);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.dt-play-btn:after {
  content: '';
  position: absolute;
  width: 98px;
  height: 98px;
  border-radius: 50%;
  background: rgba(253, 54, 126, 0.4);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
}

.dt-play-btn:hover {
  opacity: 0.5 !important;
}

/*-----------------------------------------------
  ## Lists
  ---------------------------------------------*/
ul {
  /* Styled List */
  /* Social profiles */
  /* Post Meta */
}

ul.styled-list {
  padding-left: 0;
  margin-bottom: 0;
}

ul.styled-list > li {
  list-style-type: none;
  margin-bottom: 10px;
}

ul.styled-list > li:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #FFF;
  border: 2px solid #fd367e;
  margin-right: 10px;
}

ul.social-profiles {
  padding: 0;
  margin: 0;
  /* Version 2 */
}

ul.social-profiles:before, ul.social-profiles:after {
  content: "";
  display: table;
}

ul.social-profiles:after {
  clear: both;
}

ul.social-profiles > li {
  list-style: none;
  float: left;
  margin-right: 12px;
  margin-bottom: 10px;
}

ul.social-profiles > li:last-child {
  margin-right: 0;
}

ul.social-profiles > li > a {
  display: block;
  width: 36px;
  height: 36px;
  border: 1px solid #DDD;
  text-align: center;
  color: #050709;
  font-size: 14px;
  font-size: 1em;
  line-height: 2.5em;
}

ul.social-profiles > li > a:hover {
  color: #FFF;
  background-color: #4e1184;
  border-color: #4e1184;
}

ul.social-profiles.transparent > li > a,
.transparent-area ul.social-profiles > li > a {
  border-color: rgba(255, 255, 255, 0.3);
  color: #FFF;
}

ul.social-profiles.transparent > li > a:hover,
.transparent-area ul.social-profiles > li > a:hover {
  background-color: #FFF;
  color: #4e1184;
  border-color: transparent;
}

ul.social-profiles-v2 {
  padding: 0;
  margin: 0;
}

ul.social-profiles-v2:before, ul.social-profiles-v2:after {
  content: "";
  display: table;
}

ul.social-profiles-v2:after {
  clear: both;
}

ul.social-profiles-v2 > li {
  display: inline-block;
}

ul.social-profiles-v2 > li + li {
  margin-left: 10px;
}

ul.social-profiles-v2 > li > a {
  color: #050709;
}

ul.social-profiles-v2 > li > a:hover {
  color: #fd367e;
}

ul.social-profiles-v2.transparent > li > a,
.transparent-area ul.social-profiles-v2 > li > a {
  color: #FFF;
}

ul.social-profiles-v2.transparent > li > a:hover,
.transparent-area ul.social-profiles-v2 > li > a:hover {
  color: #fd367e;
}

ul.post-meta {
  margin: 0 -10px;
  padding: 0;
}

ul.post-meta > li {
  display: inline-block;
  padding: 0 10px;
}

ul.post-meta > li > .dt-sprite {
  width: 18px;
  margin-right: 12px;
  fill: #fd367e;
}

ul.post-meta > li > i {
  color: #fd367e;
  margin-right: 10px;
}

ul.post-meta > li > a {
  color: #757575;
}

/*-----------------------------------------------
  ## Form
  ---------------------------------------------*/
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="time"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime-local"],
input[type="search"],
input[type="tel"],
input[type="url"],
textarea,
select,
.form-control {
  background-color: #f5f5f7;
  border-radius: 0;
  box-shadow: none;
  display: block;
  width: 100%;
  padding: 10px 20px;
  line-height: 1;
  border: 1px solid transparent;
  transition: all 0.15s ease-in-out;
  outline: none;
  line-height: 1;
  height: 46px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="time"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="datetime-local"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
textarea:focus,
select:focus,
.form-control:focus {
  box-shadow: none;
  border-color: #4e1184;
  background-color: #FFF;
  outline-color: transparent;
}

.transparent-form input[type="text"], .transparent-form
input[type="password"], .transparent-form
input[type="email"], .transparent-form
input[type="number"], .transparent-form
input[type="time"], .transparent-form
input[type="date"], .transparent-form
input[type="month"], .transparent-form
input[type="week"], .transparent-form
input[type="datetime-local"], .transparent-form
input[type="search"], .transparent-form
input[type="tel"], .transparent-form
input[type="url"], .transparent-form
textarea, .transparent-form
select, .transparent-form
.form-control {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #FFF;
  color: #FFF;
}

.transparent-form input[type="text"]:focus, .transparent-form
input[type="password"]:focus, .transparent-form
input[type="email"]:focus, .transparent-form
input[type="number"]:focus, .transparent-form
input[type="time"]:focus, .transparent-form
input[type="date"]:focus, .transparent-form
input[type="month"]:focus, .transparent-form
input[type="week"]:focus, .transparent-form
input[type="datetime-local"]:focus, .transparent-form
input[type="search"]:focus, .transparent-form
input[type="tel"]:focus, .transparent-form
input[type="url"]:focus, .transparent-form
textarea:focus, .transparent-form
select:focus, .transparent-form
.form-control:focus {
  border-color: #4e1184;
}

.transparent-form select option {
  color: #000;
}

select {
  -webkit-appearance: none;
}

textarea,
textarea.form-control {
  height: auto;
  padding: 20px;
}

/*-----------------------------------------------
  ## Contact Form 7 Support
  ---------------------------------------------*/
form.wpcf7-form .wpcf7-form-control.wpcf7-not-valid {
  border-color: #f00;
}

form.wpcf7-form .ajax-loader {
  display: none;
}

form.wpcf7-form .ajax-loader.is-active {
  display: inline-block;
}

form.wpcf7-form .wpcf7-response-output {
  margin-left: 0;
  margin-right: 0;
}

/*-----------------------------------------------
  ## Tables
  ---------------------------------------------*/
/*-----------------------------------------------
  ## Typography
  ---------------------------------------------*/
h1, h2, h3, h4, h5, h5 {
  color: #050709;
  font-weight: 700;
}

.h1, .h2, .h3, h1, h2, h3 {
  margin-top: 30px;
  margin-bottom: 15px;
}

p {
  margin-bottom: 20px;
  line-height: 26px;
  line-height: 1.85714em;
}

.transparent-area {
  color: #FFF;
}

.transparent-area h1, .transparent-area h2, .transparent-area h3, .transparent-area h4, .transparent-area h5, .transparent-area h5, .transparent-area a {
  color: #FFF;
}

/*-----------------------------------------------
  ## WordPress Core
  ---------------------------------------------*/
.transparent-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.5);
}

.transparent-form ::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.5);
}

.transparent-form :-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.5);
}

.transparent-form :-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5);
}

/* Bootstrap Fix */
.h6, h6 {
  font-size: 1em;
}

/* Alignments */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Caption */
.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 100%;
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption .wp-caption-text {
  padding-top: 5px;
}

/* Tables */
table {
  border-collapse: collapse;
  margin: 0 0 1.5em;
  width: 100%;
  border: 1px solid #eee;
}

thead th {
  border-bottom: 2px solid #bbb;
  padding-bottom: 0.5em;
}

th {
  padding: 10px;
  text-align: left;
}

tr {
  border-bottom: 1px solid #eee;
  transition: all 0.15s ease-in-out;
}

td {
  padding: 10px;
}

tr:hover {
  background-color: #eee;
}

/* Lists */
dt {
  font-weight: 700;
}

dd {
  margin: 0 20px 20px;
}

/* Gallery */
.gallery {
  margin-bottom: 20px;
  margin-left: -10px;
  margin-right: -10px;
}

.gallery .gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
  margin-bottom: 0 !important;
  padding: 10px;
}

.gallery .gallery-item a {
  display: block;
}

.gallery .gallery-item img {
  margin-bottom: 0 !important;
  max-width: 100%;
  height: auto;
}

.gallery .gallery-caption {
  display: block;
  margin-top: 10px;
}

.gallery.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery.gallery-columns-10 .gallery-item {
  max-width: 10%;
}

/* WordPress Admin Hacks */
@media screen and (max-width: 782px) {
  .admin-bar.fix-header .site-header {
    margin-top: 46px;
  }
}

@media screen and (min-width: 783px) {
  .admin-bar.fix-header .site-header {
    margin-top: 32px;
  }
}

@media screen and (max-width: 600px) {
  .admin-bar.fix-header .site-header {
    margin-top: 0;
  }
}

/*-----------------------------------------------
  ## WooCommerce Hacks
  ---------------------------------------------*/
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
  padding: 10px 15px;
  background-color: #fd367e;
  border: 1px solid #fd367e;
  color: #FFF;
  border-radius: 0;
}

.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover {
  background-color: transparent;
  color: #fd367e;
}

.woocommerce a.added_to_cart {
  line-height: 1;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #4e1184;
  border: 1px solid #4e1184;
  color: #fff;
}

.woocommerce a.added_to_cart:after {
  content: "\f178";
  font: normal normal normal 1em/1 FontAwesome;
  margin-left: 5px;
}

.woocommerce a.added_to_cart:hover {
  background-color: transparent;
  color: #4e1184;
}

.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
  padding: 15px 20px;
  background-color: #fd367e;
}

.woocommerce #respond input#submit.alt:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover {
  background-color: #b20241;
}

.woocommerce #respond input#submit.alt.disabled,
.woocommerce #respond input#submit.alt.disabled:hover,
.woocommerce #respond input#submit.alt:disabled,
.woocommerce #respond input#submit.alt:disabled:hover,
.woocommerce #respond input#submit.alt:disabled[disabled],
.woocommerce #respond input#submit.alt:disabled[disabled]:hover,
.woocommerce a.button.alt.disabled,
.woocommerce a.button.alt.disabled:hover,
.woocommerce a.button.alt:disabled,
.woocommerce a.button.alt:disabled:hover,
.woocommerce a.button.alt:disabled[disabled],
.woocommerce a.button.alt:disabled[disabled]:hover,
.woocommerce button.button.alt.disabled,
.woocommerce button.button.alt.disabled:hover,
.woocommerce button.button.alt:disabled,
.woocommerce button.button.alt:disabled:hover,
.woocommerce button.button.alt:disabled[disabled],
.woocommerce button.button.alt:disabled[disabled]:hover,
.woocommerce input.button.alt.disabled,
.woocommerce input.button.alt.disabled:hover,
.woocommerce input.button.alt:disabled,
.woocommerce input.button.alt:disabled:hover,
.woocommerce input.button.alt:disabled[disabled],
.woocommerce input.button.alt:disabled[disabled]:hover {
  background-color: #fd367e;
}

.woocommerce #respond input#submit.disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce #respond input#submit:disabled[disabled],
.woocommerce a.button.disabled,
.woocommerce a.button:disabled,
.woocommerce a.button:disabled[disabled],
.woocommerce button.button.disabled,
.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce input.button.disabled,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled] {
  color: #FFF;
}

.woocommerce #respond input#submit.disabled:hover,
.woocommerce #respond input#submit:disabled:hover,
.woocommerce #respond input#submit:disabled[disabled]:hover,
.woocommerce a.button.disabled:hover,
.woocommerce a.button:disabled:hover,
.woocommerce a.button:disabled[disabled]:hover,
.woocommerce button.button.disabled:hover,
.woocommerce button.button:disabled:hover,
.woocommerce button.button:disabled[disabled]:hover,
.woocommerce input.button.disabled:hover,
.woocommerce input.button:disabled:hover,
.woocommerce input.button:disabled[disabled]:hover {
  background-color: transparent;
  color: #fd367e;
}

.woocommerce ul.products li.product,
.woocommerce-page ul.products li.product {
  width: 30.741765%;
  float: left;
  margin-right: 3.882353%;
  text-align: center;
}

.woocommerce ul.products li.last,
.woocommerce-page ul.products li.last {
  margin-right: 0;
}

.woocommerce ul.products li.product .star-rating {
  margin: 10px auto 10px;
  color: #050709;
}

.woocommerce ul.products li.product .woocommerce-loop-category__title,
.woocommerce ul.products li.product .woocommerce-loop-product__title,
.woocommerce ul.products li.product h3 {
  font-size: 1.2em;
}

.woocommerce ul.products li.product .price {
  font-size: 1em;
}

.woocommerce span.onsale {
  border-radius: 0;
  padding: 5px 10px;
  line-height: 1;
  height: auto;
  min-height: auto;
  top: 0;
  left: 0;
  font-size: 1.2em;
}

.woocommerce ul.products li.product .onsale {
  top: 0;
  right: 0;
  margin: 0;
}

.woocommerce .widget_shopping_cart .cart_list li,
.woocommerce.widget_shopping_cart .cart_list li {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ebe9eb;
}

.woocommerce .widget_shopping_cart .cart_list li:last-child,
.woocommerce.widget_shopping_cart .cart_list li:last-child {
  border-bottom: none;
}

.woocommerce ul.cart_list li img,
.woocommerce ul.product_list_widget li img {
  width: 40px;
}

.woocommerce .widget_shopping_cart .total,
.woocommerce.widget_shopping_cart .total {
  border-top: 1px solid #ebe9eb;
  border-bottom: 1px solid #ebe9eb;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a {
  display: block;
  text-align: center;
}

.woocommerce div.product div.images .flex-control-thumbs {
  margin-left: -5px;
  margin-right: -5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.woocommerce div.product div.images .flex-control-thumbs li {
  padding: 5px;
}

.woocommerce .quantity .qty {
  width: 5.8em;
}

.woocommerce div.product form.cart .variations td,
.woocommerce div.product form.cart .variations th {
  display: list-item;
  list-style: none;
  line-height: 1;
}

.woocommerce div.product form.cart .variations label {
  color: #757575;
  text-align: left;
}

.woocommerce div.product form.cart .variations select {
  width: auto;
}

.woocommerce div.product form.cart .variations td.label {
  text-align: left;
  font-size: 16px;
  font-size: 1.14286em;
}

.woocommerce div.product form.cart .reset_variations {
  font-size: 14px;
  font-size: 1em;
  color: #757575;
  font-weight: 600;
}

.woocommerce div.product form.cart .reset_variations:before {
  content: "\f021";
  font: normal normal normal 1em/1 FontAwesome;
  margin-right: 5px;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text {
  margin-left: 74px;
}

.woocommerce #reviews #comments ol.commentlist li img.avatar {
  width: 60px;
  padding: 2px;
}

.woocommerce #content table.cart td.actions .input-text,
.woocommerce table.cart td.actions .input-text,
.woocommerce-page #content table.cart td.actions .input-text,
.woocommerce-page table.cart td.actions .input-text {
  width: 150px;
  height: auto;
  padding: 9px 15px;
  background-color: #f5f5f7;
  border: 1px solid transparent;
  line-height: 1;
}

.woocommerce #content table.cart td.actions .input-text:focus,
.woocommerce table.cart td.actions .input-text:focus,
.woocommerce-page #content table.cart td.actions .input-text:focus,
.woocommerce-page table.cart td.actions .input-text:focus {
  border-color: #4e1184;
}

#add_payment_method table.cart img,
.woocommerce-cart table.cart img,
.woocommerce-checkout table.cart img {
  width: 64px;
  margin-bottom: 0;
}

.woocommerce form .form-row.create-account {
  line-height: 1;
}

.woocommerce form .form-row.create-account > label {
  display: block;
  line-height: 1;
}

.woocommerce form .form-row.create-account > label > span,
.woocommerce form .form-row.create-account > label > input {
  margin: 0;
}

.woocommerce form .form-row.create-account > label > span {
  margin-left: 20px;
}

.widget_product_categories ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widget_product_categories ul li {
  margin-bottom: 10px;
}

.widget_product_categories ul li:before {
  content: "\f114";
  margin-right: 5px;
  font: normal normal normal 1em/1 FontAwesome;
}

.widget_product_categories ul li a {
  color: #050709;
}

.widget_product_categories .children {
  margin-top: 10px;
  margin-left: 15px;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle,
.woocommerce .widget_price_filter .ui-slider .ui-slider-range {
  background-color: #fd367e;
}

.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background-color: #b20241;
}

.woocommerce nav.woocommerce-pagination > ul {
  border: none;
}

.woocommerce nav.woocommerce-pagination > ul > li {
  border: 1px solid #4e1184;
}

.woocommerce nav.woocommerce-pagination > ul > li + li {
  margin-left: 10px;
}

.woocommerce nav.woocommerce-pagination > ul > li > span,
.woocommerce nav.woocommerce-pagination > ul > li > a {
  padding: 8px 10px;
  color: #050709;
}

.woocommerce nav.woocommerce-pagination > ul > li > span.current,
.woocommerce nav.woocommerce-pagination > ul > li > a:hover {
  background-color: #4e1184;
  color: #fff;
}

/*-----------------------------------------------
  ## VC Hacks
  ---------------------------------------------*/
#visual-composer-content > .vc_row {
  margin-left: 0;
  margin-right: 0;
}

#visual-composer-content > .vc_row > .vc_col-xs-12 > .vc_column-inner,
#visual-composer-content > .vc_row > .vc_col-sm-12 > .vc_column-inner,
#visual-composer-content > .vc_row > .vc_col-md-12 > .vc_column-inner,
#visual-composer-content > .vc_row > .vc_col-lg-12 > .vc_column-inner,
#visual-composer-content > .vc_row > .container > .vc_col-xs-12 > .vc_column-inner,
#visual-composer-content > .vc_row > .container > .vc_col-sm-12 > .vc_column-inner,
#visual-composer-content > .vc_row > .container > .vc_col-md-12 > .vc_column-inner,
#visual-composer-content > .vc_row > .container > .vc_col-lg-12 > .vc_column-inner {
  padding-left: 0;
  padding-right: 0;
}

/*-----------------------------------------------
  # Elements
  ---------------------------------------------*/
/*-----------------------------------------------
  ## Event Countdown
  ---------------------------------------------*/
.event-countdown {
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-countdown:before, .event-countdown:after {
  content: "";
  display: table;
}

.event-countdown:after {
  clear: both;
}

.event-countdown > .event-info {
  margin-right: 50px;
  margin-left: 30px;
}

.event-countdown > .countdown > .item {
  display: inline-block;
}

.event-countdown > .countdown > .item + .item {
  margin-left: 15px;
}

.event-countdown > .countdown > .item > span {
  display: block;
  text-transform: uppercase;
  line-height: 1;
}

.event-countdown > .countdown > .item > span.number {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  font-size: 50px;
  font-size: 3.57143em;
}

.event-countdown > .countdown > .item > span.number:after {
  content: ' :';
}

.event-countdown > .countdown > .item:last-child > .number:after {
  display: none;
}

.event-countdown.style-two {
  display: block;
  position: relative;
  color: #FFF;
}

.event-countdown.style-two > .event-info {
  position: absolute;
  bottom: 10px;
  left: -250px;
  font-weight: 700;
  z-index: 20;
}

.event-countdown.style-two > .countdown {
  position: relative;
  float: left;
  margin-bottom: 30px;
}

.event-countdown.style-two > .countdown > .item {
  text-align: center;
  position: relative;
  margin-right: 40px;
}

.event-countdown.style-two > .countdown > .item > span.number {
  width: 112px;
  height: 75px;
  position: relative;
  overflow: hidden;
  z-index: 10;
  padding-top: 25px;
  font-size: 40px;
  font-size: 2.85714em;
}

.event-countdown.style-two > .countdown > .item > span.number:after {
  content: '';
  position: absolute;
  width: 112px;
  height: 112px;
  background-color: rgba(0, 0, 0, 0.7);
  border: 3px solid #FFF;
  border-radius: 50%;
  top: 0;
  left: 0;
  z-index: -1;
}

.event-countdown.style-two > .countdown > .item > span.text {
  position: absolute;
  z-index: 20;
  width: 100%;
  bottom: -19px;
}

.event-countdown.style-two > .countdown > .item:last-child > .number:after {
  display: block;
}

.event-countdown.style-two > .countdown:after, .event-countdown.style-two > .countdown:before {
  content: '';
  position: absolute;
  height: 26px;
  background-color: rgba(253, 54, 126, 0.7);
  width: 110%;
  left: 0px;
  bottom: -21px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}

.event-countdown.style-two > .countdown:before {
  width: 200%;
  left: -200%;
  border-radius: 0;
}

.event-countdown.style-three {
  position: relative;
  padding-top: 30px;
}

.event-countdown.style-three > .event-info {
  position: absolute;
  top: 0;
  margin: 0;
  width: 100%;
}

.event-countdown.style-three > .countdown > .item {
  text-align: left;
}

.event-countdown.style-three > .countdown > .item > .text {
  margin-bottom: 5px;
}

@media (max-width: 1440px) {
  .event-countdown.style-two > .event-info {
    position: relative;
    bottom: auto;
    left: auto;
    margin-left: 0;
    margin-bottom: 30px;
    float: left;
    line-height: 1.8em;
    background-color: rgba(253, 54, 126, 0.7);
    padding-top: 2px;
    padding-left: 25px;
    padding-right: 50px;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
  }
}

@media (max-width: 1024px) {
  .event-countdown > .countdown {
    text-align: center;
  }
  .event-countdown > .countdown > .item > span.number {
    font-size: 30px;
    font-size: 2.14286em;
  }
  .event-countdown.style-two > .countdown {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .event-countdown {
    text-align: center;
  }
  .event-countdown > .event-info {
    margin-left: 0;
  }
  .event-countdown > .countdown > .item > span.number {
    font-size: 26px;
    font-size: 1.85714em;
  }
  .event-countdown > .countdown > .item > span.text {
    margin-bottom: 5px;
  }
  .event-countdown.style-two {
    text-align: left;
  }
  .event-countdown.style-two > .countdown > .item {
    margin-right: 5px;
  }
  .event-countdown.style-two > .countdown > .item > span.number {
    width: 65px;
    height: 45px;
    padding-top: 15px;
    font-size: 22px;
    font-size: 1.57143em;
  }
  .event-countdown.style-two > .countdown > .item > span.number:after {
    width: 65px;
    height: 65px;
  }
  .event-countdown.style-two > .countdown > .item > span.text {
    bottom: -25px;
  }
}

@media (max-width: 500px) {
  .event-countdown {
    display: block;
  }
  .event-countdown > .event-info {
    margin: 0;
    margin-bottom: 10px;
  }
  .event-countdown > .countdown > .item > span.number {
    font-size: 18px;
    font-size: 1.28571em;
  }
  .event-countdown > .countdown > .item > span.text {
    font-size: 12px;
    font-size: 0.85714em;
  }
  .event-countdown.style-two > .countdown > .item {
    margin-right: 5px;
  }
  .event-countdown.style-two > .countdown > .item > span.number {
    width: 55px;
    height: 40px;
    padding-top: 15px;
    font-size: 22px;
    font-size: 1.57143em;
  }
  .event-countdown.style-two > .countdown > .item > span.number:after {
    width: 55px;
    height: 55px;
  }
  .event-countdown.style-two > .countdown > .item > span.text {
    bottom: -25px;
  }
}

/*-----------------------------------------------
  ## Navbar (Standard)
  ---------------------------------------------*/
.dt-standard-navbar {
  border: 0;
  margin: 0;
  border-radius: 0;
}

.dt-standard-navbar .navbar-header {
  position: relative;
  z-index: 2;
}

.dt-standard-navbar .navbar-header > .navbar-brand {
  display: block;
  width: 128px;
  height: 44px;
  padding: 0;
  margin: 12px 100px 12px 0;
}

.dt-standard-navbar .navbar-header > .navbar-brand > img {
  width: 100%;
  height: auto;
}

.dt-standard-navbar .navbar-header > .navbar-brand > img.white-logo {
  display: block;
}

.dt-standard-navbar .navbar-header > .navbar-brand > img.white-logo {
  display: none;
}

.dt-standard-navbar .navbar-collapse {
  margin-right: -15px;
  margin-left: -15px;
  border-top: none;
}

.dt-standard-navbar .nav.navbar-nav {
  margin-top: 0;
  margin-bottom: 0;
}

.dt-standard-navbar .nav.navbar-nav > li {
  margin-right: 46px;
}

@media (max-width: 1024px) {
  .dt-standard-navbar .nav.navbar-nav > li {
    margin-right: 25px;
  }
}

.dt-standard-navbar .nav.navbar-nav > li:last-child {
  margin-right: 0;
}

.dt-standard-navbar .nav.navbar-nav > li > a {
  font-weight: 500;
  color: #757575;
  padding: 24px 0;
  text-transform: uppercase;
}

.dt-standard-navbar .nav.navbar-nav > li > a:hover, .dt-standard-navbar .nav.navbar-nav > li > a:focus {
  background-color: transparent;
  color: #4e1184;
}

.dt-standard-navbar .nav.navbar-nav > li.active > a,
.dt-standard-navbar .nav.navbar-nav > li.current-menu-item > a,
.dt-standard-navbar .nav.navbar-nav > li.current-menu-ancestor > a {
  color: #4e1184;
}

.dt-standard-navbar .nav.navbar-nav > li.menu-item-has-children > a:after {
  content: '\f107';
  display: inline-block;
  font-family: FontAwesome;
  margin-left: 5px;
  transition: all 0.15s ease-in-out;
}

.dt-standard-navbar .nav.navbar-nav li {
  position: relative;
  overflow: hidden;
}

.dt-standard-navbar .nav.navbar-nav li.children-menu-visible {
  overflow: visible;
}

.dt-standard-navbar .nav.navbar-nav li.children-menu-visible > .sub-menu {
  opacity: 1;
  transform: translateY(0px);
}

.dt-standard-navbar .nav.navbar-nav .sub-menu {
  list-style: none;
  padding: 15px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  min-width: 225px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: #2d2d2d;
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.25s ease-in-out;
}

.dt-standard-navbar .nav.navbar-nav .sub-menu .sub-menu {
  left: 100%;
  top: 0;
}

.dt-standard-navbar .nav.navbar-nav .sub-menu > li {
  border-bottom: 1px solid #3a3a3a;
}

.dt-standard-navbar .nav.navbar-nav .sub-menu > li:last-child {
  border-bottom: none;
}

.dt-standard-navbar .nav.navbar-nav .sub-menu > li > a {
  display: block;
  padding: 10px 20px;
  white-space: nowrap;
  color: #bbbbbb;
  text-transform: uppercase;
}

.dt-standard-navbar .nav.navbar-nav .sub-menu > li > a:hover {
  color: #FFF;
}

.dt-standard-navbar .nav.navbar-nav .sub-menu > li.menu-item-has-children > a {
  position: relative;
  padding-right: 40px;
}

.dt-standard-navbar .nav.navbar-nav .sub-menu > li.menu-item-has-children > a:after {
  content: '\f105';
  font-family: FontAwesome;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
  transition: all 0.15s ease-in-out;
}

.dt-standard-navbar .navbar-form {
  position: absolute;
  margin: 0;
  padding: 0;
  box-shadow: none;
  width: 220px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.dt-standard-navbar .navbar-form > input {
  background-color: transparent;
  width: 100%;
  border: 1px solid #eeeeee;
}

.dt-standard-navbar .navbar-form > input::-webkit-input-placeholder {
  color: #565656;
}

.dt-standard-navbar .navbar-form > input:-moz-placeholder {
  color: #565656;
}

.dt-standard-navbar .navbar-form > input::-moz-placeholder {
  color: #565656;
}

.dt-standard-navbar .navbar-form > input:-ms-input-placeholder {
  color: #565656;
}

.dt-standard-navbar .navbar-form > input:focus {
  border-color: #fd367e;
}

.dt-standard-navbar .navbar-form > button {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: #5a5a5a;
  padding: 0;
  line-height: 1;
}

.dt-standard-navbar .has-search-form {
  position: relative;
}

.dt-standard-navbar .has-search-form .nav.navbar-nav {
  margin-right: 225px;
}

.dt-standard-navbar.no-brand-logo .navbar-header > .navbar-brand {
  display: none;
}

.dt-standard-navbar .center-menu > .nav.navbar-nav {
  display: table;
  margin: 0 auto;
  float: none;
}

@media (max-width: 767px) {
  .dt-standard-navbar {
    min-height: 35px;
    background-color: #1b1b1b !important;
  }
  .dt-standard-navbar .navbar-header {
    background-color: #4e1184;
    border-top: 1px solid #410e6d;
  }
  .dt-standard-navbar .navbar-header > .navbar-brand {
    display: block;
    margin: 9px 15px !important;
  }
  .dt-standard-navbar .navbar-header > .navbar-brand > img.black-logo {
    display: none !important;
  }
  .dt-standard-navbar .navbar-header > .navbar-brand > img.white-logo {
    display: block !important;
  }
  .dt-standard-navbar .navbar-header > .navbar-toggle {
    float: right;
    margin: 14px;
    position: relative;
  }
  .dt-standard-navbar .navbar-header > .navbar-toggle:hover, .dt-standard-navbar .navbar-header > .navbar-toggle:focus {
    background-color: transparent;
  }
  .dt-standard-navbar .navbar-header > .navbar-toggle > .icon-bar {
    background-color: #e5cef9;
    transition: all 0.15s ease-in-out;
  }
  .dt-standard-navbar .navbar-header > .navbar-toggle .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }
  .dt-standard-navbar .navbar-header > .navbar-toggle .middle-bar {
    opacity: 0;
  }
  .dt-standard-navbar .navbar-header > .navbar-toggle .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }
  .dt-standard-navbar .navbar-header > .navbar-toggle.collapsed .top-bar {
    transform: rotate(0);
  }
  .dt-standard-navbar .navbar-header > .navbar-toggle.collapsed .middle-bar {
    opacity: 1;
  }
  .dt-standard-navbar .navbar-header > .navbar-toggle.collapsed .bottom-bar {
    transform: rotate(0);
  }
  .dt-standard-navbar .nav.navbar-nav .menu-item-has-children > .sub-menu {
    display: none;
  }
  .dt-standard-navbar .nav.navbar-nav .menu-item-has-children.children-menu-visible > .sub-menu {
    display: block;
  }
  .dt-standard-navbar .nav.navbar-nav > li {
    border-bottom: 1px solid #292929;
    margin-right: 0;
  }
  .dt-standard-navbar .nav.navbar-nav > li:last-child {
    border-bottom: none;
  }
  .dt-standard-navbar .nav.navbar-nav > li > a {
    color: #bbbbbb !important;
    padding: 10px 23px !important;
  }
  .dt-standard-navbar .nav.navbar-nav > li > a:hover, .dt-standard-navbar .nav.navbar-nav > li > a:focus {
    color: #FFF;
  }
  .dt-standard-navbar .nav.navbar-nav > li.menu-item-has-children > a:after {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) rotate(-90deg);
    opacity: 0.5;
  }
  .dt-standard-navbar .nav.navbar-nav > li.children-menu-visible > a:after {
    transform: translateY(-50%) rotate(0deg);
  }
  .dt-standard-navbar .nav.navbar-nav .sub-menu {
    padding-top: 0;
    border-top: 1px solid #3a3a3a;
    position: static;
    transform: none;
    opacity: 1;
    box-shadow: none;
    padding: 0;
  }
  .dt-standard-navbar .nav.navbar-nav .sub-menu .sub-menu {
    background-color: #3a3a3a;
  }
  .dt-standard-navbar .nav.navbar-nav .sub-menu .sub-menu > li {
    border-color: #464646;
  }
  .dt-standard-navbar .nav.navbar-nav .sub-menu .sub-menu .sub-menu {
    background-color: #464646;
  }
  .dt-standard-navbar .nav.navbar-nav .sub-menu .sub-menu .sub-menu > li {
    border-color: #505050;
  }
  .dt-standard-navbar .nav.navbar-nav .sub-menu > li {
    border-left: 1px solid transparent;
  }
  .dt-standard-navbar .nav.navbar-nav .sub-menu > li.children-menu-visible {
    border-left: 1px solid #3a3a3a;
  }
  .dt-standard-navbar .nav.navbar-nav .sub-menu > li.children-menu-visible > a:after {
    transform: translateY(-50%) rotate(90deg);
  }
  .dt-standard-navbar .navbar-form {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    padding-right: 15px;
  }
  .dt-standard-navbar .navbar-form > input {
    color: #FFF;
    border-color: #DDD;
  }
  .dt-standard-navbar .navbar-form > button {
    right: 25px;
  }
  .dt-standard-navbar .navbar-collapse {
    padding-right: 0;
  }
  .dt-standard-navbar .navbar-collapse.has-search-form {
    position: relative;
  }
  .dt-standard-navbar .navbar-collapse.has-search-form .nav.navbar-nav {
    margin-right: 0;
  }
  .dt-standard-navbar .center-menu > .nav.navbar-nav {
    display: block;
    margin: 0 0 0 -15px;
  }
  .dt-standard-navbar.no-brand-logo .navbar-header > .navbar-brand {
    display: block;
  }
}

/*-----------------------------------------------
  ## Navbar (Transparent)
  ---------------------------------------------*/
.transparent-header .dt-standard-navbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.2);
}

.transparent-header .dt-standard-navbar .navbar-header > .navbar-brand {
  margin: 16px 100px 16px 0;
}

.transparent-header .dt-standard-navbar .navbar-header > .navbar-brand > img {
  width: 100%;
  height: auto;
}

.transparent-header .dt-standard-navbar .navbar-header > .navbar-brand > img.white-logo {
  display: block;
}

.transparent-header .dt-standard-navbar .navbar-header > .navbar-brand > img.black-logo {
  display: none;
}

.transparent-header .dt-standard-navbar .nav.navbar-nav > li > a {
  color: #FFF;
  padding: 29px 0;
}

.transparent-header .dt-standard-navbar .nav.navbar-nav > li > a:hover, .transparent-header .dt-standard-navbar .nav.navbar-nav > li > a:focus {
  color: #4e1184;
}

.transparent-header .dt-standard-navbar .nav.navbar-nav > li.active > a,
.transparent-header .dt-standard-navbar .nav.navbar-nav > li.current-menu-item > a,
.transparent-header .dt-standard-navbar .nav.navbar-nav > li.current-menu-ancestor > a {
  color: #4e1184;
}

.transparent-header .dt-standard-navbar .nav.navbar-nav > li > .sub-menu {
  margin-top: 1px;
}

.transparent-header.fix-header .dt-standard-navbar .navbar-header > .navbar-brand {
  margin: 12px 100px 12px 0;
}

.transparent-header.fix-header .dt-standard-navbar .navbar-header > .navbar-brand > img.white-logo {
  display: none;
}

.transparent-header.fix-header .dt-standard-navbar .navbar-header > .navbar-brand > img.black-logo {
  display: block;
}

.transparent-header.fix-header .dt-standard-navbar .nav.navbar-nav > li > a {
  color: #757575;
  padding: 24px 0;
}

.transparent-header.fix-header .dt-standard-navbar .nav.navbar-nav > li > a:hover, .transparent-header.fix-header .dt-standard-navbar .nav.navbar-nav > li > a:focus {
  color: #4e1184;
}

.transparent-header.fix-header .dt-standard-navbar .nav.navbar-nav > li.active > a,
.transparent-header.fix-header .dt-standard-navbar .nav.navbar-nav > li.current-menu-item > a,
.transparent-header.fix-header .dt-standard-navbar .nav.navbar-nav > li.current-menu-ancestor > a {
  color: #4e1184;
}

.transparent-header.fix-header .dt-standard-navbar .nav.navbar-nav > .sub-menu {
  margin-top: 0px;
}

@media (max-width: 767px) {
  .transparent-header .dt-standard-navbar {
    border-bottom-color: transparent;
  }
}

/*-----------------------------------------------
  ## Event Statics
  ---------------------------------------------*/
.event-statics {
  display: flex;
  align-items: center;
  border: 1px solid #bfbfbf;
}

.event-statics:before, .event-statics:after {
  content: "";
  display: table;
}

.event-statics:after {
  clear: both;
}

.banner-statics .event-statics {
  border: none;
}

.event-statics > .item {
  width: 33.33333333333333%;
  float: left;
  border-right: 1px solid #bfbfbf;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.event-statics > .item:last-child {
  border-right: none;
}

.event-statics > .item > .icon {
  display: inline-block;
  margin-right: 12px;
}

.event-statics > .item > .icon > i {
  font-size: 38px;
}

.event-statics > .item > .icon > img {
  max-height: 38px;
  width: auto;
}

.event-statics > .item > .content {
  display: inline-block;
  line-height: 1;
}

.event-statics > .item > .content > .number {
  font-family: "Josefin Sans", sans-serif;
  font-size: 30px;
  font-size: 2.14286em;
  font-weight: 600;
  line-height: 1;
  margin-right: 5px;
}

@media (max-width: 500px) {
  .event-statics > .item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .event-statics > .item > .icon {
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
    height: 30px;
  }
  .event-statics > .item > .icon > i {
    font-size: 26px;
  }
  .event-statics > .item > .icon > img {
    max-height: 26px;
  }
  .event-statics > .item > .content {
    display: block;
  }
  .event-statics > .item > .content > span {
    display: block;
  }
  .event-statics > .item > .content > span.number {
    margin: 0;
    font-size: 18px;
    font-size: 1.28571em;
    margin-bottom: 5px;
  }
}

/*-----------------------------------------------
  ## Carousel Controls
  ---------------------------------------------*/
/* Pagination */
.swiper-pagination {
  margin: 0;
  padding: 0;
  bottom: 10px;
  z-index: 200;
  position: absolute;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  line-height: 1;
}

.swiper-pagination:before, .swiper-pagination:after {
  content: "";
  display: table;
}

.swiper-pagination:after {
  clear: both;
}

.swiper-pagination > li {
  display: block;
  float: left;
  width: auto;
  height: auto;
  color: #FFF;
  background-color: transparent;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  font-size: 20px;
  font-size: 1.42857em;
  margin-right: 35px;
  opacity: 0.5;
  transition: all 0.15s ease-in-out;
}

.swiper-pagination > li > span {
  display: block;
}

.swiper-pagination > li[class*=active] {
  opacity: 1;
}

.swiper-pagination.vertical {
  display: block;
  top: 50%;
  right: 10px;
  left: auto;
  bottom: auto;
  transform: translateY(-50%);
}

.swiper-pagination.vertical > li {
  float: none;
  margin-right: 0;
  margin-bottom: 35px;
}

.swiper-pagination.vertical > li:last-child {
  margin-bottom: 0;
}

/* Navigation */
.carousel-button-prev,
.carousel-button-next {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -15px;
  background: transparent;
  transition: all 0.15s ease-in-out;
  border: 1px solid #fd367e;
  z-index: 10;
}

.carousel-button-prev > i,
.carousel-button-next > i {
  color: #fd367e;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carousel-button-prev:hover,
.carousel-button-next:hover {
  background-color: #4e1184;
  border-color: #4e1184;
  cursor: pointer;
}

.carousel-button-prev:hover > i,
.carousel-button-next:hover > i {
  color: #FFF;
}

.transparent-area .carousel-button-prev, .transparent-area
.carousel-button-next {
  background-color: transparent;
  border-color: #FFF;
  opacity: 0.5;
}

.transparent-area .carousel-button-prev > i, .transparent-area
.carousel-button-next > i {
  color: #FFF;
}

.transparent-area .carousel-button-prev:hover, .transparent-area
.carousel-button-next:hover {
  opacity: 1;
  background-color: #FFF;
  border-color: #FFF;
}

.transparent-area .carousel-button-prev:hover > i, .transparent-area
.carousel-button-next:hover > i {
  color: #4e1184;
}

.carousel-button-next {
  right: 0;
  left: auto;
}

/* Controls */
.slider-control {
  position: absolute;
  padding-top: 85px;
  padding-bottom: 85px;
  top: 50%;
  right: 150px;
  transform: translateY(-50%);
  z-index: 200;
  backface-visibility: hidden;
}

.slider-control > .swiper-pagination {
  position: relative;
  margin: 0;
  left: auto;
  right: auto;
  transform: none;
}

.slider-control > .carousel-button-prev {
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: 0;
  transform: translateX(-50%);
}

.slider-control > .carousel-button-next {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1024px) {
  .slider-control {
    right: 25px;
  }
}

@media (max-width: 768px) {
  .slider-control {
    right: 50%;
    top: auto;
    bottom: 25px;
    transform: translateX(50%);
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 85px;
    padding-left: 85px;
    width: 100%;
  }
  .slider-control.position-two {
    bottom: 115px;
  }
  .slider-control > .carousel-button-prev,
  .slider-control > .carousel-button-next {
    margin: 0;
    top: 50%;
    right: auto;
    bottom: auto;
    left: auto;
    transform: translateY(-50%);
  }
  .slider-control > .carousel-button-prev {
    left: 15px;
  }
  .slider-control > .carousel-button-next {
    right: 15px;
  }
  .slider-control > .swiper-pagination {
    top: auto;
  }
  .slider-control > .swiper-pagination > li {
    margin-bottom: 0;
    margin-right: 10px;
    display: inline-block;
    text-align: center;
  }
}

/*-----------------------------------------------
  ## Event Schedule Info
  ---------------------------------------------*/
.event-schedule-info > .section-title > .title {
  margin-bottom: 30px;
}

.event-schedule-info > .info-content p {
  margin-bottom: 30px;
}

.event-schedule-info > .info-content ul {
  padding-top: 25px;
}

.event-schedule-info > .info-content ul > li {
  color: #050709;
  font-weight: 500;
}

/*-----------------------------------------------
  ## Event Schedule Tabs
  ---------------------------------------------*/
.event-schedule-tabs > .nav {
  border: none;
  text-align: center;
  display: table;
  margin: 0 auto;
}

.event-schedule-tabs > .nav > li {
  margin-right: 20px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.event-schedule-tabs > .nav > li:after {
  content: '';
  position: absolute;
  width: 100%;
  z-index: -1;
  height: 1px;
  background-color: #eeeeee;
  top: 50%;
  left: 20%;
}

.event-schedule-tabs > .nav > li:last-child {
  margin-right: 0;
}

.event-schedule-tabs > .nav > li:last-child:after {
  display: none;
}

.event-schedule-tabs > .nav > li > a {
  border: 1px solid #eeeeee;
  border-radius: 0;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  color: #050709;
  font-size: 16px;
  font-size: 1.14286em;
  padding-right: 24px;
  padding-left: 24px;
  margin-right: 0;
  background-color: #FFF;
}

.site-event-schedule.style-two .event-schedule-tabs > .nav > li > a {
  padding-right: 22px;
  padding-left: 22px;
}

.event-schedule-tabs > .nav > li > a:hover {
  background-color: #4e1184;
  color: #FFF;
  border-color: #4e1184;
}

.event-schedule-tabs > .nav > li.active > a {
  background-color: #4e1184;
  color: #FFF;
  border-color: #4e1184;
}

.event-schedule-tabs .panel-group > .panel {
  border: none;
  box-shadow: none;
}

.event-schedule-tabs .panel-group > .panel + .panel {
  margin-top: 20px;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading {
  border-radius: 0;
  padding: 0;
  border: none;
  background-color: transparent;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title {
  font-size: 18px;
  font-size: 1.125em;
  text-transform: uppercase;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a {
  display: block;
  padding: 14px 35px;
  background-color: #4e1184;
  color: #FFF;
  position: relative;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a:after {
  content: '\f105';
  font-family: FontAwesome;
  position: absolute;
  right: 30px;
  font-size: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  transition: all 0.15s ease-in-out;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a > .time {
  display: inline-block;
  padding: 10px 12px;
  font-size: 14px;
  font-size: 0.875em;
  font-weight: 400;
  background-color: #3b0d64;
  margin-right: 12px;
  transition: all 0.15s ease-in-out;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a > .time > i {
  margin-right: 5px;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a.collapsed {
  background-color: #f5f5f7;
  color: #050709;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a.collapsed:after {
  color: #fd367e;
  transform: translateY(-50%) rotate(0deg);
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a.collapsed > .time {
  background-color: #eae9e9;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a.collapsed > .time > i {
  color: #fd367e;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a:hover {
  text-decoration: none;
  background-color: #4e1184;
  color: #FFF;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a:hover:after {
  color: #FFF;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a:hover > .time {
  background-color: #3b0d64;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a:hover > .time > i {
  color: #FFF;
}

.event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a:focus {
  text-decoration: none;
}

.event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body {
  border: none;
  background-color: #4e1184;
  color: #FFF;
  padding: 20px 35px;
}

.event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .description {
  margin-bottom: 35px;
}

.event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker {
  display: flex;
  align-items: center;
}

.event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker:before, .event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker:after {
  content: "";
  display: table;
}

.event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker:after {
  clear: both;
}

.event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker > .speaker-img {
  width: 70px;
  height: 70px;
  border: 2px solid #FFF;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 35px;
}

.event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker > .speaker-img > img {
  width: 100%;
  height: auto;
}

.event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker > .speaker-info > i {
  margin-right: 5px;
}

.event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker > .speaker-info > .name {
  color: #FFF;
}

.event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker > .speaker-info > .job {
  opacity: 0.75;
  display: inline-block;
  margin-left: 5px;
}

.event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker > .speaker-info > .job:before {
  content: '\/';
  margin-right: 5px;
}

.event-schedule-tabs.timeline-style:before, .event-schedule-tabs.timeline-style:after {
  content: "";
  display: table;
}

.event-schedule-tabs.timeline-style:after {
  clear: both;
}

.event-schedule-tabs.timeline-style > .nav {
  display: block;
  float: left;
  width: 200px;
}

.event-schedule-tabs.timeline-style > .nav > li {
  display: block;
  float: none;
  margin-right: 0;
  margin-bottom: 16px;
}

.event-schedule-tabs.timeline-style > .nav > li:after {
  display: none;
}

.event-schedule-tabs.timeline-style > .tab-content {
  margin-left: 220px;
  padding: 25px 60px 50px 60px;
  background-color: #fafafa;
  position: relative;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item {
  position: relative;
  padding-bottom: 65px;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #dcdcdc;
  left: -30px;
  top: 10px;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item:last-child {
  padding-bottom: 25px;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item:last-child:after {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #dcdcdc;
  border-radius: 50%;
  bottom: -10px;
  left: -32px;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .time {
  color: #050709;
  margin-bottom: 15px;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .time:before {
  content: '\f017';
  font-family: FontAwesome;
  position: absolute;
  left: -36px;
  font-size: 16px;
  top: -0px;
  color: #fd367e;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .title {
  margin-top: 0;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  font-size: 1.14286em;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .content {
  margin-bottom: 30px;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .schedule-speaker {
  display: flex;
  align-items: center;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .schedule-speaker > .speaker-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #FFF;
  margin-right: 25px;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .schedule-speaker > .speaker-img > img {
  width: 100%;
  height: auto;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .schedule-speaker > .speaker-info > i {
  color: #fd367e;
  margin-right: 5px;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .schedule-speaker > .speaker-info > .name {
  color: #050709;
}

.event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .schedule-speaker > .speaker-info > .job:before {
  content: '\/';
  margin-right: 5px;
}

.event-schedule-tabs.timeline-style.sidenav > .tab-content {
  background-color: transparent;
  padding: 0;
}

@media (max-width: 1024px) {
  .event-schedule-tabs > .nav > li > a {
    font-size: 14px;
    font-size: 1em;
    padding-right: 15px;
    padding-left: 15px;
  }
  .site-event-schedule.style-two .event-schedule-tabs > .nav > li > a {
    padding-right: 15px;
    padding-left: 15px;
  }
  .event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title {
    font-size: 16px;
    font-size: 1.14286em;
  }
  .event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a {
    padding: 10px 20px;
  }
  .event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a:after {
    right: 20px;
  }
  .event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a > .time {
    font-size: 14px;
    font-size: 0.875em;
    font-weight: 400;
  }
}

@media (max-width: 767px) {
  .event-schedule-tabs > .nav > li {
    width: 50%;
    float: left;
    margin-right: 0;
    margin-bottom: 10px;
    padding: 5px;
  }
  .event-schedule-tabs > .nav > li:after {
    display: none;
  }
  .event-schedule-tabs > .nav > li > a {
    font-size: 14px;
    font-size: 1em;
    padding-right: 15px;
    padding-left: 15px;
  }
  .event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a > .time {
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
  .event-schedule-tabs .panel-group > .panel > .panel-heading > .panel-title > a > .title {
    display: block;
    text-align: center;
    line-height: 1.4em;
  }
  .event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker {
    display: block;
  }
  .event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker > .speaker-img {
    margin-right: 10px;
    float: left;
  }
  .event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker > .speaker-img > img {
    width: 100%;
    height: auto;
  }
  .event-schedule-tabs .panel-group > .panel > .panel-collapse > .panel-body > .schedule-speaker > .speaker-info {
    padding-top: 20px;
  }
  .event-schedule-tabs.timeline-style > .nav {
    width: auto;
    float: none;
    margin-bottom: 25px;
  }
  .event-schedule-tabs.timeline-style > .nav > li {
    float: left;
    margin-bottom: 0px;
    padding: 5px;
  }
  .event-schedule-tabs.timeline-style > .tab-content {
    margin: 0;
    padding: 20px 10px 50px 30px;
  }
  .event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .schedule-speaker {
    display: block;
  }
  .event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .schedule-speaker > .speaker-img {
    float: left;
  }
  .event-schedule-tabs.timeline-style > .tab-content .schedule-timeline > .item > .schedule-speaker > .speaker-info {
    padding-top: 20px;
  }
}

/*-----------------------------------------------
  ## Event Image Carousel
  ---------------------------------------------*/
.event-image-carousel .swiper-slide > img {
  width: 100%;
  height: auto;
}

.event-image-carousel .swiper-slide > .dt-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*-----------------------------------------------
  ## About Us Content Block
  ---------------------------------------------*/
.about-content-text-block > h3 {
  font-size: 22px;
  font-size: 1.57143em;
  margin-top: 15px;
  margin-bottom: 30px;
}

.about-content-text-block > .description {
  margin-bottom: 40px;
}

/*-----------------------------------------------
  ## Dynamic Photo Gallery
  ---------------------------------------------*/
.dynamic-photo-gallery {
  margin: -13px;
}

.dynamic-photo-gallery:before, .dynamic-photo-gallery:after {
  content: "";
  display: table;
}

.dynamic-photo-gallery:after {
  clear: both;
}

.dynamic-photo-gallery > .photo-item {
  width: 50%;
  float: left;
  padding: 13px;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.dynamic-photo-gallery > .photo-item:before, .dynamic-photo-gallery > .photo-item:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #FFF;
  transition: all 0.5s ease-in-out;
  z-index: 12;
}

.dynamic-photo-gallery > .photo-item:before {
  height: 0;
  width: 1px;
  margin-top: -20px;
}

.dynamic-photo-gallery > .photo-item:after {
  width: 0;
  height: 1px;
  margin-left: -20px;
}

.dynamic-photo-gallery > .photo-item > a {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000;
  position: relative;
  overflow: hidden;
}

.dynamic-photo-gallery > .photo-item > a:before, .dynamic-photo-gallery > .photo-item > a:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 0%;
  z-index: 11;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.dynamic-photo-gallery > .photo-item > a:before {
  border-top: 1px solid #FFF;
  border-left: 1px solid #FFF;
  top: 14px;
  left: 14px;
}

.dynamic-photo-gallery > .photo-item > a:after {
  border-bottom: 1px solid #FFF;
  border-right: 1px solid #FFF;
  bottom: 14px;
  right: 14px;
}

.dynamic-photo-gallery > .photo-item > a > img {
  width: 100%;
  height: auto;
  opacity: 1;
  transition: all 0.25s ease-in-out;
  backface-visibility: hidden;
}

.dynamic-photo-gallery > .photo-item:hover:before {
  height: 40px;
}

.dynamic-photo-gallery > .photo-item:hover:after {
  width: 40px;
}

.dynamic-photo-gallery > .photo-item:hover > a:before, .dynamic-photo-gallery > .photo-item:hover > a:after {
  width: 40%;
  height: 40%;
  opacity: 1;
}

.dynamic-photo-gallery > .photo-item:hover > a > img {
  opacity: 0.3;
}

.dynamic-photo-gallery > .photo-item > img {
  width: 100%;
  height: auto;
}

.dynamic-photo-gallery.masonry-gallery > .photo-item {
  width: 33.33333333333333%;
  height: 300px;
}

.dynamic-photo-gallery.masonry-gallery > .photo-item.width-2 {
  width: 66.66666666666667%;
}

@media (max-width: 1200px) {
  .dynamic-photo-gallery.masonry-gallery > .photo-item {
    height: 275px;
  }
}

@media (max-width: 1024px) {
  .dynamic-photo-gallery.masonry-gallery > .photo-item {
    height: 210px;
  }
}

@media (max-width: 768px) {
  .dynamic-photo-gallery.masonry-gallery > .photo-item {
    padding: 5px;
    height: 150px;
  }
}

@media (max-width: 500px) {
  .dynamic-photo-gallery.masonry-gallery > .photo-item {
    width: 50%;
    height: 150px;
  }
  .dynamic-photo-gallery.masonry-gallery > .photo-item.width-2 {
    width: 50%;
  }
  .dynamic-photo-gallery.masonry-gallery > .photo-item.width-2 a > img {
    width: 210%;
    height: auto;
  }
}

@media (max-width: 350px) {
  .dynamic-photo-gallery.masonry-gallery > .photo-item {
    width: 50%;
    height: 130px;
  }
}

/*-----------------------------------------------
  ## Newsletter form
  ---------------------------------------------*/
.newsletter-form {
  max-width: 575px;
  margin: 0 auto;
}

.newsletter-form:before, .newsletter-form:after {
  content: "";
  display: table;
}

.newsletter-form:after {
  clear: both;
}

.newsletter-form > input {
  float: left;
  max-width: 400px;
  margin-right: 40px;
}

.newsletter-form > button {
  float: left;
  text-transform: uppercase;
  min-width: 135px;
}

.newsletter-form > button > i {
  display: none;
}

.newsletter-form > button.clicked > span {
  display: none;
}

.newsletter-form > button.clicked > i {
  display: inline-block;
}

.newsletter-form .form-result {
  margin-top: 25px;
}

@media (max-width: 500px) {
  .newsletter-form {
    width: auto;
    text-align: center;
  }
  .newsletter-form > input {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .newsletter-form > button {
    float: none;
  }
}

/*-----------------------------------------------
  ## Speaker Profile Thumb
  ---------------------------------------------*/
.speaker-profile-thumb {
  position: relative;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  color: #FFF;
  margin-bottom: 40px;
}

.speaker-profile-thumb > .profile-link {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  color: #FFF;
  opacity: 0;
  transform: scale(3);
  transition: all 0.25s;
  transition-delay: 0.25s;
}

.speaker-profile-thumb > img {
  width: 100%;
  height: 100%;
}

.speaker-profile-thumb > .profile-info {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -75px;
  left: 0;
  background-color: rgba(5, 7, 9, 0.7);
  padding: 10px 34px 50px 34px;
  transform: translateY(100%);
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.speaker-profile-thumb > .profile-info > .name-job {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  margin-bottom: 35px;
  padding-bottom: 14px;
}

.speaker-profile-thumb > .profile-info > .name-job > .name {
  font-size: 22px;
  font-size: 1.57143em;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;
}

.speaker-profile-thumb > .profile-info > .name-job > .job {
  opacity: 0.7;
}

.speaker-profile-thumb > .profile-info > .description {
  transform: translateY(50%);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.3s;
}

.speaker-profile-thumb > .profile-info > .social-profiles {
  position: absolute;
  bottom: 50px;
  text-align: center;
  right: 34px;
  left: 34px;
  text-align: center;
  transform: translateY(50%);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.5s;
}

.speaker-profile-thumb > .profile-info > .social-profiles > li {
  display: inline-block;
  float: none;
}

.speaker-profile-thumb:hover {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);
}

.speaker-profile-thumb:hover > .profile-link {
  opacity: 1;
  transform: scale(1);
}

.speaker-profile-thumb:hover > .profile-info {
  top: 0;
  padding-top: 75px;
  transform: translateY(0%);
  border-top: 1px solid rgba(255, 255, 255, 0);
}

.speaker-profile-thumb:hover > .profile-info > .description,
.speaker-profile-thumb:hover > .profile-info > .social-profiles {
  opacity: 1;
  transform: translateY(0%);
}

@media (min-width: 768px) and (max-width: 1024px) {
  .speaker-profile-thumb > .profile-info {
    padding: 15px;
  }
  .speaker-profile-thumb > .profile-info > .name-job {
    margin-bottom: 15px;
  }
  .speaker-profile-thumb > .profile-info > .social-profiles {
    left: 15px;
    right: 15px;
  }
  .speaker-profile-thumb:hover > .profile-info {
    padding-top: 15px;
  }
}

@media (max-width: 768px) {
  .speaker-profile-thumb {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

/*-----------------------------------------------
  ## Speaker Profile Thumb (v2)
  ---------------------------------------------*/
.speaker-profile-thumb-v2 {
  min-height: 395px;
  margin-bottom: 60px;
}

.speaker-profile-thumb-v2 > .profile-image {
  position: relative;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
}

.speaker-profile-thumb-v2 > .profile-image > img {
  width: 100%;
  height: auto;
}

.speaker-profile-thumb-v2 > .profile-image > .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(5, 7, 9, 0.7);
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.speaker-profile-thumb-v2 > .profile-image > .overlay > .social-profiles-v2,
.speaker-profile-thumb-v2 > .profile-image > .overlay > a {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  transform: translate(-50%, 100px);
  opacity: 0;
  transition: all 0.25s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.2s;
}

.speaker-profile-thumb-v2 > .profile-image > .overlay > a {
  margin-top: 20px;
  color: #FFF;
  text-transform: uppercase;
  opacity: 0;
  transition-delay: 0.3s;
}

.speaker-profile-thumb-v2 > .profile-image > .overlay > a:before {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -100%;
  top: -5px;
  width: 200%;
  height: 1px;
  background-color: #FFF;
}

.speaker-profile-thumb-v2 > .profile-image > .overlay > a:hover {
  color: #fd367e;
}

.speaker-profile-thumb-v2 > .profile-info {
  text-align: center;
}

.speaker-profile-thumb-v2 > .profile-info > .name {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  font-size: 24px;
  font-size: 1.71429em;
}

.speaker-profile-thumb-v2 > .profile-info > .name > a {
  color: #050709;
}

.speaker-profile-thumb-v2 > .profile-info > .name > a:hover {
  color: #4e1184;
}

.speaker-profile-thumb-v2:hover > .profile-image {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);
}

.speaker-profile-thumb-v2:hover > .profile-image > .overlay {
  opacity: 1;
}

.speaker-profile-thumb-v2:hover > .profile-image > .overlay > .social-profiles-v2,
.speaker-profile-thumb-v2:hover > .profile-image > .overlay > a {
  opacity: 1;
  transform: translate(-50%, -50%);
}

/*-----------------------------------------------
  ## Speaker Profile Details
  ---------------------------------------------*/
.speaker-profile-details {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.speaker-profile-details:before, .speaker-profile-details:after {
  content: "";
  display: table;
}

.speaker-profile-details:after {
  clear: both;
}

.swiper-slide .speaker-profile-details {
  opacity: 0;
  transition-delay: 0.4s;
}

.swiper-slide-active .speaker-profile-details {
  opacity: 1;
}

.speaker-profile-details > .profile-image {
  width: 40%;
  float: left;
}

.speaker-profile-details > .profile-image > img {
  width: 100%;
  height: auto;
}

.speaker-profile-details > .profile-content {
  width: 60%;
  padding-left: 45px;
  float: left;
}

.speaker-profile-details > .profile-content > .name-job {
  margin-bottom: 36px;
  padding-bottom: 10px;
  position: relative;
}

.speaker-profile-details > .profile-content > .name-job:after {
  content: '';
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 240px;
  background-color: #eeeeee;
}

.speaker-profile-details > .profile-content > .name-job > .name {
  font-size: 26px;
  font-size: 1.85714em;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 10px;
}

.speaker-profile-details > .profile-content > .biography {
  margin-bottom: 30px;
}

@media (max-width: 600px) {
  .speaker-profile-details {
    display: block;
  }
  .speaker-profile-details > .profile-image,
  .speaker-profile-details > .profile-content {
    width: 100%;
    float: none;
    padding: 0;
  }
  .speaker-profile-details > .profile-image {
    max-width: 200px;
    margin: 0 auto 30px auto;
  }
}

/*-----------------------------------------------
  ## Testimonial Item
  ---------------------------------------------*/
.swiper-slide > .testimonial-item {
  padding: 0 125px;
  opacity: 0;
  transition: all 0.15s ease-in-out 0.4s;
}

.swiper-slide-active > .testimonial-item {
  opacity: 1;
}

.testimonial-item > .reviewer-image {
  width: 130px;
  height: 130px;
  border: 1px solid #000;
  overflow: hidden;
  padding: 5px;
  float: left;
}

.testimonial-item > .reviewer-image > img {
  width: 100%;
  height: auto;
}

.transparent-area .testimonial-item > .reviewer-image {
  border-color: #FFF;
}

.testimonial-item > .reviewer-content {
  padding-left: 172px;
}

.testimonial-item > .reviewer-content > .name {
  font-size: 22px;
  font-size: 1.57143em;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 10px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
}

.testimonial-item > .reviewer-content > .job {
  margin-bottom: 20px;
  line-height: 1;
}

.transparent-area .testimonial-item > .reviewer-content > .job {
  opacity: 0.7;
}

@media (max-width: 767px) {
  .testimonial-item {
    text-align: center;
  }
  .swiper-slide > .testimonial-item {
    padding: 0 70px;
  }
  .testimonial-item > .reviewer-image {
    float: none;
    margin: 0 auto 30px auto;
  }
  .testimonial-item > .reviewer-content {
    padding-left: 0;
  }
}

/*-----------------------------------------------
  ## Testimonial Item (v2)
  ---------------------------------------------*/
.swiper-slide > .testimonial-item-v2 {
  padding: 0 80px;
  opacity: 0;
  transition: all 0.15s ease-in-out 0.4s;
}

.swiper-slide-active > .testimonial-item-v2 {
  opacity: 1;
}

.testimonial-item-v2 > .reviewer-content {
  margin-bottom: 34px;
  text-align: center;
  background-color: #f7f7f7;
  padding: 26px;
  border: 1px solid #000;
  position: relative;
}

.testimonial-item-v2 > .reviewer-content > p {
  margin: 0;
  position: relative;
}

.transparent-area .testimonial-item-v2 > .reviewer-content {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #FFF;
}

.testimonial-item-v2 > .reviewer-meta {
  text-align: center;
}

.testimonial-item-v2 > .reviewer-meta > .reviewer-image {
  width: 100px;
  height: 100px;
  margin: 0 auto 18px;
  border: 1px solid #000;
  overflow: hidden;
  padding: 5px;
}

.testimonial-item-v2 > .reviewer-meta > .reviewer-image > img {
  width: 100%;
  height: auto;
}

.transparent-area .testimonial-item-v2 > .reviewer-meta > .reviewer-image {
  border-color: #FFF;
}

.testimonial-item-v2 > .reviewer-meta > .name {
  font-size: 22px;
  font-size: 1.57143em;
  margin-top: 0;
  margin-bottom: 14px;
  line-height: 1;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
}

.testimonial-item-v2 > .reviewer-meta > .job {
  margin: 0;
}

.transparent-area .testimonial-item-v2 > .reviewer-meta > .job {
  opacity: 0.7;
}

/*-----------------------------------------------
  ## Pricing Table
  ---------------------------------------------*/
.pricing-item {
  background-color: #f5f5f7;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
  margin: 10px;
  transition: all 0.25s ease-in-out;
}

.pricing-item > .plan-name {
  margin-top: 0;
  margin-bottom: 38px;
  font-size: 30px;
  font-size: 2.14286em;
  text-transform: uppercase;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
}

.pricing-item > .plan-price {
  background-color: #fd367e;
  color: #FFF;
  font-size: 50px;
  font-size: 3.57143em;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  transition: background 0.25s ease-in-out;
}

.pricing-item > .plan-price > sup {
  font-size: 26px;
  font-size: 0.52em;
}

.pricing-item > ul {
  padding: 0;
  margin-bottom: 20px;
}

.pricing-item > ul > li {
  list-style: none;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eeeeee;
}

.pricing-item > ul > li:last-child {
  border-bottom: none;
}

.pricing-item > .action > a {
  text-transform: uppercase;
}

.pricing-item:hover {
  transform: translateY(-5px);
}

.pricing-item:hover, .pricing-item.featured {
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.2);
}

.pricing-item:hover > .plan-price, .pricing-item.featured > .plan-price {
  background-color: #4e1184;
}

@media (max-width: 768px) {
  .pricing-item {
    max-width: 340px;
    margin: 30px auto;
  }
}

/*-----------------------------------------------
  ## Register form
  ---------------------------------------------*/
.register-now-form {
  max-width: 840px;
  margin: 0 auto;
  text-align: center;
}

.register-now-form:before, .register-now-form:after {
  content: "";
  display: table;
}

.register-now-form:after {
  clear: both;
}

.register-now-form > p {
  width: 50%;
  float: left;
  padding: 0 10px;
}

.register-now-form > p:nth-last-child(2) {
  width: auto;
  float: none;
  padding: 0;
}

.register-now-form > p:nth-last-child(2):before, .register-now-form > p:nth-last-child(2):after {
  content: "";
  display: table;
}

.register-now-form > p:nth-last-child(2):after {
  clear: both;
}

.register-now-form > input,
.register-now-form > select {
  width: 46%;
  float: left;
  margin: 10px 2%;
}

.register-now-form > button {
  margin-top: 40px;
  text-transform: uppercase;
}

.register-now-form > button > i {
  display: none;
}

.register-now-form > button.clicked {
  min-width: 160px;
}

.register-now-form > button.clicked > i {
  display: inline-block;
}

.register-now-form > button.clicked > span {
  display: none;
}

.register-now-form .form-result {
  margin-top: 25px;
}

@media (max-width: 768px) {
  .register-now-form > p,
  .register-now-form > input,
  .register-now-form > select {
    width: 100%;
    margin: 10px 0;
  }
}

/*-----------------------------------------------
  ## Event Sponsor Gallery
  ---------------------------------------------*/
.event-sponsor-gallery {
  text-align: center;
}

.event-sponsor-gallery > .item {
  display: inline-block;
  padding: 3px;
}

.event-sponsor-gallery > .item > a,
.event-sponsor-gallery > .item > span {
  display: block;
}

.event-sponsor-gallery > .item > a > img,
.event-sponsor-gallery > .item > span > img {
  max-width: 100%;
  height: auto;
}

.event-sponsor-gallery > .item > img {
  display: inline-block;
}

/*-----------------------------------------------
  ## Recent News List
  ---------------------------------------------*/
.recent-news-list article.post {
  max-width: 370px;
  margin: 0 auto 30px auto;
}

.recent-news-list article.post > .post-header > .post-thumb > a {
  display: block;
}

.recent-news-list article.post > .post-header > .post-thumb > a > img {
  width: 100%;
  height: auto;
}

.recent-news-list article.post > .post-header > .post-meta {
  background-color: #f5f5f7;
  padding: 10px 4px;
  margin: 0 0 30px 0;
}

.recent-news-list article.post > .post-header > .post-title {
  margin-top: 0;
  margin-bottom: 36px;
  font-size: 22px;
  font-size: 1.57143em;
  line-height: 1;
}

.recent-news-list article.post > .post-header > .post-title > a {
  color: #050709;
}

.recent-news-list article.post > .post-header > .post-title > a:hover {
  color: #4e1184;
}

.recent-news-list article.post > .post-content {
  margin-bottom: 38px;
}

.recent-news-list article.post > a {
  text-transform: uppercase;
}

/*-----------------------------------------------
  ## Contact Form
  ---------------------------------------------*/
.contact-form {
  margin-bottom: 25px;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 22px;
}

.contact-form button[type="submit"] > i {
  display: none;
}

.contact-form button[type="submit"].clicked > span {
  display: none;
}

.contact-form button[type="submit"].clicked > i {
  display: inline-block;
}

.contact-form.for-page .form-result {
  margin-top: 25px;
}

/*-----------------------------------------------
  ## Contact Method Box
  ---------------------------------------------*/
.contact-method-box {
  background-color: #4e1184;
  color: #FFF;
  padding: 7px;
}

.contact-method-box + .contact-method-box {
  margin-top: 20px;
}

.contact-method-box > .inside {
  border: 1px solid #9c47e7;
  position: relative;
  overflow: hidden;
}

.contact-method-box > .inside:before {
  content: '';
  position: absolute;
  top: 0;
  left: 85px;
  height: 100%;
  width: 1px;
  background-color: #9c47e7;
}

.contact-method-box > .inside > .icon {
  position: absolute;
  width: 85px;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
}

.contact-method-box > .inside > .icon > img {
  max-width: 45px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-method-box > .inside > .icon > i {
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-method-box > .inside > .content {
  padding: 10px;
  margin-left: 85px;
}

.contact-method-box > .inside > .content > .title {
  color: #FFF;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 20px;
  font-size: 1.42857em;
  text-transform: uppercase;
  font-weight: 400;
}

.contact-method-box > .inside > .content p {
  margin: 0;
}

/*-----------------------------------------------
  ## Gallery Item
  ---------------------------------------------*/
.gallery-item {
  padding-bottom: 30px;
}

.gallery-item > .content {
  display: block;
  position: relative;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
}

.gallery-item > .content > img {
  width: 100%;
  height: auto;
}

.gallery-item > .content > .info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(5, 7, 9, 0.7);
  padding: 60px 40px 50px 40px;
  color: #FFF;
  opacity: 0;
  transition: 0.25s ease-in-out;
  backface-visibility: hidden;
}

.gallery-item > .content > .info > .title {
  color: #FFF;
  margin-top: 0;
  margin-bottom: 12px;
  line-height: 1;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  font-size: 24px;
  font-size: 1.71429em;
  text-transform: uppercase;
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.gallery-item > .content > .info > .meta {
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.15s;
}

.gallery-item > .content > .info > .plus-sign {
  position: absolute;
  bottom: 50px;
  left: 40px;
  width: 40px;
  height: 40px;
}

.gallery-item > .content > .info > .plus-sign:before, .gallery-item > .content > .info > .plus-sign:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: #FFF;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.25s;
}

.gallery-item > .content > .info > .plus-sign:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.gallery-item > .content:hover {
  box-shadow: 0px 4px 10.8px 1.2px rgba(0, 0, 0, 0.25);
}

.gallery-item > .content:hover > .info {
  opacity: 1;
}

.gallery-item > .content:hover > .info > .title,
.gallery-item > .content:hover > .info > .meta {
  transform: translateY(0);
  opacity: 1;
}

.gallery-item > .content:hover > .info > .meta {
  opacity: 0.7;
}

.gallery-item > .content:hover > .info > .plus-sign:before, .gallery-item > .content:hover > .info > .plus-sign:after {
  opacity: 1;
  width: 100%;
}

@media (max-width: 600px) {
  .gallery-item {
    max-width: 300px;
    margin: 0 auto;
  }
}

/*-----------------------------------------------
  ## Navigation
  ---------------------------------------------*/
.navigation.pagination {
  display: block;
  margin-top: 30px;
  margin-bottom: 50px;
}

.navigation.pagination > .nav-links {
  text-align: center;
}

.navigation.pagination > .nav-links > a,
.navigation.pagination > .nav-links > span {
  display: inline-block;
  border: 1px solid #eeeeee;
  min-width: 30px;
  min-height: 30px;
  text-align: center;
  color: #050709;
  line-height: 2;
  padding: 0 7px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.navigation.pagination > .nav-links > a.current, .navigation.pagination > .nav-links > a:hover,
.navigation.pagination > .nav-links > span.current,
.navigation.pagination > .nav-links > span:hover {
  color: #FFF;
  background-color: #4e1184;
  border-color: #4e1184;
}

.navigation.pagination > .nav-links > a.dots,
.navigation.pagination > .nav-links > span.dots {
  background-color: transparent;
  border-color: #eeeeee;
  color: #050709;
}

.navigation.pagination > .nav-links > a.prev, .navigation.pagination > .nav-links > a.next,
.navigation.pagination > .nav-links > span.prev,
.navigation.pagination > .nav-links > span.next {
  width: 30px;
}

.navigation.pagination > .nav-links > a.prev:before,
.navigation.pagination > .nav-links > span.prev:before {
  content: '\f104';
  font-family: FontAwesome;
}

.navigation.pagination > .nav-links > a.next:before,
.navigation.pagination > .nav-links > span.next:before {
  content: '\f105';
  font-family: FontAwesome;
}

.navigation.post-navigation > .nav-links {
  padding: 0 30px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.navigation.post-navigation > .nav-links:before, .navigation.post-navigation > .nav-links:after {
  content: "";
  display: table;
}

.navigation.post-navigation > .nav-links:after {
  clear: both;
}

.navigation.post-navigation > .nav-links > div {
  width: 50%;
  float: left;
}

.navigation.post-navigation > .nav-links > div > a {
  display: block;
  color: #050709;
  font-weight: 700;
}

.navigation.post-navigation > .nav-links > div > a > .nav-subtitle {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 5px;
  color: #757575;
}

.navigation.post-navigation > .nav-links > div > a > .nav-title {
  font-size: 1.2em;
  position: relative;
}

.navigation.post-navigation > .nav-links > div > a > .nav-title > i {
  position: absolute;
  left: -25px;
  top: 4px;
  transition: all 0.15s ease-in-out;
}

.navigation.post-navigation > .nav-links > div > a > span {
  display: block;
}

.navigation.post-navigation > .nav-links > div > a:hover {
  color: #fd367e;
}

.navigation.post-navigation > .nav-links > div > a:hover > .nav-title > i {
  left: -30px;
}

.navigation.post-navigation > .nav-links > div.nav-next {
  float: right;
  text-align: right;
}

.navigation.post-navigation > .nav-links > div.nav-next > a > .nav-title > i {
  left: auto;
  right: -25px;
}

.navigation.post-navigation > .nav-links > div.nav-next > a:hover > .nav-title > i {
  left: auto;
  right: -30px;
}

@media (max-width: 600px) {
  .navigation.post-navigation > .nav-links > div,
  .navigation.post-navigation > .nav-links > div.nav-next {
    width: auto;
    float: none;
  }
}

.page-links {
  margin-top: 10px;
  margin-bottom: 10px;
}

.page-links > a,
.page-links > .page-number {
  display: inline-block;
  border: 1px solid #eeeeee;
  min-width: 30px;
  min-height: 30px;
  text-align: center;
  color: #050709;
  line-height: 2;
  padding: 0 7px;
  margin-bottom: 5px;
}

.page-links > .page-number {
  color: #FFF;
  background-color: #4e1184;
  border-color: #4e1184;
}

.page-links > .title {
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid #eeeeee;
  line-height: 2;
  padding: 0 7px;
  margin-bottom: 5px;
}

/*-----------------------------------------------
  ## Error 404 Content
  ---------------------------------------------*/
.error-404-content {
  font-family: "Josefin Sans", sans-serif;
}

.error-404-content > .error-code {
  font-size: 340px;
  font-size: 24.28571em;
  line-height: 1;
  color: #4e1184;
}

.error-404-content > .error-message {
  font-size: 32px;
  font-size: 2.28571em;
  text-transform: uppercase;
  color: #050709;
}

@media (max-width: 1024px) {
  .error-404-content > .error-code {
    font-size: 250px;
    font-size: 17.85714em;
  }
}

@media (max-width: 768px) {
  .error-404-content > .error-code {
    font-size: 150px;
    font-size: 10.71429em;
  }
}

/*-----------------------------------------------
  ## Search Form
  ---------------------------------------------*/
.search-form {
  position: relative;
}

.search-form > label {
  display: inline;
}

.search-form > button {
  position: absolute;
  top: 14px;
  right: 16px;
  border: none;
  background: none;
  padding: 0;
  color: inherit;
}

.search-form > button > i {
  font-size: 18px;
}

/*-----------------------------------------------
  ## Article
  ---------------------------------------------*/
.hentry:before, .hentry:after {
  content: "";
  display: table;
}

.hentry:after {
  clear: both;
}

.site-blog-single-content .hentry {
  margin-bottom: 50px;
}

.hentry.sticky {
  background-color: #f7f7f7;
  padding: 25px;
}

.hentry + .hentry {
  margin-top: 70px;
}

.hentry > .entry-header {
  margin-bottom: 40px;
}

.hentry > .entry-header > .post-thumbnail {
  margin-bottom: 30px;
  text-align: center;
}

.hentry > .entry-header > .post-thumbnail > img {
  max-width: 100%;
  height: auto;
}

.hentry > .entry-header > .entry-meta {
  margin-bottom: 20px;
}

.hentry > .entry-header > .entry-title {
  margin-top: 0;
  font-size: 32px;
  font-size: 2.28571em;
  line-height: 1.2;
}

.hentry .entry-meta {
  padding: 0;
  margin-bottom: 30px;
  position: relative;
}

.hentry .entry-meta > li {
  display: inline-block;
  margin-right: 10px;
}

.hentry .entry-meta > li .updated:not(.published) {
  display: none;
}

.hentry .entry-meta > li:last-child {
  margin-right: 0;
}

.hentry .entry-meta > li > i {
  color: #fd367e;
  margin-right: 5px;
}

.hentry .entry-meta > li > a {
  color: #757575;
}

.hentry .entry-meta > li.sharer {
  cursor: pointer;
}

.hentry .entry-meta > li.sharer > .sharer-items {
  cursor: auto;
  opacity: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  background-color: #fd367e;
  text-align: center;
  visibility: hidden;
  transform: translate(-50%, -50%) scaleX(0);
  transition: all 0.15s ease-in-out;
}

.hentry .entry-meta > li.sharer > .sharer-items > a {
  display: inline-block;
  color: #fff;
  padding: 5px 10px;
}

.hentry .entry-meta > li.sharer > .sharer-items > a:hover {
  background-color: #FFF;
  color: #fd367e;
}

.hentry .entry-meta > li.sharer:hover > .sharer-items {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scaleX(1);
}

.hentry > .post-thumbnail {
  float: left;
  width: 320px;
  height: 280px;
  overflow: hidden;
}

.hentry > .post-thumbnail > a {
  display: block;
}

.hentry > .post-thumbnail > a > img {
  width: 100%;
  height: auto;
}

.hentry > .post-thumbnail > a > img {
  width: 100%;
  height: auto;
}

.hentry > .post-content > .entry-title {
  font-size: 32px;
  font-size: 2.28571em;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 12px;
}

.hentry > .post-content > .entry-title > a {
  color: #050709;
}

.hentry > .post-content > .entry-title > a:hover {
  color: #4e1184;
}

.hentry > .post-content > .entry-excerpt {
  margin-bottom: 30px;
}

.hentry > .post-content > .readmore {
  text-transform: uppercase;
}

.hentry > .entry-content figure,
.hentry > .entry-content img {
  max-width: 100%;
  height: auto;
  margin-bottom: 25px;
}

.hentry > .entry-content figure > img {
  margin-bottom: 0;
}

.hentry > .entry-footer .cat-links,
.hentry > .entry-footer .tags-links {
  margin-bottom: 10px;
}

.hentry > .entry-footer .cat-links > i,
.hentry > .entry-footer .tags-links > i {
  padding-right: 10px;
}

.hentry > .entry-footer .cat-links > a,
.hentry > .entry-footer .tags-links > a {
  color: #050709;
}

.hentry > .entry-footer .cat-links > a:hover,
.hentry > .entry-footer .tags-links > a:hover {
  opacity: 0.5;
}

.hentry > .entry-footer .cat-links {
  border-bottom: 1px solid #f5f5f7;
  padding-bottom: 10px;
}

.hentry.devent_post_thumbnail_enabled.has-post-thumbnail > .post-content {
  padding-left: 350px;
}

@media (max-width: 1024px) {
  .hentry + .hentry {
    margin-top: 40px;
  }
  .hentry > .post-thumbnail {
    width: 220px;
    height: 180px;
  }
  .hentry > .post-content > .entry-meta {
    margin-bottom: 15px;
  }
  .hentry > .post-content > .entry-excerpt {
    margin-bottom: 20px;
    height: 75px;
    overflow: hidden;
  }
  .hentry > .post-content > .readmore {
    padding: 10px 20px;
  }
  .hentry.devent_post_thumbnail_enabled.has-post-thumbnail > .post-content {
    padding-left: 240px;
  }
}

@media (max-width: 600px) {
  .hentry + .hentry {
    margin-top: 30px;
  }
  .hentry > .post-thumbnail {
    width: 100px;
    height: 100px;
  }
  .hentry > .post-content > .entry-title {
    font-size: 16px;
    font-size: 1.14286em;
    margin-bottom: 5px;
  }
  .hentry > .post-content > .entry-meta {
    font-size: 12px;
    font-size: 0.85714em;
    margin-bottom: 10px;
  }
  .hentry > .post-content > .entry-excerpt {
    height: 75px;
    overflow: hidden;
    margin-bottom: 15px;
  }
  .hentry > .post-content > .readmore {
    padding: 7px 15px;
  }
  .hentry.devent_post_thumbnail_enabled.has-post-thumbnail > .post-content {
    padding-left: 115px;
  }
}

/*-----------------------------------------------
  ## Post Author Bio
  ---------------------------------------------*/
.post-author-bio {
  background-color: #f5f5f7;
  padding: 14px 24px;
  overflow: hidden;
}

.post-author-bio:before, .post-author-bio:after {
  content: "";
  display: table;
}

.post-author-bio:after {
  clear: both;
}

.post-author-bio > .author-image {
  width: 110px;
  height: 110px;
  overflow: hidden;
  border: 4px solid #4e1184;
  float: left;
}

.post-author-bio > .author-image > img {
  width: 100%;
  height: auto;
}

.post-author-bio > .author-content {
  padding-left: 135px;
}

.post-author-bio > .author-content > .head {
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 4px;
}

.post-author-bio > .author-content > .head > .name a,
.post-author-bio > .author-content > .head > .name span {
  font-family: "Josefin Sans", sans-serif;
  line-height: 1;
  font-size: 20px;
  font-size: 1.42857em;
  color: #050709;
}

@media (max-width: 500px) {
  .post-author-bio > .author-image {
    float: none;
    margin: 0 auto 25px auto;
  }
  .post-author-bio > .author-content {
    padding-left: 0;
  }
  .post-author-bio > .author-content > .head > div,
  .post-author-bio > .author-content > .head > ul {
    float: none !important;
    text-align: center;
  }
  .post-author-bio > .author-content > .head > .name {
    margin-bottom: 20px;
  }
}

/*-----------------------------------------------
  ## Comments
  ---------------------------------------------*/
#comments .comments-title {
  font-size: 30px;
  font-size: 2.14286em;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 30px;
}

#comments > .comment-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

#comments > .comment-list li.comment > .comment-body {
  border: 1px solid #eeeeee;
  position: relative;
  padding: 20px 30px 20px 75px;
  margin-left: 50px;
  margin-bottom: 40px;
  min-height: 140px;
}

#comments > .comment-list li.comment > .comment-body img,
#comments > .comment-list li.comment > .comment-body iframe {
  max-width: 100%;
}

#comments > .comment-list li.comment > .comment-body img {
  height: auto;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta {
  position: relative;
  margin-bottom: 15px;
  line-height: 1;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta:before, #comments > .comment-list li.comment > .comment-body > .comment-meta:after {
  content: "";
  display: table;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta:after {
  clear: both;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-author > img {
  width: 100px;
  height: 100px;
  border: 3px solid #f5f5f7;
  position: absolute;
  top: 0px;
  left: -125px;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-author > .fn {
  font-size: 20px;
  font-size: 1.42857em;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  color: #050709;
  line-height: 1;
  float: left;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-author > .fn > a {
  color: #050709;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-author > .fn > a:hover {
  opacity: 0.7;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-metadata {
  float: right;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-metadata > a,
#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-metadata > .reply,
#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-metadata > .edit-link {
  display: inline-block;
  line-height: 1;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-metadata > a {
  color: #050709;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-metadata > a:hover {
  opacity: 0.7;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-metadata > .edit-link {
  border-left: 1px solid #c2c2c2;
  padding-left: 5px;
  margin-left: 5px;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-metadata > .edit-link > a {
  color: #050709;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-metadata > .edit-link > a:hover {
  opacity: 0.7;
}

#comments > .comment-list li.comment > .comment-body > .comment-meta > .comment-awaiting-moderation {
  margin-bottom: 10px;
  padding-top: 25px;
  font-style: italic;
}

#comments > .comment-list li.comment > .comment-body > .reply {
  text-align: right;
}

#comments > .comment-list li.comment > .comment-body > .reply a {
  color: #fff;
  display: inline-block;
  padding: 5px 15px;
  border: 1px solid #fd367e;
  background-color: #fd367e;
}

#comments > .comment-list li.comment > .comment-body > .reply a:hover {
  color: #fd367e;
  background-color: transparent;
}

#comments > .comment-list li.bypostauthor > .comment-body {
  border-color: #fd367e;
}

#comments > .comment-list li.bypostauthor > .comment-body > .comment-meta > .comment-author > img {
  border-color: #fd367e;
}

#comments > .comment-list li.pingback {
  border: 1px solid #DDD;
  padding: 10px;
  margin-bottom: 15px;
}

#comments > .comment-list li.pingback a.url {
  color: #050709;
}

#comments > .comment-list li.pingback a.url:hover {
  opacity: 0.6;
}

#comments > .comment-list .children {
  list-style: none;
}

#respond {
  margin-bottom: 50px;
}

#respond .comment-reply-title {
  margin-top: 0;
  margin-bottom: 30px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  font-size: 30px;
  font-size: 2.14286em;
}

#respond label {
  display: none;
}

#respond a {
  color: #050709;
}

#respond a:hover {
  opacity: 0.5;
}

#respond #cancel-comment-reply-link {
  color: #fd367e;
}

@media (max-width: 600px) {
  #comments .comment-list li.comment > .comment-body {
    padding: 15px 15px 10px 30px;
    margin-left: 35px;
  }
  #comments .comment-list li.comment > .comment-body > .comment-meta > .comment-author > img {
    width: 60px;
    height: 60px;
    top: -5px;
    left: -75px;
  }
  #comments .comment-list li.comment > .comment-body > .comment-meta > .comment-author .fn {
    float: none;
    display: block;
    margin-bottom: 10px;
  }
  #comments .comment-list li.comment > .comment-body > .comment-meta > .comment-metadata {
    float: none;
    opacity: 0.8;
  }
  #comments .comment-list .children {
    padding-left: 15px;
  }
}

/*-----------------------------------------------
  ## Color Picker
  ---------------------------------------------*/
.Switcher {
  font-family: "Roboto", sans-serif;
  position: fixed;
  top: 30%;
  right: 0;
  width: 208px;
  margin-right: -208px;
  padding: 20px;
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 15px transparent;
  z-index: 1000;
  transition: none 0.5s ease-out;
  transition-property: margin-right, box-shadow;
}

.Switcher--show {
  margin-right: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.Switcher::before {
  content: "";
  position: absolute;
  z-index: 9998;
  height: 100%;
  width: 15px;
  left: 0;
  top: 0;
  margin-left: 0;
  background-color: #FFF;
}

.Switcher__control {
  cursor: pointer;
  position: absolute;
  height: 50px;
  width: 50px;
  left: 0;
  margin-left: -50px;
  background-color: #FFF;
  color: #757575;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
  padding: 0;
  border-right-color: transparent;
  outline: none;
  touch-action: manipulation;
}

.Switcher__control > i {
  font-size: 18px;
}

.Switcher__control:hover, .Switcher__control:focus {
  background-color: #FFF;
  color: #757575;
}

.Switcher > h5 {
  text-transform: uppercase;
  color: #656565;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 15px;
}

.Switcher > ul {
  padding: 0;
  margin: 0;
}

.Switcher > ul:before, .Switcher > ul:after {
  content: "";
  display: table;
}

.Switcher > ul:after {
  clear: both;
}

.Switcher > ul > li {
  list-style: none;
  float: left;
  width: 30px;
  height: 30px;
  margin: 1px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.01);
}

/*-----------------------------------------------
  ## Back To Top
  ---------------------------------------------*/
#back-to-top {
  position: fixed;
  right: 35px;
  bottom: 0px;
  padding: 15px;
  opacity: 0;
  visibility: hidden;
}

#back-to-top:hover {
  cursor: pointer;
}

#back-to-top.visible {
  bottom: 35px;
  visibility: visible;
  opacity: 1;
}

/*-----------------------------------------------
  # Sections
  ---------------------------------------------*/
.dt-flexible-row {
  display: flex;
}

.dt-flexible-row.middle {
  align-items: center;
}

.dt-flexible-row.center {
  justify-content: space-between;
}

.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section-padding-two {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section-padding-three {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section-padding-top {
  padding-top: 120px;
}

.section-padding-top-two {
  padding-top: 100px;
}

.section-padding-bottom {
  padding-bottom: 120px;
}

.section-padding-bottom-two {
  padding-bottom: 100px;
}

.section-margin-top {
  margin-top: 50px;
}

.section-margin-top-two {
  margin-top: 100px;
}

.section-bgc-alt {
  background-color: #f5f5f7;
}

section[data-bg-image],
.image-size-cover[data-bg-image] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/* Section Title */
.section-heading {
  margin-bottom: 50px;
}

.section-heading > .title {
  margin-top: 0;
  margin-bottom: 30px;
  font-family: "Josefin Sans", sans-serif;
  font-size: 40px;
  font-size: 2.85714em;
  font-weight: 600;
}

.section-heading > .title > .effect {
  display: inline-block;
  height: 14px;
  width: 60px;
  position: relative;
}

.section-heading > .title > .effect:before, .section-heading > .title > .effect:after {
  content: '';
  width: 100%;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.section-heading > .title > .effect:before {
  width: 35px;
  background-color: #fd367e;
}

.section-heading > .title > .effect:after {
  background-color: #4e1184;
  top: auto;
  bottom: 0;
}

.section-heading:not(.stripe) > .title > .effect {
  display: none;
}

/*-----------------------------------------------
  ## Site Header
  ---------------------------------------------*/
.site-header {
  background-color: #FFF;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease-in-out;
  backface-visibility: hidden;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.transparent-header .site-header {
  background-color: transparent;
  box-shadow: none;
}

.fix-header .site-header {
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}

/* Header Middle Area */
.header-top-area {
  background-color: #4e1184;
  padding-top: 32px;
  padding-bottom: 32px;
  color: #FFF;
}

.header-top-area .no-logo.no-register.no-learnmore {
  display: block;
}

.header-top-area .site-logo > a {
  display: block;
}

.header-top-area .site-logo > a > img {
  max-width: 100%;
  height: auto;
}

.header-top-area .event-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-top-area .event-buttons > a + a {
  margin-left: 20px;
}

/* Topbar */
.header-top-bar {
  border-top: 4px solid #fd367e;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.transparent-header:not(.fix-header) .header-top-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  color: #FFF;
}

.header-top-bar ul {
  margin: 0;
  padding: 0;
}

.header-top-bar ul > li {
  float: left;
  list-style: none;
}

.header-top-bar ul > li > a {
  color: #050709;
}

.header-top-bar ul > li > a:hover {
  color: #fd367e;
}

.transparent-header:not(.fix-header) .header-top-bar ul > li > a {
  color: #FFF;
}

.transparent-header:not(.fix-header) .header-top-bar ul > li > a:hover {
  color: #4e1184;
}

.header-top-bar ul > li + li {
  margin-left: 30px;
}

.header-top-bar ul > li > i {
  margin-right: 10px;
}

.header-top-bar ul.header-social-profile > li + li {
  margin-left: 15px;
}

.header-top-bar ul.header-social-profile:not(.icons-only) > li > a > i {
  margin-right: 10px;
}

/* Fix header */
.fix-header .site-header {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #FFF;
}

/* Header Mask */
.transparent-header #header-fake-mask {
  display: none;
}

@media (max-width: 1024px) {
  .header-top-area {
    padding: 16px 0;
  }
  .event-buttons > a {
    font-size: 12px;
    font-size: 0.85714em;
  }
}

@media (max-width: 768px) {
  .header-top-area .dt-flexible-row {
    flex-direction: column;
  }
  .header-top-area .dt-flexible-row > div:not(:last-child) {
    margin-bottom: 30px;
    flex-direction: row;
  }
}

@media (max-width: 767px) {
  .header-top-area .site-logo-area {
    display: none;
  }
}

/*-----------------------------------------------
  ## Page Header
  ---------------------------------------------*/
.site-page-header {
  text-align: center;
}

.site-page-header .page-title {
  margin-top: 0;
  margin-bottom: 15px;
  font-family: "Josefin Sans", sans-serif;
  font-size: 50px;
  font-size: 3.57143em;
  line-height: 1;
  font-weight: 600;
  position: relative;
}

.site-page-header .page-title:after {
  content: '';
  position: absolute;
  width: 360px;
  height: 1px;
  background-color: #FFF;
  bottom: -5px;
  left: 50%;
  margin-left: -180px;
}

.site-page-header .breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
}

.site-page-header .breadcrumbs > li {
  display: inline-block;
  word-break: break-word;
}

.site-page-header .breadcrumbs > li + li:before {
  content: '\/';
  margin: 0 5px;
  color: #d0d0d0;
}

@media (max-width: 768px) {
  .site-page-header.section-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .site-page-header .page-title {
    font-size: 30px;
    font-size: 2.14286em;
  }
}

/*-----------------------------------------------
  ## Site Banner
  ---------------------------------------------*/
.site-banner {
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.site-banner .swiper-slide {
  height: auto;
}

.site-banner > .banner-overlay {
  display: flex;
  align-items: center;
}

.site-banner > .banner-statics {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 100;
}

.site-banner > .banner-statics .event-statics {
  background-color: rgba(78, 17, 132, 0.3);
  color: #FFF;
}

.site-banner .banner-content {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 200px;
}

.site-banner .banner-content .title {
  font-size: 50px;
  font-size: 3.57143em;
  margin-top: 0;
  margin-bottom: 30px;
}

.site-banner .banner-content .title-two {
  font-size: 26px;
  font-size: 1.85714em;
  letter-spacing: 0.6em;
  margin-bottom: 20px;
}

.site-banner .banner-content .description {
  max-width: 700px;
  margin-bottom: 50px;
}

.site-banner .banner-content.text-center .description {
  margin-left: auto;
  margin-right: auto;
}

.site-banner .banner-content .event-countdown {
  margin-top: 50px;
}

.site-banner .banner-content .hero-actions {
  margin-top: 20px;
}

.site-banner .banner-content .hero-actions > a + a {
  margin-left: 15px;
}

.site-banner .swiper-container {
  height: 100%;
}

.site-banner .swiper-container .swiper-wrapper .swiper-slide .middle-content {
  display: flex;
  align-items: center;
  height: 100%;
}

@media (max-width: 1024px) {
  .site-banner .banner-content .title {
    font-size: 26px;
    font-size: 1.85714em;
  }
  .site-banner .banner-content .title-two {
    font-size: 18px;
    font-size: 1.28571em;
  }
  .site-banner .banner-content .description {
    margin-bottom: 30px;
  }
}

/*-----------------------------------------------
  ## Event Schedule
  ---------------------------------------------*/
.site-event-schedule.style-two > .container {
  padding-top: 50px;
  background-repeat: no-repeat;
  background-position: top center;
  padding-right: 50px;
  padding-left: 0;
}

.site-event-schedule.style-two .event-schedule-info {
  margin-top: 250px;
}

.site-event-schedule.style-three > .container {
  padding-top: 150px;
  background-repeat: no-repeat;
  background-position: top center;
}

@media (max-width: 1024px) {
  .site-event-schedule.style-two > .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 768px) {
  .site-event-schedule.style-two > .container {
    background-image: none !important;
    padding-top: 0;
  }
  .site-event-schedule.style-two .event-schedule-info {
    margin-top: 0;
  }
  .site-event-schedule .event-schedule-info {
    margin-bottom: 75px;
  }
}

@media (max-width: 600px) {
  .site-event-schedule.style-three > .container {
    background-image: none !important;
    padding-top: 0px;
  }
}

/*-----------------------------------------------
  ## About The Event
  ---------------------------------------------*/
.about-the-event > .nav {
  border: none;
  margin-bottom: 60px;
}

.about-the-event > .nav > li {
  width: 50%;
  margin-bottom: 0;
}

.about-the-event > .nav > li > a {
  border: 1px solid #f5f5f7;
  padding: 15px 20px;
  border-radius: 0;
  margin: 0;
  font-size: 22px;
  font-size: 1.57143em;
  line-height: 1;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  text-align: center;
  color: #050709;
}

.about-the-event > .nav > li > a:hover {
  background-color: #6917b1;
  color: #FFF;
  border-color: #6917b1;
}

.about-the-event > .nav > li.active > a {
  background-color: #4e1184;
  color: #FFF;
  border-color: #4e1184;
}

.about-the-event .event-image-carousel .carousel-button-prev {
  left: 10px;
}

.about-the-event .event-image-carousel .carousel-button-next {
  right: 10px;
}

@media (max-width: 768px) {
  .about-the-event .event-image-carousel {
    margin-bottom: 30px;
  }
  .about-the-event .about-content-text-block {
    margin-bottom: 50px;
  }
}

/*-----------------------------------------------
  ## Newsletter
  ---------------------------------------------*/
/*-----------------------------------------------
  ## Event Speakers
  ---------------------------------------------*/
/*-----------------------------------------------
  ## Testimonials
  ---------------------------------------------*/
.testimonials-carousel-v2 {
  max-width: 930px;
  margin: 0 auto;
}

.testimonials-carousel-v2 .carousel-button-next,
.testimonials-carousel-v2 .carousel-button-prev {
  top: 20%;
}

/*-----------------------------------------------
  ## Event Pricing
  ---------------------------------------------*/
/*-----------------------------------------------
  ## Register Now
  ---------------------------------------------*/
/*-----------------------------------------------
  ## Photos From Our Event
  ---------------------------------------------*/
/*-----------------------------------------------
  ## Event Sponsor
  ---------------------------------------------*/
/*-----------------------------------------------
  ## Recent News
  ---------------------------------------------*/
/*-----------------------------------------------
  ## Contact Us
  ---------------------------------------------*/
/*-----------------------------------------------
  ## Site Footer
  ---------------------------------------------*/
.footer-widget-area {
  padding-top: 50px;
  background-color: #272727;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-widget-area .widget img {
  max-width: 100%;
  height: auto;
}

.footer-widget-area .widget input,
.footer-widget-area .widget select {
  background-color: transparent;
  border-color: #565656;
}

.footer-widget-area .widget select option {
  color: #050709;
}

.footer-widget-area .widget .wp-caption {
  color: #050709;
}

.copyright-info-area {
  background-color: #16161a;
  text-align: center;
  padding: 20px 0;
}

.copyright-info-area .copyright-notice {
  margin-bottom: 0;
}

.copyright-info-area .copyright-notice > a {
  color: #050709;
}

.copyright-info-area .copyright-notice > a:hover {
  color: #fd367e;
}

.copyright-info-area.transparent-area .copyright-notice {
  opacity: 0.6;
}

.copyright-info-area.transparent-area .copyright-notice > a {
  color: #FFF;
}

.copyright-info-area.transparent-area .copyright-notice > a:hover {
  color: #fd367e;
}

@media (max-width: 768px) {
  .footer-widget-area > .container > .row > [class*="col"]:nth-child(odd) {
    clear: both;
  }
  .footer-widget-area > .container > .row > [class*="col"]:nth-child(even) {
    clear: none;
  }
}

/*-----------------------------------------------
  ## About Us Content Block
  ---------------------------------------------*/
.about-us-block {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.about-us-block .about-us-content-block > .title {
  font-family: "Josefin Sans", sans-serif;
  font-size: 24px;
  font-size: 1.71429em;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 30px;
}

.about-us-block .about-us-content-image > img {
  max-width: 100%;
  height: auto;
}

.about-us-block.right {
  flex-direction: row-reverse;
}

.about-us-block.right .about-us-content-block {
  margin-right: 40px;
}

.about-us-block.left .about-us-content-block {
  margin-left: 40px;
}

@media (max-width: 768px) {
  .about-us-block {
    display: block;
    border-bottom: 1px solid #e8e8e8;
  }
  .about-us-block .about-us-content-image {
    text-align: center;
    margin-bottom: 40px;
  }
  .about-us-block.left .about-us-content-block, .about-us-block.right .about-us-content-block {
    margin-left: 0;
    margin-right: 0;
  }
}

/*-----------------------------------------------
  ## Speakers Profile Carousel
  ---------------------------------------------*/
.speakers-profile-carousel > .event-speakers-list {
  padding-top: 60px;
}

.speakers-profile-carousel > .event-speakers-list .carousel-button-prev,
.speakers-profile-carousel > .event-speakers-list .carousel-button-next {
  top: 0;
  right: 0;
  transform: none;
  margin: 0;
}

.speakers-profile-carousel > .event-speakers-list .carousel-button-prev {
  left: auto;
  right: 42px;
}

/*-----------------------------------------------
  ## Gallery Archive
  ---------------------------------------------*/
.gallery-archive {
  column-count: 3;
  column-gap: 2em;
  column-span: all;
}

.gallery-archive > .gallery-item {
  break-inside: avoid;
}

@media (max-width: 600px) {
  .gallery-archive {
    column-count: 2;
    column-gap: 1.5em;
    column-span: all;
  }
  .gallery-archive > .gallery-item {
    break-inside: avoid;
  }
  .gallery-archive > .gallery-item > .content > .info {
    padding: 10px;
  }
  .gallery-archive > .gallery-item > .content > .info > .title {
    font-size: 16px;
    font-size: 1.14286em;
  }
  .gallery-archive > .gallery-item > .content > .info > .plus-sign {
    left: 10px;
    bottom: 10px;
  }
}

/*-----------------------------------------------
  ## Error 404
  ---------------------------------------------*/
.site-error-404 .search-form {
  margin-bottom: 30px;
}

.site-error-404 .error-page-content {
  margin-bottom: 50px;
}

.site-error-404 .error-page-action a + a {
  margin-left: 15px;
}

.site-error-404 .error-404-content {
  margin-bottom: 40px;
  border-right: 1px solid #eeeeee;
}

@media (max-width: 1024px) {
  .site-error-404 .error-page-action a {
    display: block;
  }
  .site-error-404 .error-page-action a + a {
    margin-left: 0;
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .site-error-404 .error-404-content {
    border-right: none;
  }
  .site-error-404 .error-page-action a {
    display: inline-block;
  }
  .site-error-404 .error-page-action a + a {
    margin-left: 15px;
    margin-top: 0px;
  }
}

@media (max-width: 400px) {
  .site-error-404 .error-page-action a {
    display: block;
  }
  .site-error-404 .error-page-action a + a {
    margin-top: 15px;
    margin-left: 0;
  }
}

/*-----------------------------------------------
  ## Blog Entries
  ---------------------------------------------*/
/*-----------------------------------------------
  ## Sidebar
  ---------------------------------------------*/
#site-sidebar {
  padding-left: 60px;
}

#site-sidebar:before, #site-sidebar:after {
  content: "";
  display: table;
}

#site-sidebar:after {
  clear: both;
}

.sidebar-left #site-sidebar {
  padding-left: 0;
  padding-right: 60px;
}

#site-sidebar > .widget img {
  max-width: 100%;
  height: auto;
}

#site-sidebar > .widget select {
  max-width: 100%;
}

#site-sidebar > .widget > .widget-title {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

@media (max-width: 1024px) {
  #site-sidebar {
    padding-left: 0;
  }
}

@media (max-width: 768px) {
  #site-sidebar {
    margin-left: -15px;
    margin-right: -15px;
  }
  #site-sidebar > .widget {
    width: 50%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
  }
  #site-sidebar > .widget:nth-child(odd) {
    clear: both;
  }
  #site-sidebar > .widget:nth-child(even) {
    clear: none;
  }
}

@media (max-width: 500px) {
  #site-sidebar {
    margin: 0;
  }
  #site-sidebar > .widget {
    padding: 0;
    float: none;
    width: 100%;
  }
}

/*-----------------------------------------------
  # Widgets
  ---------------------------------------------*/
.widget {
  margin-bottom: 50px;
}

.widget > .widget-title {
  font-size: 18px;
  font-size: 1.28571em;
  margin-bottom: 32px;
  margin-top: 0;
}

/*-----------------------------------------------
  ## About Us
  ---------------------------------------------*/
.widget_dt_about_us > .logo {
  margin-bottom: 20px;
}

.widget_dt_about_us > .description {
  margin-bottom: 30px;
}

.transparent-area .widget_dt_about_us > .description {
  opacity: 0.7;
}

.widget_dt_about_us > .contact-method {
  padding: 0;
  margin-bottom: 30px;
}

.widget_dt_about_us > .contact-method > li {
  list-style: none;
  margin-bottom: 20px;
}

.widget_dt_about_us > .contact-method > li > .dt-sprite {
  float: left;
  margin-right: 15px;
  width: 18px;
  fill: #fd367e;
}

.widget_dt_about_us > .contact-method > li > i {
  margin-right: 5px;
  color: #fd367e;
  font-size: 16px;
  min-width: 25px;
}

/*-----------------------------------------------
  ## Recent Entries
  ---------------------------------------------*/
.widget_recent_entries ul,
.widget_meta ul,
.widget_categories ul,
.widget_archive ul,
.widget_recent_comments ul,
.widget_pages ul,
.widget_nav_menu ul {
  padding: 0;
  margin: 0;
}

.widget_recent_entries ul li,
.widget_meta ul li,
.widget_categories ul li,
.widget_archive ul li,
.widget_recent_comments ul li,
.widget_pages ul li,
.widget_nav_menu ul li {
  list-style: none;
  margin-bottom: 20px;
  font-size: 16px;
  font-size: 1.14286em;
}

.widget_recent_entries ul li li,
.widget_meta ul li li,
.widget_categories ul li li,
.widget_archive ul li li,
.widget_recent_comments ul li li,
.widget_pages ul li li,
.widget_nav_menu ul li li {
  font-size: 1em;
}

.widget_recent_entries ul li ul,
.widget_meta ul li ul,
.widget_categories ul li ul,
.widget_archive ul li ul,
.widget_recent_comments ul li ul,
.widget_pages ul li ul,
.widget_nav_menu ul li ul {
  padding-top: 10px;
  padding-left: 20px;
  margin: 0;
}

.widget_recent_entries ul li a,
.widget_meta ul li a,
.widget_categories ul li a,
.widget_archive ul li a,
.widget_recent_comments ul li a,
.widget_pages ul li a,
.widget_nav_menu ul li a {
  color: #050709;
}

.transparent-area .widget_recent_entries ul li a, .transparent-area
.widget_meta ul li a, .transparent-area
.widget_categories ul li a, .transparent-area
.widget_archive ul li a, .transparent-area
.widget_recent_comments ul li a, .transparent-area
.widget_pages ul li a, .transparent-area
.widget_nav_menu ul li a {
  color: #FFF;
}

.widget_recent_entries ul li a:hover,
.widget_meta ul li a:hover,
.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.widget_recent_comments ul li a:hover,
.widget_pages ul li a:hover,
.widget_nav_menu ul li a:hover {
  color: #757575;
  padding-left: 10px;
}

.widget_recent_entries ul li:before,
.widget_meta ul li:before,
.widget_categories ul li:before,
.widget_archive ul li:before,
.widget_recent_comments ul li:before,
.widget_pages ul li:before,
.widget_nav_menu ul li:before {
  content: '\f105';
  font-family: FontAwesome;
  color: #fd367e;
  margin-right: 10px;
}

/*-----------------------------------------------
  ## Get Your Tickets
  ---------------------------------------------*/
.widget_dt_get_your_tickets .widget-content > p {
  margin-bottom: 35px;
}

.transparent-area .widget_dt_get_your_tickets .widget-content > p {
  opacity: 0.7;
}

/*-----------------------------------------------
  ## Photos From Our Events
  ---------------------------------------------*/
.widget_dt_photos_from_our_events > .gallery-container > ul {
  margin: -6px;
  padding: 0;
}

.widget_dt_photos_from_our_events > .gallery-container > ul:before, .widget_dt_photos_from_our_events > .gallery-container > ul:after {
  content: "";
  display: table;
}

.widget_dt_photos_from_our_events > .gallery-container > ul:after {
  clear: both;
}

.widget_dt_photos_from_our_events > .gallery-container > ul > li {
  padding: 6px;
  float: left;
  width: 33.33333%;
  list-style: none;
}

.widget_dt_photos_from_our_events > .gallery-container > ul > li > a {
  display: block;
  overflow: hidden;
}

.widget_dt_photos_from_our_events > .gallery-container > ul > li > a:hover {
  opacity: 0.5;
}

.widget_dt_photos_from_our_events > .gallery-container > ul > li > a > img {
  width: 100%;
  height: auto;
}

/*-----------------------------------------------
  ## Tag Cloud
  ---------------------------------------------*/
.widget_tag_cloud > .tagcloud {
  margin: 0 -5px;
}

.widget_tag_cloud > .tagcloud > a {
  display: inline-block;
  font-size: 1em !important;
  padding: 5px 10px;
  color: #fd367e;
  border: 1px solid #fd367e;
  text-transform: uppercase;
  margin: 5px;
}

.transparent-area .widget_tag_cloud > .tagcloud > a {
  color: #FFF;
}

.widget_tag_cloud > .tagcloud > a:hover {
  background-color: #fd367e;
  color: #FFF;
}

/*-----------------------------------------------
  ## Calendar
  ---------------------------------------------*/
#wp-calendar {
  width: 100%;
  position: relative;
}

#wp-calendar caption {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: #FFF;
  background-color: #000;
  padding: 0;
}

#wp-calendar thead tr {
  border-bottom: 1px solid #eee;
}

#wp-calendar thead tr th {
  text-align: center;
  color: #000;
  font-weight: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.transparent-area #wp-calendar thead tr th {
  color: #FFF;
}

#wp-calendar tbody {
  color: #000;
}

.transparent-area #wp-calendar tbody {
  color: #b3b3b3;
}

#wp-calendar tbody td {
  text-align: center;
  padding: 8px;
}

#wp-calendar tbody .pad {
  background: none;
}

#wp-calendar tfoot #prev a,
#wp-calendar tfoot #next a {
  display: inline-block;
  text-transform: uppercase;
  color: #050709;
}

.transparent-area #wp-calendar tfoot #prev a, .transparent-area
#wp-calendar tfoot #next a {
  color: #FFF;
}

#wp-calendar tfoot #prev a:hover,
#wp-calendar tfoot #next a:hover {
  opacity: 0.7;
}

#wp-calendar tfoot #next {
  text-align: right;
}

/*-----------------------------------------------
  ## Recent Comments
  ---------------------------------------------*/
.widget_recent_comments > ul > li a:hover {
  padding: 0;
}

/*-----------------------------------------------
  ## RSS Feed
  ---------------------------------------------*/
.widget_rss > .widget-title > a {
  color: #050709;
}

.widget_rss > .widget-title > a:hover {
  opacity: 0.7;
}

.transparent-area .widget_rss > .widget-title > a {
  color: #FFF;
}

.widget_rss > .widget-title > a > .rss-widget-icon {
  width: 20px;
  height: 20px;
}

.widget_rss > ul {
  padding: 0;
  margin: 0;
}

.widget_rss > ul > li {
  list-style: none;
  padding-bottom: 10px;
  border-bottom: 1px solid #EEE;
}

.widget_rss > ul > li + li {
  margin-top: 10px;
}

.widget_rss > ul > li:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.widget_rss > ul > li > .rsswidget {
  display: block;
  font-size: 1.3em;
  color: #050709;
}

.widget_rss > ul > li > .rsswidget:hover {
  opacity: 0.7;
}

.transparent-area .widget_rss > ul > li > .rsswidget {
  color: #FFF;
}

.transparent-area .widget_rss > ul > li > .rssSummary {
  opacity: 0.7;
}
