.about-the-event {

  // Nav Items
  > .nav {
    border: none;
    margin-bottom: 60px;

    > li {
      width: 50%;
      margin-bottom: 0;

      > a {
        border: 1px solid #f5f5f7;
        padding: 15px 20px;
        border-radius: 0;
        margin: 0;
        @include font-size(22);
        line-height: 1;
        font-family: $font_secondary;
        font-weight: 600;
        text-align: center;
        color: $deep_black;

        &:hover {
          background-color: lighten($color_secondary, 10%);
          color: #FFF;
          border-color: lighten($color_secondary, 10%); } }

      &.active {
        > a {
          background-color: $color_secondary;
          color: #FFF;
          border-color: $color_secondary; } } } }

  .event-image-carousel {
    .carousel-button-prev {
      left: 10px; }

    .carousel-button-next {
      right: 10px; } } }

// Responsiveness
@media (max-width: 768px) {
  .about-the-event {
    .event-image-carousel {
      margin-bottom: 30px; }

    .about-content-text-block {
      margin-bottom: 50px; } } }
