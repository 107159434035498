.widget_dt_photos_from_our_events {

  > .gallery-container {
    > ul {
      margin: -6px;
      padding: 0;
      @include clearfix();

      > li {
        padding: 6px;
        float: left;
        width: (100% / 3);
        list-style: none;

        > a {
          display: block;
          overflow: hidden;

          &:hover {
            opacity: 0.5; }

          > img {
            width: 100%;
            height: auto; } } } } } }
