.transparent-form ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.5);
}
.transparent-form ::-moz-placeholder { /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.5);
}
.transparent-form :-ms-input-placeholder { /* IE 10+ */
  color: rgba(255, 255, 255, 0.5);
}
.transparent-form :-moz-placeholder { /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5);
}

/* Bootstrap Fix */
.h6, h6 {
	font-size: 1em;
}

/* Alignments */
.alignnone {
	margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
	display: block;
	margin: 5px auto 5px auto;
}

.alignright {
	float:right;
	margin: 5px 0 20px 20px;
}

.alignleft {
	float: left;
	margin: 5px 20px 20px 0;
}

a img.alignright {
	float: right;
	margin: 5px 0 20px 20px;
}

a img.alignnone {
	margin: 5px 20px 20px 0;
}

a img.alignleft {
	float: left;
	margin: 5px 20px 20px 0;
}

a img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

/* Caption */
.wp-caption {
	background: #fff;
	border: 1px solid #f0f0f0;
	max-width: 100%;
	padding: 5px 3px 10px;
	text-align: center;
}

.wp-caption.alignnone {
	margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
	margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
	margin: 5px 0 20px 20px;
}

.wp-caption .wp-caption-text {
	padding-top: 5px;
}

/* Tables */
table {
	border-collapse: collapse;
	margin: 0 0 1.5em;
	width: 100%;
	border: 1px solid #eee;
}

thead th {
	border-bottom: 2px solid #bbb;
	padding-bottom: 0.5em;
}

th {
	padding: 10px;
	text-align: left;
}

tr {
	border-bottom: 1px solid #eee;
	transition: all 0.15s ease-in-out
}

td {
	padding: 10px;
}

tr:hover {
	background-color: #eee;
}

/* Lists */
dt {
	font-weight: 700;
}

dd {
	margin: 0 20px 20px;
}

/* Gallery */

.gallery {
	margin-bottom: 20px;
	margin-left: -10px;
	margin-right: -10px;
}

.gallery .gallery-item {
	display: inline-block;
	text-align: center;
	vertical-align: top;
	width: 100%;
	margin-bottom: 0 !important;
	padding: 10px;
}
.gallery .gallery-item a {
	display: block;
}
.gallery .gallery-item img {
	margin-bottom: 0 !important;
	max-width: 100%;
	height: auto;
}

.gallery .gallery-caption {
	display: block;
	margin-top: 10px;
}

.gallery.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery.gallery-columns-3 .gallery-item {
	max-width: 33.33%;
}

.gallery.gallery-columns-4 .gallery-item {
	max-width: 25%;
}

.gallery.gallery-columns-5 .gallery-item {
	max-width: 20%;
}

.gallery.gallery-columns-6 .gallery-item {
	max-width: 16.66%;
}

.gallery.gallery-columns-7 .gallery-item {
	max-width: 14.28%;
}

.gallery.gallery-columns-8 .gallery-item {
	max-width: 12.5%;
}

.gallery.gallery-columns-9 .gallery-item {
	max-width: 11.11%;
}

.gallery.gallery-columns-10 .gallery-item {
	max-width: 10%;
}

/* WordPress Admin Hacks */
@media screen and (max-width: 782px) {
	.admin-bar.fix-header .site-header {
		margin-top: 46px;
	}
}
@media screen and (min-width: 783px) {
	.admin-bar.fix-header .site-header {
		margin-top: 32px;
	}
}

@media screen and (max-width: 600px) {
	.admin-bar.fix-header .site-header {
		margin-top: 0;
	}
}