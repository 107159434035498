.event-statics {
  @include clearfix;
  display: flex;
  align-items: center;
  border: 1px solid #bfbfbf;

  .banner-statics & {
    border: none; }

  > .item {
    width: 33.33333333333333%;
    float: left;
    border-right: 1px solid #bfbfbf;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;

    &:last-child {
      border-right: none; }

    > .icon {
      display: inline-block;
      margin-right: 12px;

      > i {
        font-size: 38px; }

      > img {
        max-height: 38px;
        width: auto; } }

    > .content {
      display: inline-block;
      line-height: 1;

      > .number {
        font-family: $font_secondary;
        @include font-size(30);
        font-weight: 600;
        line-height: 1;
        margin-right: 5px; } } } }

// Responsiveness
@media (max-width: 500px) {
  .event-statics {

    > .item {
      padding-top: 10px;
      padding-bottom: 10px;

      > .icon {
        display: block;
        margin-right: 0;
        margin-bottom: 10px;
        height: 30px;

        > i {
          font-size: 26px; }

        > img {
          max-height: 26px; } }

      > .content {
        display: block;

        > span {
          display: block;

          &.number {
            margin: 0;
            @include font-size(18);
            margin-bottom: 5px; } } } } } }
