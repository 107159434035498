ul {

  /* Styled List */
  &.styled-list {
    padding-left: 0;
    margin-bottom: 0;

    > li {
      list-style-type: none;
      margin-bottom: 10px;

      &:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #FFF;
        border: 2px solid $color_primary;
        margin-right: 10px; } } }

  /* Social profiles */
  &.social-profiles {
    padding: 0;
    margin: 0;
    @include clearfix();

    > li {
      list-style: none;
      float: left;
      margin-right: 12px;
      margin-bottom: 10px;

      &:last-child {
        margin-right: 0; }

      > a {
        display: block;
        width: 36px;
        height: 36px;
        border: 1px solid #DDD;
        text-align: center;
        color: $deep_black;
        @include font-size(14);
        line-height: 2.5em;

        &:hover {
          color: #FFF;
          background-color: $color_secondary;
          border-color: $color_secondary; } } }

    &.transparent,
    .transparent-area & {
      > li > a {
        border-color: rgba(255,255,255,0.3);
        color: #FFF;

        &:hover {
          background-color: #FFF;
          color: $color_secondary;
          border-color: transparent; } } }

    /* Version 2 */
    &-v2 {
      padding: 0;
      margin: 0;
      @include clearfix();

      > li {
        display: inline-block;

        &+li {
          margin-left: 10px; }

        > a {
          color: $deep_black;

          &:hover {
            color: $color_primary; } } }

      &.transparent,
      .transparent-area & {
        > li > a {
          color: #FFF;

          &:hover {
            color: $color_primary; } } } } }

  /* Post Meta */
  &.post-meta {
    margin: 0 -10px;
    padding: 0;

    > li {
      display: inline-block;
      padding: 0 10px;

      > .dt-sprite {
        width: 18px;
        margin-right: 12px;
        fill: $color_primary; }

      > i {
        color: $color_primary;
        margin-right: 10px; }

      > a {
        color: $deep_gray; } } } }
