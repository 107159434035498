.site-event-schedule {

  &.style-two {

    > .container {
      padding-top: 50px;
      background-repeat: no-repeat;
      background-position: top center;
      padding-right: 50px;
      padding-left: 0; }

    .event-schedule-info {
      margin-top: 250px; } }

  &.style-three {
    > .container {
      padding-top: 150px;
      background-repeat: no-repeat;
      background-position: top center; } } }

// Responsiveness
@media (max-width: 1024px) {
  .site-event-schedule {
    &.style-two {
      > .container {
        padding-right: 15px;
        padding-left: 15px; } } } }

@media (max-width: 768px) {
  .site-event-schedule {
    &.style-two {
      > .container {
        background-image: none !important;
        padding-top: 0; }

      .event-schedule-info {
        margin-top: 0; } }

    .event-schedule-info {
      margin-bottom: 75px; } } }

@media (max-width: 600px) {
  .site-event-schedule {
    &.style-three {
      > .container {
        background-image: none !important;
        padding-top: 0px; } } } }
