.transparent-header {

  .dt-standard-navbar {
    border-bottom: 1px solid rgba(255,255,255,0.4);
    background-color: rgba(255,255,255,0.2);

    .navbar-header {
      > .navbar-brand {
        margin: 16px 100px 16px 0;

        > img {
          width: 100%;
          height: auto;

          &.white-logo {
            display: block; }

          &.black-logo {
            display: none; } } } }

    .nav.navbar-nav {
      > li {
        > a {
          color: #FFF;
          padding: 29px 0;

          &:hover,
          &:focus {
            color: $color_secondary; } }

        &.active > a,
        &.current-menu-item > a,
        &.current-menu-ancestor > a {
          color: $color_secondary; }

        > .sub-menu {
          margin-top: 1px; } } } }

  &.fix-header {
    .dt-standard-navbar {
      .navbar-header {
        > .navbar-brand {
          margin: 12px 100px 12px 0;

          > img {
            &.white-logo {
              display: none; }

            &.black-logo {
              display: block; } } } }

      .nav.navbar-nav {
        > li {
          > a {
            color: #757575;
            padding: 24px 0;

            &:hover,
            &:focus {
              color: $color_secondary; } }

          &.active > a,
          &.current-menu-item > a,
          &.current-menu-ancestor > a {
            color: $color_secondary; } }

        > .sub-menu {
          margin-top: 0px; } } } } }

@media (max-width: 767px) {
  .transparent-header {
    .dt-standard-navbar {
      border-bottom-color: transparent; } } }
