#site-sidebar {
  padding-left: 60px;
  @include clearfix();

  .sidebar-left & {
    padding-left: 0;
    padding-right: 60px; }

  > .widget {

    img {
      max-width: 100%;
      height: auto; }

    select {
      max-width: 100%; }

    > .widget-title {
      border-bottom: 1px solid #eee;
      padding-bottom: 5px; } } }

@media (max-width: 1024px) {
  #site-sidebar {
    padding-left: 0; } }

@media (max-width: 768px) {
  #site-sidebar {
    margin-left: -15px;
    margin-right: -15px;

    > .widget {
      width: 50%;
      float: left;
      padding-left: 15px;
      padding-right: 15px;

      &:nth-child(odd) {
        clear: both; }
      &:nth-child(even) {
        clear: none; } } } }

@media (max-width: 500px) {
  #site-sidebar {
    margin: 0;

    > .widget {
      padding: 0;
      float: none;
      width: 100%; } } }
