.dt-standard-navbar {
  border: 0;
  margin: 0;
  border-radius: 0;

  .navbar-header {
    position: relative;
    z-index: 2;

    > .navbar-brand {
      display: block;
      width: 128px;
      height: 44px;
      padding: 0;
      margin: 12px 100px 12px 0;

      > img {
        width: 100%;
        height: auto;

        &.white-logo {
          display: block; }

        &.white-logo {
          display: none; } } } }

  .navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
    border-top: none; }

  .nav.navbar-nav {
    margin-top: 0;
    margin-bottom: 0;

    > li {
      margin-right: 46px;

      @media (max-width: 1024px) {
        margin-right: 25px; }

      &:last-child {
        margin-right: 0; }

      > a {
        font-weight: 500;
        color: #757575;
        padding: 24px 0;
        text-transform: uppercase;

        &:hover,
        &:focus {
          background-color: transparent;
          color: $color_secondary; } }

      &.active > a,
      &.current-menu-item > a,
      &.current-menu-ancestor > a {
        color: $color_secondary; }

      &.menu-item-has-children > a:after {
        content: '\f107';
        display: inline-block;
        font-family: FontAwesome;
        margin-left: 5px;
        transition: all 0.15s ease-in-out; } }
    li {
      position: relative;
      overflow: hidden;

      &.children-menu-visible {
        overflow: visible;

        > .sub-menu {
          opacity: 1;
          transform: translateY(0px); } } }

    // Style Dropdown
    .sub-menu {
      list-style: none;
      padding: 15px;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 100;
      min-width: 225px;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
      background-color: #2d2d2d;
      opacity: 0;
      transform: translateY(50px);
      transition: all 0.25s ease-in-out;

      .sub-menu {
        left: 100%;
        top: 0; }

      > li {
        border-bottom: 1px solid #3a3a3a;

        &:last-child {
          border-bottom: none; }

        > a {
          display: block;
          padding: 10px 20px;
          white-space: nowrap;
          color: #bbbbbb;
          text-transform: uppercase;

          &:hover {
            color: #FFF; } }

        &.menu-item-has-children > a {
          position: relative;
          padding-right: 40px;

          &:after {
            content: '\f105';
            font-family: FontAwesome;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.5;
            transition: all 0.15s ease-in-out; } } } } }

  .navbar-form {
    position: absolute;
    margin: 0;
    padding: 0;
    box-shadow: none;
    width: 220px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    > input {
      background-color: transparent;
      width: 100%;
      border: 1px solid #eeeeee;

      // Placeholder colors by CSS-Tricks.com
      &::-webkit-input-placeholder {
        color: #565656; }

      &:-moz-placeholder {
        color: #565656; }

      &::-moz-placeholder {
        color: #565656; }

      &:-ms-input-placeholder {
        color: #565656; }

      &:focus {
        border-color: $color_primary; } }

    > button {
      background-color: transparent;
      border: none;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      color: #5a5a5a;
      padding: 0;
      line-height: 1; } }

  .has-search-form {
    position: relative;

    .nav.navbar-nav {
      margin-right: 225px; } }

  &.no-brand-logo {
    .navbar-header {
      > .navbar-brand {
        display: none; } } }

  .center-menu {
    > .nav.navbar-nav {
      display: table;
      margin: 0 auto;
      float: none; } } }

// Responsiveness
@media (max-width: 767px) {
  .dt-standard-navbar {
    min-height: 35px;
    background-color: #1b1b1b !important;

    .navbar-header {
      background-color: $color_secondary;
      border-top: 1px solid darken($color_secondary, 5%);

      > .navbar-brand {
        display: block;
        margin: 9px 15px !important;

        > img {

          &.black-logo {
            display: none !important; }

          &.white-logo {
            display: block !important; } } }

      > .navbar-toggle {
        float: right;
        margin: 14px;
        position: relative;

        &:hover,
        &:focus {
          background-color: transparent; }

        > .icon-bar {
          background-color: lighten($color_secondary, 60%);
          transition: all 0.15s ease-in-out; }

        .top-bar {
          transform: rotate(45deg);
          transform-origin: 10% 10%; }
        .middle-bar {
          opacity: 0; }
        .bottom-bar {
          transform: rotate(-45deg);
          transform-origin: 10% 90%; }

        &.collapsed .top-bar {
          transform: rotate(0); }
        &.collapsed .middle-bar {
          opacity: 1; }
        &.collapsed .bottom-bar {
          transform: rotate(0); } } }

    .nav.navbar-nav {
      .menu-item-has-children {
        > .sub-menu {
          display: none; }

        &.children-menu-visible {
          > .sub-menu {
            display: block; } } }

      > li {
        border-bottom: 1px solid #292929;
        margin-right: 0;

        &:last-child {
          border-bottom: none; }

        > a {
          color: #bbbbbb !important;
          padding: 10px 23px !important;

          &:hover,
          &:focus {
            color: #FFF; } }

        &.menu-item-has-children > a:after {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%) rotate(-90deg);
          opacity: 0.5; }

        &.children-menu-visible > a:after {
          transform: translateY(-50%) rotate(0deg); } }

      .sub-menu {
        padding-top: 0;
        border-top: 1px solid #3a3a3a;
        position: static;
        transform: none;
        opacity: 1;
        box-shadow: none;
        padding: 0;

        .sub-menu {
          background-color: #3a3a3a;

          > li {
            border-color: #464646; }

          .sub-menu {
            background-color: #464646;

            > li {
              border-color: #505050; } } }

        > li {
          border-left: 1px solid transparent;

          &.children-menu-visible {
            border-left: 1px solid #3a3a3a;

            > a:after {
              transform: translateY(-50%) rotate(90deg); } } } } }

    .navbar-form {
      position: relative;
      top: auto;
      right: auto;
      transform: none;
      width: 100%;
      margin-top: 25px;
      margin-bottom: 25px;
      padding-right: 15px;

      > input {
        color: #FFF;
        border-color: #DDD; }

      > button {
        right: 25px; } }

    .navbar-collapse {
      padding-right: 0;

      &.has-search-form {
        position: relative;

        .nav.navbar-nav {
          margin-right: 0; } } }

    .center-menu {
      > .nav.navbar-nav {
        display: block;
        margin: 0 0 0 -15px; } }

    &.no-brand-logo {
      .navbar-header {
        >.navbar-brand {
          display: block; } } } } }
