.section-heading {
  margin-bottom: 50px;
  > .title {
    margin-top: 0;
    margin-bottom: 30px;
    font-family: $font_secondary;
    @include font-size(40);
    font-weight: 600;

    > .effect {
      display: inline-block;
      height: 14px;
      width: 60px;
      position: relative;

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
        top: 0;
        left: 0; }

      &:before {
        width: 35px;
        background-color: $color_primary; }

      &:after {
        background-color: $color_secondary;
        top: auto;
        bottom: 0; } } }

  &:not(.stripe) {
    > .title > .effect {
      display: none; } } }
