.event-schedule-tabs {
  // Nav Items
  > .nav {
    border: none;
    text-align: center;
    display: table;
    margin: 0 auto;

    > li {
      margin-right: 20px;
      position: relative;
      z-index: 1;
      margin-bottom: 30px;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        z-index: -1;
        height: 1px;
        background-color: #eeeeee;
        top: 50%;
        left: 20%; }


      &:last-child {
        margin-right: 0;

        &:after {
          display: none; } }

      > a {
        border: 1px solid #eeeeee;
        border-radius: 0;
        font-family: $font_secondary;
        font-weight: 600;
        color: $deep_black;
        @include font-size(16);
        padding-right: 24px;
        padding-left: 24px;
        margin-right: 0;
        background-color: #FFF;

        .site-event-schedule.style-two & {
          padding-right: 22px;
          padding-left: 22px; }

        &:hover {
          background-color: $color_secondary;
          color: #FFF;
          border-color: $color_secondary; } }

      &.active > a {
        background-color: $color_secondary;
        color: #FFF;
        border-color: $color_secondary; } } }

  // Schedules
  .panel-group {

    > .panel {
      border: none;
      box-shadow: none;

      & +.panel {
        margin-top: 20px; }

      > .panel-heading {
        border-radius: 0;
        padding: 0;
        border: none;
        background-color: transparent;

        > .panel-title {
          @include font-size(18,16);
          text-transform: uppercase;

          > a {
            display: block;
            padding: 14px 35px;
            background-color: $color_secondary;
            color: #FFF;
            position: relative;

            &:after {
              content: '\f105';
              font-family: FontAwesome;
              position: absolute;
              right: 30px;
              font-size: 20px;
              top: 50%;
              transform: translateY(-50%) rotate(90deg);
              transition: all 0.15s ease-in-out; }

            > .time {
              display: inline-block;
              padding: 10px 12px;
              @include font-size(14, 16);
              font-weight: 400;
              background-color: darken($color_secondary, 7%);
              margin-right: 12px;
              transition: all 0.15s ease-in-out;

              > i {
                margin-right: 5px; } }

            &.collapsed {
              background-color: #f5f5f7;
              color: $deep_black;

              &:after {
                color: $color_primary;
                transform: translateY(-50%) rotate(0deg); }

              > .time {
                background-color: #eae9e9;

                > i {
                  color: $color_primary; } } }

            &:hover {
              text-decoration: none;
              background-color: $color_secondary;
              color: #FFF;

              &:after {
                color: #FFF; }

              > .time {
                background-color: darken($color_secondary, 7%);

                > i {
                  color: #FFF; } } }

            &:focus {
              text-decoration: none; } } } }

      > .panel-collapse {
        > .panel-body {
          border: none;
          background-color: $color_secondary;
          color: #FFF;
          padding: 20px 35px;

          > .description {
            margin-bottom: 35px; }

          > .schedule-speaker {
            display: flex;
            align-items: center;
            @include clearfix();

            > .speaker-img {
              width: 70px;
              height: 70px;
              border: 2px solid #FFF;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 35px;

              > img {
                width: 100%;
                height: auto; } }

            > .speaker-info {

              > i {
                margin-right: 5px; }

              > .name {
                color: #FFF; }

              > .job {
                opacity: 0.75;
                display: inline-block;
                margin-left: 5px;

                &:before {
                  content: '\/';
                  margin-right: 5px; } } } } } } } }

  &.timeline-style {
    @include clearfix();

    // Navs
    > .nav {
      display: block;
      float: left;
      width: 200px;

      > li {
        display: block;
        float: none;
        margin-right: 0;
        margin-bottom: 16px;

        &:after {
          display: none; } } }

    // Tab Content
    > .tab-content {
      margin-left: 220px;
      padding: 25px 60px 50px 60px;
      background-color: #fafafa;
      position: relative;

      .schedule-timeline {

        > .item {
          position: relative;
          padding-bottom: 65px;

          &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 1px;
            background-color: #dcdcdc;
            left: -30px;
            top: 10px; }

          &:last-child {
            padding-bottom: 25px;

            &:after {
              content: '';
              position: absolute;
              width: 5px;
              height: 5px;
              background-color: #dcdcdc;
              border-radius: 50%;
              bottom: -10px;
              left: -32px; } }

          > .time {
            color: $deep_black;
            margin-bottom: 15px;

            &:before {
              content: '\f017';
              font-family: FontAwesome;
              position: absolute;
              left: -36px;
              font-size: 16px;
              top: -0px;
              color: $color_primary; } }

          > .title {
            margin-top: 0;
            font-family: $font_secondary;
            font-weight: 600;
            @include font-size(16);
            margin-bottom: 25px;
            text-transform: uppercase; }

          > .content {
            margin-bottom: 30px; }

          > .schedule-speaker {
            display: flex;
            align-items: center;

            > .speaker-img {
              width: 70px;
              height: 70px;
              border-radius: 50%;
              overflow: hidden;
              border: 3px solid #FFF;
              margin-right: 25px;

              > img {
                width: 100%;
                height: auto; } }

            > .speaker-info {
              > i {
                color: $color_primary;
                margin-right: 5px; }

              > .name {
                color: $deep_black; }

              > .job {

                &:before {
                  content: '\/';
                  margin-right: 5px; } } } } } } }

    &.sidenav {
      > .tab-content {
        background-color: transparent;
        padding: 0; } } } }

// Responsiveness
@media (max-width: 1024px) {
  .event-schedule-tabs {

    // Nav Items
    > .nav {
      > li {
        > a {
          @include font-size(14);
          padding-right: 15px;
          padding-left: 15px;

          .site-event-schedule.style-two & {
            padding-right: 15px;
            padding-left: 15px; } } } }

    // Panels
    .panel-group {

      > .panel {

        > .panel-heading {

          > .panel-title {
            @include font-size(16);

            > a {
              padding: 10px 20px;

              &:after {
                right: 20px; }

              > .time {
                @include font-size(14, 16);
                font-weight: 400; } } } } } } } }

@media (max-width: 767px) {
  .event-schedule-tabs {
    // Nav Items
    > .nav {
      > li {
        width: 50%;
        float: left;
        margin-right: 0;
        margin-bottom: 10px;
        padding: 5px;

        &:after {
          display: none; }

        > a {
          @include font-size(14);
          padding-right: 15px;
          padding-left: 15px; } } }

    // Schedules
    .panel-group {

      > .panel {

        > .panel-heading {

          > .panel-title {

            > a {

              > .time {
                display: block;
                text-align: center;
                margin-bottom: 10px; }

              > .title {
                display: block;
                text-align: center;
                line-height: 1.4em; } } } }
        > .panel-collapse {
          > .panel-body {

            > .schedule-speaker {
              display: block;

              > .speaker-img {
                margin-right: 10px;
                float: left;

                > img {
                  width: 100%;
                  height: auto; } }

              > .speaker-info {
                padding-top: 20px; } } } } } }

    &.timeline-style {
      > .nav {
        width: auto;
        float: none;
        margin-bottom: 25px;

        > li {
          float: left;
          margin-bottom: 0px;
          padding: 5px; } }

      > .tab-content {
        margin: 0;
        padding: 20px 10px 50px 30px;

        .schedule-timeline {
          > .item {
            > .schedule-speaker {
              display: block;

              > .speaker-img {
                float: left; }

              > .speaker-info {
                padding-top: 20px; } } } } } } } }
