// Theme Color
$color_primary: #fd367e;
$color_secondary: #4e1184;

// Text Color
$deep_gray: #757575;
$light_gray: #f5f5f7;
$deep_black: #050709;

// Button Colors
// $btn_purple_light #
