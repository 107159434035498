.event-countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  @include clearfix();

  > .event-info {
    margin-right: 50px;
    margin-left: 30px; }

  > .countdown {

    > .item {
      display: inline-block;

      &+.item {
        margin-left: 15px; }

      > span {
        display: block;
        text-transform: uppercase;
        line-height: 1;

        &.number {
          font-family: $font_secondary;
          font-weight: 600;
          @include font-size(50);

          &:after {
            content: ' :'; } } }

      &:last-child > .number:after {
        display: none; } } }

  // Style 2
  &.style-two {
    display: block;
    position: relative;

    color: #FFF;

    > .event-info {
      position: absolute;
      bottom: 10px;
      left: -250px;
      font-weight: 700;
      z-index: 20; }

    > .countdown {
      position: relative;
      float: left;
      margin-bottom: 30px;

      > .item {
        text-align: center;
        position: relative;
        margin-right: 40px;

        > span {

          &.number {
            width: 112px;
            height: 75px;
            position: relative;
            overflow: hidden;
            z-index: 10;
            padding-top: 25px;
            @include font-size(40, 14);

            &:after {
              content: '';
              position: absolute;
              width: 112px;
              height: 112px;
              background-color: rgba(0,0,0, 0.7);
              border: 3px solid #FFF;
              border-radius: 50%;
              top: 0;
              left: 0;
              z-index: -1; } }

          &.text {
            position: absolute;
            z-index: 20;
            width: 100%;
            bottom: -19px; } }

        &:last-child > .number:after {
          display: block; } }

      &:after,
      &:before {
        content: '';
        position: absolute;
        height: 26px;
        background-color: rgba($color_primary, 0.7);
        width: 110%;
        left: 0px;
        bottom: -21px;
        border-top-right-radius: 13px;
        border-bottom-right-radius: 13px; }

      &:before {
        width: 200%;
        left: -200%;
        border-radius: 0; } } }

  // Style 3
  &.style-three {
    position: relative;
    padding-top: 30px;

    > .event-info {
      position: absolute;
      top: 0;
      margin: 0;
      width: 100%; }

    > .countdown {
      > .item {
        text-align: left;

        > .text {
          margin-bottom: 5px; } } } } }

// Responsiveness
@media (max-width: 1440px) {
  .event-countdown {
    &.style-two {
      > .event-info {
        position: relative;
        bottom: auto;
        left: auto;
        margin-left: 0;
        margin-bottom: 30px;
        float: left;
        line-height: 1.8em;
        background-color: rgba($color_primary, 0.7);
        padding-top: 2px;
        padding-left: 25px;
        padding-right: 50px;
        border-top-right-radius: 13px;
        border-bottom-right-radius: 13px; } } } }

@media (max-width: 1024px) {
  .event-countdown {
    > .countdown {
      text-align: center;

      > .item {
        > span {
         &.number {
          @include font-size(30,14); } } } }

    &.style-two {
      > .countdown {
        text-align: left; } } } }

@media (max-width: 768px) {
  .event-countdown {
    text-align: center;

    > .event-info {
      margin-left: 0; }

    > .countdown {
      > .item {
        > span {
          &.number {
            @include font-size(26,14); }
          &.text {
            margin-bottom: 5px; } } } }

    &.style-two {
      text-align: left;

      > .countdown {
        > .item {
          margin-right: 5px;

          > span {

            &.number {
              width: 65px;
              height: 45px;
              padding-top: 15px;
              @include font-size(22, 14);

              &:after {
                width: 65px;
                height: 65px; } }

            &.text {
              bottom: -25px; } } } } } } }

@media (max-width: 500px) {
  .event-countdown {
    display: block;
    > .event-info {
      margin: 0;
      margin-bottom: 10px; }

    > .countdown {
      > .item {
        > span {
          &.number {
            @include font-size(18,14); }

          &.text {
            @include font-size(12, 14); } } } }

    &.style-two {
      > .countdown {
        > .item {
          margin-right: 5px;

          > span {

            &.number {
              width: 55px;
              height: 40px;
              padding-top: 15px;
              @include font-size(22, 14);

              &:after {
                width: 55px;
                height: 55px; } }

            &.text {
              bottom: -25px; } } } } } } }
