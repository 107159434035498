form.wpcf7-form {
  .wpcf7-form-control {

    &.wpcf7-not-valid {
      border-color: #f00; } }

  .ajax-loader {
    display: none;

    &.is-active {
      display: inline-block; } }

  .wpcf7-response-output {
    margin-left: 0;
    margin-right: 0; } }
