.event-sponsor-gallery {
  text-align: center;
  > .item {
    display: inline-block;
    padding: 3px;

    > a,
    > span {
      display: block;

      > img {
        max-width: 100%;
        height: auto; } }

    > img {
      display: inline-block; } } }
