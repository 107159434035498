/*!
  Theme Name: Devent
  Theme URI: http://www.decentthemes.com/product/devent-events-conference-template/
  Author: Decent Themes
  Author URI: http://www.decentthemes.com
  Description: Events & Conference Site Template.
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: devent
  Tags: one-column, two-columns, right-sidebar, accessibility-ready, custom-background, custom-colors, custom-header, custom-menu, editor-style, featured-images, flexible-header, microformats, post-formats, rtl-language-support, sticky-post, threaded-comments, translation-ready, blog
  */

/*-----------------------------------------------
  >>> TABLE OF CONTENTS:
  -----------------------------------------------
  # Base rules
    ## Accessibility
    ## Links
    ## Buttons
    ## Lists
    ## Form
    ## Contact Form 7 Support
    ## Tables
    ## Typography
    ## WordPress Core
    ## WooCommerce Hacks
    ## VC Hacks
  # Elements
    ## Event Countdown
    ## Navbar (Standard)
    ## Navbar (Transparent)
    ## Event Statics
    ## Carousel Controls
    ## Event Schedule Info
    ## Event Schedule Tabs
    ## Event Image Carousel
    ## About Content Block
    ## Newsletter form
    ## Speaker Profile Thumb
    ## Speaker Profile Thumb (v2)
    ## Speaker Profile Details
    ## Testimonial Item
    ## Testimonial Item (v2)
    ## Pricing Table
    ## Register form
    ## Event Sponsor Gallery
    ## Recent News List
    ## Contact Form
    ## Contact Method Box
    ## Gallery Item
    ## Navigation
    ## Error 404 Content
    ## Search Form
    ## Article
    ## Post Author Bio
    ## Comments
    ## Back To Top
  # Sections
    ## Site Header
    ## Page Header
    ## Site Banner
    ## Event Schedule
    ## About The Event
    ## Newsletter
    ## Event Speakers
    ## Testimonials
    ## Event Pricing
    ## Register Now
    ## Photos From Our Event
    ## Event Sponsor
    ## Recent News
    ## Contact Us
    ## Site Footer
    ## About Us Content Block
    ## Speakers Profile Carousel
    ## Galleries Archive
    ## Error 404
    ## Blog Entries
    ## Sidebars
  # Widgets
    ## About Us
    ## Recent Entries
    ## Get Your Tickets
    ## Photos From Our Events
    ## Tag Cloud
    ## Calendar
    ## Recent Comments
    ## RSS Feed
  ---------------------------------------------*/
@import "variables/variables";
@import "mixins/mixins";

/*-----------------------------------------------
  # Base rules
  ---------------------------------------------*/
@import "base/base";

/*-----------------------------------------------
  # Elements
  ---------------------------------------------*/
@import "elements/elements";

/*-----------------------------------------------
  # Sections
  ---------------------------------------------*/
@import "sections/sections";

/*-----------------------------------------------
  # Widgets
  ---------------------------------------------*/
@import "widgets/widgets";
