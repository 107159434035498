body {
  font-family: $font_primary;
  font-size: 14px;
  font-weight: 400;
  color: $deep_gray;
  -ms-word-wrap: break-word;
  word-wrap: break-word; }

// Link colors
a {
  color: $deep_black;

  &:hover {
    color: $color_primary; } }

#site {
  position: relative; }

hr {
  &.mt-50 {
    margin-top: 50px; } }

/* Swiper slider tweak */
[data-carousel="swiper"] {
  position: relative; }

.swiper-slide {
  &[data-bg-image] {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }

  .banner-content {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    transition-delay: 0.4s; }

  &.swiper-slide-active {
    .banner-content {
      opacity: 1; } } }

/* Map Display Area */
.map-display-area {
  min-height: 250px;

  .contact-page-map & {
    min-height: 500px;
    @media (max-width: 768px) {
      min-height: 200px;
      max-height: 250px; } } }

/* SVG Sprite */
.dt-sprite {
  float: left;
  display: inline-block;

  > svg {
    display: block;
    width: 100%;
    height: auto; } }

/* WordPress Alignment Classes */
.alignleft {
  display: inline;
  float: left;
  margin-right: 2em; }

.alignright {
  display: inline;
  float: right;
  margin-left: 2em; }

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto; }

/* Preloader */
#site-preloader {
  position: fixed;
  background-color: #050709;
  width: 100%;
  height: 100%;
  z-index: 2000;

  > .loader-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }
