.recent-news-list {
  article.post {
    max-width: 370px;
    margin: 0 auto 30px auto;

    > .post-header {
      > .post-thumb {
        > a {
          display: block;
          > img {
            width: 100%;
            height: auto; } } }

      > .post-meta {
        background-color: #f5f5f7;
        padding: 10px 4px;
        margin: 0 0 30px 0; }

      > .post-title {
        margin-top: 0;
        margin-bottom: 36px;
        @include font-size(22);
        line-height: 1;

        > a {
          color: $deep_black;

          &:hover {
            color: $color_secondary; } } } }

    > .post-content {
      margin-bottom: 38px; }

    > a {
      text-transform: uppercase; } } }
