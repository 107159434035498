.error-404-content {
  font-family: $font_secondary;

  > .error-code {
    @include font-size(340);
    line-height: 1;
    color: $color_secondary; }

  > .error-message {
    @include font-size(32);
    text-transform: uppercase;
    color: $deep_black; } }

// Responsiveness
@media (max-width: 1024px) {
  .error-404-content {
    > .error-code {
      @include font-size(250); } } }

@media (max-width: 768px) {
  .error-404-content {
    > .error-code {
      @include font-size(150); } } }
