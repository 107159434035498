/* Pagination */
.swiper-pagination {
  margin: 0;
  padding: 0;
  bottom: 10px;
  @include clearfix();
  z-index: 200;
  position: absolute;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  line-height: 1;

  > li {
    display: block;
    float: left;
    width: auto;
    height: auto;
    color: #FFF;
    background-color: transparent;
    font-family: $font_secondary;
    font-weight: 600;
    @include font-size(20,14);
    margin-right: 35px;
    opacity: 0.5;
    transition: all 0.15s ease-in-out;

    > span {
      display: block; }

    &[class*=active] {
      opacity: 1;
 } }      // +font-size(26,14)

  // Vertical
  &.vertical {
    display: block;
    top: 50%;
    right: 10px;
    left: auto;
    bottom: auto;
    transform: translateY(-50%);

    > li {
      float: none;
      margin-right: 0;
      margin-bottom: 35px;

      &:last-child {
        margin-bottom: 0; } } } }

/* Navigation */
.carousel-button-prev,
.carousel-button-next {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -15px;
  background: transparent;
  transition: all 0.15s ease-in-out;
  border: 1px solid $color_primary;
  z-index: 10;

  > i {
    color: $color_primary;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

  &:hover {
    background-color: $color_secondary;
    border-color: $color_secondary;
    cursor: pointer;

    > i {
      color: #FFF; } }

  .transparent-area & {
    background-color: transparent;
    border-color: #FFF;
    opacity: 0.5;

    > i {
      color: #FFF; }

    &:hover {
      opacity: 1;
      background-color: #FFF;
      border-color: #FFF;
      > i {
        color: $color_secondary; } } } }

.carousel-button-next {
  right: 0;
  left: auto; }

/* Controls */
.slider-control {
  position: absolute;
  padding-top: 85px;
  padding-bottom: 85px;
  top: 50%;
  right: 150px;
  transform: translateY(-50%);
  z-index: 200;
  backface-visibility: hidden;

  > .swiper-pagination {
    position: relative;
    margin: 0;
    left: auto;
    right: auto;
    transform: none; }

  > .carousel-button-prev {
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: 0;
    transform: translateX(-50%); }

  > .carousel-button-next {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); } }

// Responsiveness
@media (max-width: 1024px) {
  .slider-control {
    right: 25px; } }

@media (max-width: 768px) {
  .slider-control {
    right: 50%;
    top: auto;
    bottom: 25px;
    transform: translateX(50%);
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 85px;
    padding-left: 85px;
    width: 100%;

    &.position-two {
      bottom: 115px; }

    > .carousel-button-prev,
    > .carousel-button-next {
      margin: 0;
      top: 50%;
      right: auto;
      bottom: auto;
      left: auto;
      transform: translateY(-50%); }

    > .carousel-button-prev {
      left: 15px; }

    > .carousel-button-next {
      right: 15px; }

    > .swiper-pagination {
      top: auto;

      > li {
        margin-bottom: 0;
        margin-right: 10px;
        display: inline-block;
        text-align: center; } } } }
