.contact-method-box {
  background-color: $color_secondary;
  color: #FFF;
  padding: 7px;

  &+.contact-method-box {
    margin-top: 20px; }

  > .inside {
    border: 1px solid lighten($color_secondary, 30%);
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 85px;
      height: 100%;
      width: 1px;
      background-color: lighten($color_secondary, 30%); }

    > .icon {
      position: absolute;
      width: 85px;
      height: 100%;
      top: 0;
      left: 0;
      text-align: center;

      > img {
        max-width: 45px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }

      > i {
        font-size: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }

    > .content {
      padding: 10px;
      margin-left: 85px;

      > .title {
        color: #FFF;
        margin-top: 0;
        margin-bottom: 15px;
        @include font-size(20);
        text-transform: uppercase;
        font-weight: 400; }

      p {
        margin: 0; } } } }
