#comments {

  .comments-title {
    @include font-size(30);
    font-family: $font_secondary;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 30px; }

  > .comment-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li.comment {

      > .comment-body {
        border: 1px solid #eeeeee;
        position: relative;
        padding: 20px 30px 20px 75px;
        margin-left: 50px;
        margin-bottom: 40px;
        min-height: 140px;

        img,
        iframe {
          max-width: 100%; }

        img {
          height: auto; }

        > .comment-meta {
          position: relative;
          margin-bottom: 15px;
          line-height: 1;
          @include clearfix();

          > .comment-author {

            > img {
              width: 100px;
              height: 100px;
              border: 3px solid #f5f5f7;
              position: absolute;
              top: 0px;
              left: -125px; }

            > .fn {
              @include font-size(20);
              font-family: $font_secondary;
              font-weight: 600;
              color: $deep_black;
              line-height: 1;
              float: left;

              > a {
                color: $deep_black;
                &:hover {
                  opacity: 0.7; } } } }

          > .comment-metadata {
            float: right;

            > a,
            > .reply,
            > .edit-link {
              display: inline-block;
              line-height: 1; }

            > a {
              color: $deep_black;

              &:hover {
                opacity: 0.7; } }

            > .edit-link {
              border-left: 1px solid #c2c2c2;
              padding-left: 5px;
              margin-left: 5px;

              > a {
                color: $deep_black;
                &:hover {
                  opacity: 0.7; } } } }

          > .comment-awaiting-moderation {
            margin-bottom: 10px;
            padding-top: 25px;
            font-style: italic; } }

        > .reply {
          text-align: right;

          a {
            color: #fff;
            display: inline-block;
            padding: 5px 15px;
            border: 1px solid $color_primary;
            background-color: $color_primary;

            &:hover {
              color: $color_primary;
              background-color: transparent; } } } } }

    // Comment Author
    li.bypostauthor {
      > .comment-body {
        border-color: $color_primary;

        > .comment-meta > .comment-author > img {
          border-color: $color_primary; } } }

    li.pingback {
      border: 1px solid #DDD;
      padding: 10px;
      margin-bottom: 15px;

      a.url {
        color: $deep_black;

        &:hover {
          opacity: 0.6; } } }
    // children
    .children {
      list-style: none; } } }

#respond {
  margin-bottom: 50px;

  .comment-reply-title {
    margin-top: 0;
    margin-bottom: 30px;
    font-family: $font_secondary;
    font-weight: 600;
    @include font-size(30); }

  label {
    display: none; }

  a {
    color: $deep_black;

    &:hover {
      opacity: 0.5; } }

  #cancel-comment-reply-link {
    color: $color_primary; } }

// Responsiveness
@media (max-width: 600px) {
  #comments {

    .comment-list {

      li.comment {
        > .comment-body {
          padding: 15px 15px 10px 30px;
          margin-left: 35px;
          > .comment-meta {

            > .comment-author {
              > img {
                width: 60px;
                height: 60px;
                top: -5px;
                left: -75px; }

              .fn {
                float: none;
                display: block;
                margin-bottom: 10px; } }

            > .comment-metadata {
              float: none;
              opacity: 0.8; } } } }

      .children {
        padding-left: 15px; } } } }
