.testimonial-item-v2 {

  .swiper-slide > & {
    padding: 0 80px;
    opacity: 0;
    transition: all 0.15s ease-in-out 0.4s; }

  .swiper-slide-active > & {
    opacity: 1; }

  > .reviewer-content {
    margin-bottom: 34px;
    text-align: center;
    background-color: #f7f7f7;
    padding: 26px;
    border: 1px solid #000;
    position: relative;

    > p {
      margin: 0;
      position: relative; }

    .transparent-area &, {
      background-color: rgba(255,255,255,0.2);
      border: 1px solid #FFF; } }

  > .reviewer-meta {
    text-align: center;

    > .reviewer-image {
      width: 100px;
      height: 100px;
      margin: 0 auto 18px;
      border: 1px solid #000;
      overflow: hidden;
      padding: 5px;

      > img {
        width: 100%;
        height: auto; }

      .transparent-area & {
        border-color: #FFF; } }

    > .name {
      @include font-size(22);
      margin-top: 0;
      margin-bottom: 14px;
      line-height: 1;
      font-family: $font-secondary;
      font-weight: 600; }

    > .job {
      margin: 0;

      .transparent-area & {
        opacity: 0.7; } } }

  > .reviewer-content {} }
