.widget {
  margin-bottom: 50px;

  > .widget-title {
    @include font-size(18);
    margin-bottom: 32px;
    margin-top: 0; } }

/*-----------------------------------------------
  ## About Us
  ---------------------------------------------*/
@import "about-us";

/*-----------------------------------------------
  ## Recent Entries
  ---------------------------------------------*/
@import "recent-entries";

/*-----------------------------------------------
  ## Get Your Tickets
  ---------------------------------------------*/
@import "get-your-tickets";

/*-----------------------------------------------
  ## Photos From Our Events
  ---------------------------------------------*/
@import "photos-from-our-event";

/*-----------------------------------------------
  ## Tag Cloud
  ---------------------------------------------*/
@import "tagcloud";

/*-----------------------------------------------
  ## Calendar
  ---------------------------------------------*/
@import "calendar";

/*-----------------------------------------------
  ## Recent Comments
  ---------------------------------------------*/
@import "recent-comments";

/*-----------------------------------------------
  ## RSS Feed
  ---------------------------------------------*/
@import "rssfeed";
