.widget_dt_about_us {

  > .logo {
    margin-bottom: 20px; }

  > .description {
    margin-bottom: 30px;

    .transparent-area & {
      opacity: 0.7; } }

  > .contact-method {
    padding: 0;
    margin-bottom: 30px;

    > li {
      list-style: none;
      margin-bottom: 20px;

      > .dt-sprite {
        float: left;
        margin-right: 15px;
        width: 18px;
        fill: $color_primary; }

      > i {
        margin-right: 5px;
        color: $color_primary;
        font-size: 16px;
        min-width: 25px; } } } }
