.gallery-archive {
  column-count: 3;
  column-gap: 2em;
  column-span: all;

  > .gallery-item {
    break-inside: avoid; } }

// Responsiveness
@media (max-width: 600px) {
  .gallery-archive {
    column-count: 2;
    column-gap: 1.5em;
    column-span: all;

    > .gallery-item {
      break-inside: avoid;

      > .content > .info {
        padding: 10px;

        > .title {
          @include font-size(16); }

        > .plus-sign {
          left: 10px;
          bottom: 10px; } } } } }
