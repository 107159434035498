.speaker-profile-thumb {
  position: relative;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  color: #FFF;
  margin-bottom: 40px;

  > .profile-link {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    color: #FFF;
    opacity: 0;
    transform: scale(3);
    transition: all 0.25s;
    transition-delay: 0.25s; }

  > img {
    width: 100%;
    height: 100%; }

  > .profile-info {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -75px;
    left: 0;
    background-color: rgba(5,7,9,.7);
    padding: 10px 34px 50px 34px;
    transform: translateY(100%);
    border-top: 1px solid rgba(255,255,255,0.7);
    transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);

    > .name-job {
      border-bottom: 1px solid rgba(255,255,255, 0.7);
      margin-bottom: 35px;
      padding-bottom: 14px;

      > .name {
        @include font-size(22);
        font-family: $font_secondary;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 10px; }

      > .job {
        opacity: 0.7; } }

    > .description {
      transform: translateY(50%);
      opacity: 0;
      transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.3s; }

    > .social-profiles {
      position: absolute;
      bottom: 50px;
      text-align: center;
      right: 34px;
      left: 34px;
      text-align: center;
      transform: translateY(50%);
      opacity: 0;
      transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.5s;

      > li {
        display: inline-block;
        float: none; } } }

  &:hover {
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);

    > .profile-link {
      opacity: 1;
      transform: scale(1); }

    > .profile-info {
      top: 0;
      padding-top: 75px;
      transform: translateY(0%);
      border-top: 1px solid rgba(255,255,255,0.0);

      > .description,
      > .social-profiles {
        opacity: 1;
        transform: translateY(0%); } } } }

// Responsiveness
@media (min-width: 768px) and (max-width: 1024px) {
  .speaker-profile-thumb {

    > .profile-info {
      padding: 15px;

      > .name-job {
        margin-bottom: 15px; }

      > .social-profiles {
        left: 15px;
        right: 15px; } }

    &:hover {
      > .profile-info {
        padding-top: 15px; } } } }

@media (max-width: 768px) {
  .speaker-profile-thumb {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto; } }
