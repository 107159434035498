.search-form {
  position: relative;

  > label {
    display: inline; }

  > button {
    position: absolute;
    top: 14px;
    right: 16px;
    border: none;
    background: none;
    padding: 0;
    color: inherit;

    > i {
      font-size: 18px; } } }
