.widget_rss {
  > .widget-title {
    > a {
      color: $deep_black;

      &:hover {
        opacity: 0.7; }

      .transparent-area & {
        color: #FFF; }

      > .rss-widget-icon {
        width: 20px;
        height: 20px; } } }

  > ul {
    padding: 0;
    margin: 0;

    > li {
      list-style: none;
      padding-bottom: 10px;
      border-bottom: 1px solid #EEE;

      &+li {
        margin-top: 10px; }

      &:last-child {
        margin-bottom: 0;
        border-bottom: none; }

      > .rsswidget {
        display: block;
        font-size: 1.3em;
        color: $deep_black;

        &:hover {
          opacity: 0.7; }

        .transparent-area & {
          color: #FFF; } }
      > .rssSummary {
        .transparent-area & {
          opacity: 0.7; } } } } }
