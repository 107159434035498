.hentry {
  @include clearfix();

  .site-blog-single-content & {
    margin-bottom: 50px; }

  &.sticky {
    background-color: #f7f7f7;
    padding: 25px; }

  &+.hentry {
    margin-top: 70px; }

  > .entry-header {
    margin-bottom: 40px;

    > .post-thumbnail {
      margin-bottom: 30px;
      text-align: center;

      > img {
        max-width: 100%;
        height: auto; } }

    > .entry-meta {
      margin-bottom: 20px; }

    > .entry-title {
      margin-top: 0;
      @include font-size(32);
      line-height: 1.2; } }

  .entry-meta {
    padding: 0;
    margin-bottom: 30px;
    position: relative;

    > li {
      display: inline-block;
      margin-right: 10px;

      .updated:not(.published) {
        display: none; }

      &:last-child {
        margin-right: 0; }

      > i {
        color: $color_primary;
        margin-right: 5px; }

      > a {
        color: #757575; }

      &.sharer {
        cursor: pointer;

        > .sharer-items {
          cursor: auto;
          opacity: 0;
          position: absolute;
          width: 100%;
          top: 50%;
          left: 50%;
          background-color: $color_primary;
          text-align: center;
          visibility: hidden;
          transform: translate(-50%, -50%) scaleX(0);
          transition: all 0.15s ease-in-out;

          > a {
            display: inline-block;
            color: #fff;
            padding: 5px 10px;

            &:hover {
              background-color: #FFF;
              color: $color_primary; } } }

        &:hover {
          > .sharer-items {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%) scaleX(1); } } } } }

  > .post-thumbnail {
    float: left;
    width: 320px;
    height: 280px;
    overflow: hidden;

    > a {
      display: block;
      > img {
        width: 100%;
        height: auto; }

      > img {
        width: 100%;
        height: auto; } } }

  > .post-content {

    > .entry-title {
      @include font-size(32);
      line-height: 1.2;
      margin-top: 0;
      margin-bottom: 12px;

      > a {
        color: $deep_black;

        &:hover {
          color: $color_secondary; } } }

    > .entry-excerpt {
      margin-bottom: 30px; }

    > .readmore {
      text-transform: uppercase; } }

  > .entry-content {
    figure,
    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 25px; }

    figure > img {
      margin-bottom: 0; } }

  > .entry-footer {
    .cat-links,
    .tags-links {
      margin-bottom: 10px;

      > i {
        padding-right: 10px; }

      > a {
        color: $deep_black;

        &:hover {
          opacity: 0.5; } } }

    .cat-links {
      border-bottom: 1px solid $light_gray;
      padding-bottom: 10px; } }


  &.devent_post_thumbnail_enabled.has-post-thumbnail {
    > .post-content {
      padding-left: 350px; } } }

// Responsiveness
@media (max-width: 1024px) {
  .hentry {
    &+.hentry {
      margin-top: 40px; }

    > .post-thumbnail {
      width: 220px;
      height: 180px; }

    > .post-content {

      > .entry-meta {
        margin-bottom: 15px; }

      > .entry-excerpt {
        margin-bottom: 20px;
        height: 75px;
        overflow: hidden; }

      > .readmore {
        padding: 10px 20px; } }

    &.devent_post_thumbnail_enabled.has-post-thumbnail {
      > .post-content {
        padding-left: 240px; } } } }

@media (max-width: 600px) {
  .hentry {
    &+.hentry {
      margin-top: 30px; }

    > .post-thumbnail {
      width: 100px;
      height: 100px; }

    > .post-content {

      > .entry-title {
        @include font-size(16);
        margin-bottom: 5px; }

      > .entry-meta {
        @include font-size(12, 14);
        margin-bottom: 10px; }

      > .entry-excerpt {
        height: 75px;
        overflow: hidden;
        margin-bottom: 15px; }

      > .readmore {
        padding: 7px 15px; } }

    &.devent_post_thumbnail_enabled.has-post-thumbnail {
      > .post-content {
        padding-left: 115px; } } } }
