input[type="submit"] {
  border: none; }

input[type="button"],
input[type="submit"],
button,
.btn {
  border-radius: 0;
  outline: none;
  transition: all 0.15s ease-in-out;

  &:focus,
  &:active:focus {
    outline: none; } }

input[type="button"],
input[type="submit"],
button,
.btn {
  line-height: 1;
  padding: 15px 30px; }

input[type="button"],
input[type="submit"],
button,
.btn-devent {
  color: #FFF;
  background-color: $color_primary;
  border: 1px solid $color_primary;

  &:hover,
  &:focus {
    color: #FFF;
    background-color: darken($color_primary, 15%); }

  &.btn-hs:hover,
  &.btn-hs:focus {
    background-color: transparent;
    color: $color_primary; }

  &.dt-alt {
    background-color: $color_secondary;
    border-color: $color_secondary;

    &:hover,
    &:focus {
      background-color: darken($color_secondary, 15%); }

    &.btn-hs:hover,
    &.btn-hs:focus {
      background-color: transparent;
      color: $color_secondary; } }

  &.btn-alt-2 {
    background-color: lighten($color_secondary, 20%);
    border-color: lighten($color_secondary, 20%);

    &:hover,
    &:focus {
      background-color: lighten($color_secondary, 10%); }

    &.btn-hs:hover,
    &.btn-hs:focus {
      background-color: transparent;
      color: lighten($color_secondary, 20%); } } }


// Play btn for image carousel hover
.dt-play-btn {
  display: inline-block;
  width: 70px;
  height: 70px;
  background-color: rgba($color_primary, 0.8);
  position: relative;
  border-radius: 50%;
  z-index: 10;

  > i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFF;
    font-size: 24px;
    margin-left: 2px; }

  &:before {
    content: '';
    position: absolute;
    width: 84px;
    height: 84px;
    border-radius: 50%;
    background: rgba($color_primary, 0.6);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1; }

  &:after {
    content: '';
    position: absolute;
    width: 98px;
    height: 98px;
    border-radius: 50%;
    background: rgba($color_primary, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -2; }

  &:hover {
    opacity: 0.5 !important; } }
