.register-now-form {
  max-width: 840px;
  margin: 0 auto;
  text-align: center;
  @include clearfix();

  > p {
    width: 50%;
    float: left;
    padding: 0 10px;

    &:nth-last-child(2) {
      width: auto;
      float: none;
      padding: 0;
      @include clearfix(); } }

  > input,
  > select {
    width: 46%;
    float: left;
    margin: 10px 2%; }

  > button {
    margin-top: 40px;
    text-transform: uppercase;

    > i {
      display: none; }

    &.clicked {
      min-width: 160px;
      > i {
        display: inline-block; }

      > span {
        display: none; } } }

  .form-result {
      margin-top: 25px; } }

// Responsiveness
@media  (max-width: 768px) {
  .register-now-form {
    > p,
    > input,
    > select {
      width: 100%;
      margin: 10px 0; } } }
