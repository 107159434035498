.widget_recent_entries,
.widget_meta,
.widget_categories,
.widget_archive,
.widget_recent_comments,
.widget_pages,
.widget_nav_menu {

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      margin-bottom: 20px;
      @include font-size(16);

      li {
        font-size: 1em; }

      ul {
        padding-top: 10px;
        padding-left: 20px;
        margin: 0; }

      a {
          color: $deep_black;
          .transparent-area & {
            color: #FFF; }

          &:hover {
            color: $deep_gray;
            padding-left: 10px; } }

      &:before {
        content: '\f105';
        font-family: FontAwesome;
        color: $color_primary;
        margin-right: 10px; } } } }
