.Switcher {
  font-family: $font_primary;
  position: fixed;
  top: 30%;
  right: 0;
  width: 208px;
  margin-right: -208px;
  padding: 20px;
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, .02);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0);
  z-index: 1000;
  transition: none 0.5s ease-out;
  transition-property: margin-right, box-shadow;
  &--show {
    margin-right: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, .15); }

  &::before {
    content: "";
    position: absolute;
    z-index: 9998;
    height: 100%;
    width: 15px;
    left: 0;
    top: 0;
    margin-left: 0;
    background-color: #FFF; }
  &__control {
    cursor: pointer;
    position: absolute;
    height: 50px;
    width: 50px;
    left: 0;
    margin-left: -50px;
    background-color: #FFF;
    color: $deep_gray;
    border: 1px solid rgba(0, 0, 0, .02);
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    border-radius: 3px 0 0 3px;
    padding: 0;
    border-right-color: transparent;
    outline: none;
    touch-action: manipulation;

    > i {
      font-size: 18px; }

    &:hover,
    &:focus {
      background-color: #FFF;
      color: $deep_gray; } }

  > h5 {
    text-transform: uppercase;
    color: #656565;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 15px; }

  > ul {
    padding: 0;
    margin: 0;
    @include clearfix();

    > li {
      list-style: none;
      float: left;
      width: 30px;
      height: 30px;
      margin: 1px;
      cursor: pointer;
      border: 1px solid rgba(0,0,0,.01); } } }
