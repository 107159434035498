.about-us-block {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  .about-us-content-block {
    > .title {
      font-family: $font_secondary;
      @include font-size(24);
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 30px; } }

  .about-us-content-image {
    > img {
      max-width: 100%;
      height: auto; } }

  &.right {
    flex-direction: row-reverse;

    .about-us-content-block {
      margin-right: 40px; } }

  &.left {
    .about-us-content-block {
      margin-left: 40px; } } }

@media (max-width: 768px) {
  .about-us-block {
    display: block;
    border-bottom: 1px solid #e8e8e8;

    .about-us-content-image {
      text-align: center;
      margin-bottom: 40px; }

    &.left,
    &.right {
      .about-us-content-block {
        margin-left: 0;
        margin-right: 0; } } } }
