.speaker-profile-details {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  @include clearfix();

  .swiper-slide & {
    opacity: 0;
    transition-delay: 0.4s; }

  .swiper-slide-active & {
    opacity: 1; }

  > .profile-image {
    width: 40%;
    float: left;

    > img {
      width: 100%;
      height: auto; } }

  > .profile-content {
    width: 60%;
    padding-left: 45px;
    float: left;

    > .name-job {
      margin-bottom: 36px;
      padding-bottom: 10px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 240px;
        background-color: #eeeeee; }

      > .name {
        @include font-size(26);
        font-family: $font_secondary;
        font-weight: 600;
        line-height: 1;
        margin-top: 0;
        margin-bottom: 10px; } }

    > .biography {
      margin-bottom: 30px; } } }

// Responsiveness
@media (max-width: 600px) {
  .speaker-profile-details {
    display: block;

    > .profile-image,
    > .profile-content {
      width: 100%;
      float: none;
      padding: 0; }

    > .profile-image {
      max-width: 200px;
      margin: 0 auto 30px auto; } } }
