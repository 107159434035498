.dynamic-photo-gallery {
  @include clearfix;
  margin: -13px;

  > .photo-item {
    width: 50%;
    float: left;
    padding: 13px;
    overflow: hidden;
    position: relative;
    z-index: 10;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #FFF;
      transition: all 0.5s ease-in-out;
      z-index: 12; }

    &:before {
      height: 0;
      width: 1px;
      margin-top: -20px; }

    &:after {
      width: 0;
      height: 1px;
      margin-left: -20px; }


    > a {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #000;
      position: relative;
      overflow: hidden;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 0%;
        height: 0%;
        z-index: 11;
        opacity: 0;
        transition: all 0.5s ease-in-out; }

      &:before {
        border-top: 1px solid #FFF;
        border-left: 1px solid #FFF;
        top: 14px;
        left: 14px; }

      &:after {
        border-bottom: 1px solid #FFF;
        border-right: 1px solid #FFF;
        bottom: 14px;
        right: 14px; }


      > img {
        width: 100%;
        height: auto;
        opacity: 1;
        transition: all 0.25s ease-in-out;
        backface-visibility: hidden; } }

    &:hover {
      &:before {
        height: 40px; }
      &:after {
        width: 40px; }

      > a {
        &:before,
        &:after {
          width: 40%;
          height: 40%;
          opacity: 1; }
        > img {
          opacity: 0.3; } } }

    > img {
      width: 100%;
      height: auto; } }

  &.masonry-gallery {
    > .photo-item {
      width: 33.33333333333333%;
      height: 300px;

      &.width-2 {
        width: 66.66666666666667%; } } } }

// Responsiveness
@media (max-width: 1200px) {
  .dynamic-photo-gallery {
    &.masonry-gallery {

      > .photo-item {
        height: 275px; } } } }
@media (max-width: 1024px) {
  .dynamic-photo-gallery {
    &.masonry-gallery {

      > .photo-item {
        height: 210px; } } } }

@media (max-width: 768px) {
  .dynamic-photo-gallery {
    &.masonry-gallery {

      > .photo-item {
        padding: 5px;
        height: 150px; } } } }

@media (max-width: 500px) {
  .dynamic-photo-gallery {
    &.masonry-gallery {

      > .photo-item {
        width: 50%;
        height: 150px;

        &.width-2 {
          width: 50%;

          a > img {
            width: 210%;
            height: auto; } } } } } }

@media (max-width: 350px) {
  .dynamic-photo-gallery {
    &.masonry-gallery {

      > .photo-item {
        width: 50%;
        height: 130px; } } } }
