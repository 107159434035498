.footer-widget-area {
  padding-top: 50px;
  background-color: #272727;
  background-size: cover;
  background-repeat: no-repeat;

  .widget {

    img {
      max-width: 100%;
      height: auto; }

    input,
    select {
      background-color: transparent;
      border-color: #565656; }

    select option {
      color: $deep_black; }

    .wp-caption {
      color: $deep_black; } } }

.copyright-info-area {
  background-color: #16161a;
  text-align: center;
  padding: 20px 0;

  .copyright-notice {
    margin-bottom: 0;

    > a {
      color: $deep_black;
      &:hover {
        color: $color_primary; } } }

  &.transparent-area {
    .copyright-notice {
      opacity: 0.6;

      > a {
        color: #FFF;

        &:hover {
          color: $color_primary; } } } } }


// Reponsiveness
@media (max-width: 768px) {
  .footer-widget-area {

    > .container {
      > .row {
        > [class*="col"] {
          &:nth-child(odd) {
            clear: both; }
          &:nth-child(even) {
            clear: none; } } } } } }
