.testimonial-item {

  .swiper-slide > & {
    padding: 0 125px;
    opacity: 0;
    transition: all 0.15s ease-in-out 0.4s; }

  .swiper-slide-active > & {
    opacity: 1; }

  > .reviewer-image {
    width: 130px;
    height: 130px;
    border: 1px solid #000;
    overflow: hidden;
    padding: 5px;
    float: left;

    > img {
      width: 100%;
      height: auto; }

    .transparent-area & {
      border-color: #FFF; } }

  > .reviewer-content {
    padding-left: 172px;

    > .name {
      @include font-size(22);
      line-height: 1;
      margin-top: 0;
      margin-bottom: 10px;
      font-family: $font_secondary;
      font-weight: 600; }

    > .job {
      margin-bottom: 20px;
      line-height: 1;

      .transparent-area & {
        opacity: 0.7; } } } }

// Responsiveness
@media (max-width: 767px) {
  .testimonial-item {
    text-align: center;

    .swiper-slide > & {
      padding: 0 70px; }

    > .reviewer-image {
      float: none;
      margin: 0 auto 30px auto; }

    > .reviewer-content {
      padding-left: 0; } } }
