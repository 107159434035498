// Buttons
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
  padding: 10px 15px;
  background-color: $color_primary;
  border: 1px solid $color_primary;
  color: #FFF;
  border-radius: 0;

  &:hover {
    background-color: transparent;
    color: $color_primary; } }

.woocommerce a.added_to_cart {
  line-height: 1;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: $color_secondary;
  border: 1px solid $color_secondary;
  color: #fff;

  &:after {
    content: "\f178";
    font: normal normal normal 1em/1 FontAwesome;
    margin-left: 5px; }

  &:hover {
    background-color: transparent;
    color: $color_secondary; } }

.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
  padding: 15px 20px;
  background-color: $color_primary;

  &:hover {
    background-color: darken($color_primary, 25%); } }

.woocommerce #respond input#submit.alt.disabled,
.woocommerce #respond input#submit.alt.disabled:hover,
.woocommerce #respond input#submit.alt:disabled,
.woocommerce #respond input#submit.alt:disabled:hover,
.woocommerce #respond input#submit.alt:disabled[disabled],
.woocommerce #respond input#submit.alt:disabled[disabled]:hover,
.woocommerce a.button.alt.disabled,
.woocommerce a.button.alt.disabled:hover,
.woocommerce a.button.alt:disabled,
.woocommerce a.button.alt:disabled:hover,
.woocommerce a.button.alt:disabled[disabled],
.woocommerce a.button.alt:disabled[disabled]:hover,
.woocommerce button.button.alt.disabled,
.woocommerce button.button.alt.disabled:hover,
.woocommerce button.button.alt:disabled,
.woocommerce button.button.alt:disabled:hover,
.woocommerce button.button.alt:disabled[disabled],
.woocommerce button.button.alt:disabled[disabled]:hover,
.woocommerce input.button.alt.disabled,
.woocommerce input.button.alt.disabled:hover,
.woocommerce input.button.alt:disabled,
.woocommerce input.button.alt:disabled:hover,
.woocommerce input.button.alt:disabled[disabled],
.woocommerce input.button.alt:disabled[disabled]:hover, {
  background-color: $color_primary; }

.woocommerce #respond input#submit.disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce #respond input#submit:disabled[disabled],
.woocommerce a.button.disabled,
.woocommerce a.button:disabled,
.woocommerce a.button:disabled[disabled],
.woocommerce button.button.disabled,
.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce input.button.disabled,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled] {
  color: #FFF;

  &:hover {
    background-color: transparent;
    color: $color_primary; } }

// Columns
.woocommerce ul.products li.product,
.woocommerce-page ul.products li.product {
  width: 30.741765%;
  float: left;
  margin-right: 3.882353%;
  text-align: center; }

.woocommerce ul.products li.last,
.woocommerce-page ul.products li.last {
  margin-right: 0; }

// Ratings on product grid
.woocommerce ul.products li.product .star-rating {
  margin: 10px auto 10px;
  color: $deep_black; }

// Product title
.woocommerce ul.products li.product .woocommerce-loop-category__title,
.woocommerce ul.products li.product .woocommerce-loop-product__title,
.woocommerce ul.products li.product h3 {
  font-size: 1.2em; }

// Product Price
.woocommerce ul.products li.product .price {
  font-size: 1em; }

// Sale badge
.woocommerce span.onsale {
  border-radius: 0;
  padding: 5px 10px;
  line-height: 1;
  height: auto;
  min-height: auto;
  top: 0;
  left: 0;
  font-size: 1.2em; }

.woocommerce ul.products li.product .onsale {
  top: 0;
  right: 0;
  margin: 0; }

// Mini cart
.woocommerce .widget_shopping_cart .cart_list li,
.woocommerce.widget_shopping_cart .cart_list li {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ebe9eb; }

.woocommerce .widget_shopping_cart .cart_list li:last-child,
.woocommerce.widget_shopping_cart .cart_list li:last-child {
  border-bottom: none; }

.woocommerce ul.cart_list li img,
.woocommerce ul.product_list_widget li img {
  width: 40px; }

.woocommerce .widget_shopping_cart .total,
.woocommerce.widget_shopping_cart .total {
  border-top: 1px solid #ebe9eb;
  border-bottom: 1px solid #ebe9eb;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px; }

.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a {
  display: block;
  text-align: center; }

// Product Preview
.woocommerce div.product div.images .flex-control-thumbs {
  margin-left: -5px;
  margin-right: -5px;
  padding-top: 5px;
  padding-bottom: 5px; }

.woocommerce div.product div.images .flex-control-thumbs li {
  padding: 5px; }

// Add to cart Quantity
.woocommerce .quantity .qty {
  width: 5.8em; }

.woocommerce div.product form.cart .variations td,
.woocommerce div.product form.cart .variations th {
  display: list-item;
  list-style: none;
  line-height: 1; }

.woocommerce div.product form.cart .variations label {
  color: $deep_gray;
  text-align: left; }

.woocommerce div.product form.cart .variations select {
  width: auto; }

.woocommerce div.product form.cart .variations td.label {
  text-align: left;
  @include font-size(16); }

.woocommerce div.product form.cart .reset_variations {
  @include font-size(14);
  color: $deep_gray;
  font-weight: 600;

  &:before {
    content: "\f021";
    font: normal normal normal 1em/1 FontAwesome;
    margin-right: 5px; } }

// Reviews
.woocommerce #reviews #comments ol.commentlist li .comment-text {
  margin-left: 74px; }

.woocommerce #reviews #comments ol.commentlist li img.avatar {
  width: 60px;
  padding: 2px; }

// Coupon
.woocommerce #content table.cart td.actions .input-text,
.woocommerce table.cart td.actions .input-text,
.woocommerce-page #content table.cart td.actions .input-text,
.woocommerce-page table.cart td.actions .input-text {
  width: 150px;
  height: auto;
  padding: 9px 15px;
  background-color: #f5f5f7;
  border: 1px solid transparent;
  line-height: 1;

  &:focus {
    border-color: $color_secondary; } }

// Cart Thumbs
#add_payment_method table.cart img,
.woocommerce-cart table.cart img,
.woocommerce-checkout table.cart img {
  width: 64px;
  margin-bottom: 0; }

// Create Account Checkbox
.woocommerce form .form-row.create-account {
  line-height: 1;

  > label {
    display: block;
    line-height: 1;

    > span,
    > input {
      margin: 0; }

    > span {
      margin-left: 20px; } } }

// Product Categories Widget
.widget_product_categories ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.widget_product_categories ul li {
  margin-bottom: 10px;

  &:before {
    content: "\f114";
    margin-right: 5px;
    font: normal normal normal 1em/1 FontAwesome; } }

.widget_product_categories ul li a {
  color: $deep_black; }

.widget_product_categories .children {
  margin-top: 10px;
  margin-left: 15px; }

// Price Filter
.woocommerce .widget_price_filter .ui-slider .ui-slider-handle,
.woocommerce .widget_price_filter .ui-slider .ui-slider-range {
  background-color: $color_primary; }

.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background-color: darken($color_primary, 25%); }

// Paginations
.woocommerce nav.woocommerce-pagination {
  > ul {
    border: none;

    > li {
      border: 1px solid $color_secondary;

      + li {
        margin-left: 10px; }

      > span,
      > a {
        padding: 8px 10px;
        color: $deep_black; }

      > span.current,
      > a:hover {
        background-color: $color_secondary;
        color: #fff; } } } }
