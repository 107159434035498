.event-image-carousel {
  .swiper-slide {
    > img {
      width: 100%;
      height: auto; }

    > .dt-play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); } } }
