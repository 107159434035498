.speakers-profile-carousel {

  > .event-speakers-list {
    padding-top: 60px;

    .carousel-button-prev,
    .carousel-button-next {
      top: 0;
      right: 0;
      transform: none;
      margin: 0; }

    .carousel-button-prev {
      left: auto;
      right: 42px; } } }
