.widget_tag_cloud {
  > .tagcloud {
    margin: 0 -5px;
    > a {
      display: inline-block;
      font-size: 1em !important;
      padding: 5px 10px;
      color: $color_primary;
      border: 1px solid $color_primary;
      text-transform: uppercase;
      margin: 5px;

      .transparent-area &, {
        color: #FFF; }

      &:hover {
        background-color: $color_primary;
        color: #FFF; } } } }
