.site-banner {
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  .swiper-slide {
    height: auto; }

  > .banner-overlay {
    display: flex;
    align-items: center; }

  > .banner-statics {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 100;

    .event-statics {
      background-color: rgba($color_secondary, 0.30);
      color: #FFF; } }

  // Banner Content
  .banner-content {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 200px;

    .title {
      @include font-size(50, 14);
      margin-top: 0;
      margin-bottom: 30px; }

    .title-two {
      @include font-size(26, 14);
      @include letter-spacing(600);
      margin-bottom: 20px; }

    .description {
      max-width: 700px;
      margin-bottom: 50px; }

    &.text-center {
      .description {
        margin-left: auto;
        margin-right: auto; } }

    .event-countdown {
      margin-top: 50px; }

    .hero-actions {
      margin-top: 20px;

      > a + a {
        margin-left: 15px; } } }

  // Swiper Slide
  .swiper-container {
    height: 100%;

    .swiper-wrapper {
      .swiper-slide {
        .middle-content {
          display: flex;
          align-items: center;
          height: 100%; } } } } }

// Responsiveness
@media (max-width: 1024px) {
  .site-banner {
    .banner-content {
      .title {
        @include font-size(26, 14); }

      .title-two {
        @include font-size(18, 14); }

      .description {
        margin-bottom: 30px; } } } }
