#visual-composer-content {
  > .vc_row {
    margin-left: 0;
    margin-right: 0;

    > .vc_col-xs-12,
    > .vc_col-sm-12,
    > .vc_col-md-12,
    > .vc_col-lg-12,
    > .container > .vc_col-xs-12,
    > .container > .vc_col-sm-12,
    > .container > .vc_col-md-12,
    > .container > .vc_col-lg-12 {
      > .vc_column-inner {
        padding-left: 0;
        padding-right: 0; } } } }
