.site-error-404 {
  .search-form {
    margin-bottom: 30px; }

  .error-page-content {
    margin-bottom: 50px; }

  .error-page-action {
    a {
      &+a {
        margin-left: 15px; } } }


  .error-404-content {
    margin-bottom: 40px;
    border-right: 1px solid #eeeeee; } }

// Responsiveness
@media (max-width: 1024px) {
  .site-error-404 {
    .error-page-action {
      a {
        display: block;

        &+a {
          margin-left: 0;
          margin-top: 15px; } } } } }

@media (max-width: 768px) {
  .site-error-404 {
    .error-404-content {
      border-right: none; }

    .error-page-action {
      a {
        display: inline-block;
        &+a {
          margin-left: 15px;
          margin-top: 0px; } } } } }

@media (max-width: 400px) {
  .site-error-404 {
    .error-page-action {
      a {
        display: block;
        &+a {
          margin-top: 15px;
          margin-left: 0; } } } } }
