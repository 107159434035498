.dt-flexible-row {
  display: flex;

  &.middle {
    align-items: center; }

  &.center {
    justify-content: space-between; } }

// Padding
.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;

  &-two {
    padding-top: 80px;
    padding-bottom: 80px; }

  &-three {
    padding-top: 100px;
    padding-bottom: 100px; } }

.section-padding-top {
  padding-top: 120px;

  &-two {
    padding-top: 100px; } }

.section-padding-bottom {
  padding-bottom: 120px;

  &-two {
    padding-bottom: 100px; } }

// Padding
.section-margin-top {
  margin-top: 50px;

  &-two {
    margin-top: 100px; } }

// Background color
.section-bgc {
  &-alt {
    background-color: #f5f5f7; } }

// Background Image
section[data-bg-image],
.image-size-cover[data-bg-image] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

/* Section Title */
@import "../elements/section-heading";

/*-----------------------------------------------
  ## Site Header
  ---------------------------------------------*/
@import "site-header";

/*-----------------------------------------------
  ## Page Header
  ---------------------------------------------*/
@import "page-header";

/*-----------------------------------------------
  ## Site Banner
  ---------------------------------------------*/
@import "site-banner";

/*-----------------------------------------------
  ## Event Schedule
  ---------------------------------------------*/
@import "event-schedules";

/*-----------------------------------------------
  ## About The Event
  ---------------------------------------------*/
@import "about-the-event";

/*-----------------------------------------------
  ## Newsletter
  ---------------------------------------------*/
@import "newsletter";

/*-----------------------------------------------
  ## Event Speakers
  ---------------------------------------------*/
@import "event-speakers";

/*-----------------------------------------------
  ## Testimonials
  ---------------------------------------------*/
@import "testimonials";

/*-----------------------------------------------
  ## Event Pricing
  ---------------------------------------------*/
@import "event-pricing";

/*-----------------------------------------------
  ## Register Now
  ---------------------------------------------*/
@import "register-now";

/*-----------------------------------------------
  ## Photos From Our Event
  ---------------------------------------------*/
@import "photos-from-our-event";

/*-----------------------------------------------
  ## Event Sponsor
  ---------------------------------------------*/
@import "event-sponsor";

/*-----------------------------------------------
  ## Recent News
  ---------------------------------------------*/
@import "recent-news";

/*-----------------------------------------------
  ## Contact Us
  ---------------------------------------------*/
@import "contact-us";

/*-----------------------------------------------
  ## Site Footer
  ---------------------------------------------*/
@import "site-footer";

/*-----------------------------------------------
  ## About Us Content Block
  ---------------------------------------------*/
@import "about-us-content-block";

/*-----------------------------------------------
  ## Speakers Profile Carousel
  ---------------------------------------------*/
@import "speakers-profile-carousel";

/*-----------------------------------------------
  ## Gallery Archive
  ---------------------------------------------*/
@import "gallery-archive";

/*-----------------------------------------------
  ## Error 404
  ---------------------------------------------*/
@import "error-404";

/*-----------------------------------------------
  ## Blog Entries
  ---------------------------------------------*/
@import "blog-entries";

/*-----------------------------------------------
  ## Sidebar
  ---------------------------------------------*/
@import "sidebar";
