// Headings
h1, h2, h3, h4, h5, h5 {
  color: $deep_black;
  font-weight: 700; }

.h1, .h2, .h3, h1, h2, h3 {
  margin-top: 30px;
  margin-bottom: 15px; }

// Paragraph
p {
  margin-bottom: 20px;
  @include line-height(26); }

// Transparent area
.transparent-area {
  color: #FFF;

  h1, h2, h3, h4, h5, h5, a {
    color: #FFF; } }
