#back-to-top {
  position: fixed;
  right: 35px;
  bottom: 0px;
  padding: 15px;
  opacity: 0;
  visibility: hidden;

  &:hover {
    cursor: pointer; }

  &.visible {
    bottom: 35px;
    visibility: visible;
    opacity: 1; } }
