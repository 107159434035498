.site-header {
  background-color: #FFF;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease-in-out;
  backface-visibility: hidden;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .transparent-header & {
    background-color: transparent;
    box-shadow: none; }

  .fix-header & {
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2); } }

/* Header Middle Area */
.header-top-area {
  background-color: $color_secondary;
  padding-top: 32px;
  padding-bottom: 32px;
  color: #FFF;

  .no-logo.no-register.no-learnmore {
    display: block; }

  .site-logo {

    > a {
      display: block;

      > img {
        max-width: 100%;
        height: auto; } } }

  .event-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > a {
      &+a {
        margin-left: 20px; } } } }
/* Topbar */
.header-top-bar {
  border-top: 4px solid $color_primary;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0,0,0,0.1);

  .transparent-header:not(.fix-header) & {
    border-bottom: 1px solid rgba(255,255,255,0.4);
    color: #FFF; }

  ul {
    margin: 0;
    padding: 0;

    > li {
      float: left;
      list-style: none;

      > a {
        color: $deep_black;

        &:hover {
          color: $color_primary; }

        .transparent-header:not(.fix-header) & {
          color: #FFF;

          &:hover {
            color:  $color_secondary; } } }

      &+li {
        margin-left: 30px; }

      > i {
        margin-right: 10px; } }

    &.header-social-profile {
      > li + li {
        margin-left: 15px; }

      &:not(.icons-only) {
        > li > a > i {
          margin-right: 10px; } } } } }

/* Fix header */
.fix-header {
  .site-header {
    position: fixed;
    left: 0;
    right: 0;
    background-color: #FFF; } }

/* Header Mask */
#header-fake-mask {
  .transparent-header & {
    display: none; } }

// Responsiveness
@media (max-width: 1024px) {
  .header-top-area {
    padding: 16px 0; }

  .event-buttons {
    > a {
      @include font-size(12, 14); } } }

@media (max-width: 768px) {
  .header-top-area {

    .dt-flexible-row {
      flex-direction: column;


      > div:not(:last-child) {
        margin-bottom: 30px;
        flex-direction: row; } } } }

@media (max-width: 767px) {
  .header-top-area {
    .site-logo-area {
      display: none; } } }
