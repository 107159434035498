.newsletter-form {
  max-width: 575px;
  margin: 0 auto;
  @include clearfix();

  > input {
    float: left;
    max-width: 400px;
    margin-right: 40px; }

  > button {
    float: left;
    text-transform: uppercase;
    min-width: 135px;

    > i {
      display: none; }

    &.clicked {
      > span {
        display: none; }

      > i {
        display: inline-block; } } }

  .form-result {
    margin-top: 25px; } }

// Responsiveness
@media (max-width: 500px) {
  .newsletter-form {
    width: auto;
    text-align: center;

    > input {
      width: 100%;
      float: none;
      margin-right: 0;
      margin-bottom: 20px; }

    > button {
      float: none; } } }
