.post-author-bio {
  background-color: #f5f5f7;
  padding: 14px 24px;
  overflow: hidden;
  @include clearfix();

  > .author-image {
    width: 110px;
    height: 110px;
    overflow: hidden;
    border: 4px solid $color_secondary;
    float: left;

    > img {
      width: 100%;
      height: auto; } }

  > .author-content {
    padding-left: 135px;

    > .head {
      overflow: hidden;
      margin-bottom: 20px;
      margin-top: 4px;

      > .name {
        a,
        span {
          font-family: $font_secondary;
          line-height: 1;
          @include font-size(20);
          color: $deep_black; } } } } }

// Responsiveness
@media (max-width: 500px) {
  .post-author-bio {

    > .author-image {
      float: none;
      margin: 0 auto 25px auto; }

    > .author-content {
      padding-left: 0;

      > .head {
        > div,
        > ul {
          float: none !important;
          text-align: center; }

        > .name {
          margin-bottom: 20px; } } } } }
