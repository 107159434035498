.pricing-item {
  background-color: #f5f5f7;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
  margin: 10px;
  transition: all 0.25s ease-in-out;

  > .plan-name {
    margin-top: 0;
    margin-bottom: 38px;
    @include font-size(30);
    text-transform: uppercase;
    font-family: $font_secondary;
    font-weight: 600; }

  > .plan-price {
    background-color: $color_primary;
    color: #FFF;
    @include font-size(50);
    font-family: $font_secondary;
    font-weight: 600;
    transition: background 0.25s ease-in-out;

    > sup {
      @include font-size(26, 50); } }

  > ul {
    padding: 0;
    margin-bottom: 20px;

    > li {
      list-style: none;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #eeeeee;

      &:last-child {
        border-bottom: none; } } }
  > .action {
    > a {
      text-transform: uppercase; } }

  &:hover {
    transform: translateY(-5px); }

  &:hover,
  &.featured {
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.2);

    > .plan-price {
      background-color: $color_secondary; } } }

// Responsiveness
@media (max-width: 768px) {
  .pricing-item {
    max-width: 340px;
    margin: 30px auto; } }
