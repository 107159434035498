.speaker-profile-thumb-v2 {
  min-height: 395px;
  margin-bottom: 60px;

  > .profile-image {
    position: relative;
    transition: all 0.25s ease-in-out;
    overflow: hidden;

    > img {
      width: 100%;
      height: auto; }

    > .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(5, 7, 9, 0.7);
      opacity: 0;
      transition: opacity 0.15s ease-in-out;

      > .social-profiles-v2,
      > a {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -10px;
        transform: translate(-50%, 100px);
        opacity: 0;
        transition: all 0.25s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.2s; }

      > a {
        margin-top: 20px;
        color: #FFF;
        text-transform: uppercase;
        opacity: 0;
        transition-delay: 0.3s;

        &:before {
          content: '';
          position: absolute;
          left: 50%;
          margin-left: -100%;
          top: -5px;
          width: 200%;
          height: 1px;
          background-color: #FFF; }

        &:hover {
          color: $color_primary; } } } }

  > .profile-info {
    text-align: center;

    > .name {
      font-family: $font_secondary;
      font-weight: 600;
      @include font-size(24);

      > a {
        color: $deep_black;

        &:hover {
          color: $color_secondary; } } } }

  &:hover {
    > .profile-image {
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);

      > .overlay {
        opacity: 1;

        > .social-profiles-v2,
        > a {
          opacity: 1;
          transform: translate(-50%, -50%); } } } } }
